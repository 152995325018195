@import 'src/styles/colors';

$fontWeightRegular: 400;
$fontWeightMedium: 500;
$fontWeightSemiBold: 600;
$fontWeightBold: 700;

$mainFont: 'Poppins', sans-serif;
$secondaryFont: 'DM Sans', sans-serif;

@mixin sectionCaption {
  font-family: $mainFont;
  color: $blackColor;
  font-size: 24px;
  font-weight: $fontWeightSemiBold;
  line-height: 36px;
}

@mixin sectionSubText {
  font-family: $secondaryFont;
  font-size: 15px;
  line-height: 22px;
  color: $grayColor;
  font-weight: $fontWeightMedium;
}
