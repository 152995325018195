@import 'src/styles/buttons';
@import 'src/styles/fonts';
@import 'src/styles/colors';
@import 'src/styles/media';
@import "src/styles/mixin/adaptiv-value";
@import "src/styles/variables";

.header {
  position: relative;
  top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  padding: 10px 0;
  //margin: 24px 38px 0 34px;
  @media screen and (min-width: $desktop + px) {
    //max-width: 1112px;
    //margin: 0 auto;
    padding: 10px;
    box-sizing: border-box;
  }

  &_logoM {
    display: none;
  }

  &_logoD {
    width: 132px;
    height: 32px;
  }

  &_links {
    display: flex;
    .login {
      padding-left: 60px;
    }
    &--link {
      font-size: 15px;
      text-decoration: none;
      color: $blackColor;
      font-weight: $fontWeightMedium;
      line-height: 22px;
      padding-right: 24px;
    }

    &--link.dropdown {
      transition: all 0.4s ease;

      &.right {
        &:after {
          margin-left: auto;
        }
      }

      &:after {
        display: inline-block;
        transition: transform 0.2s ease-in;
        transform: rotate(0deg);
        margin-left: 6px;
        content: url('/public/header/arrow_down.svg');
      }

      &:hover {
        &:after {
          transform: rotate(180deg);
        }
      }
    }
  }

  &_mobileToggle {
    display: none;

    &--icon {
      cursor: pointer;
    }
  }

  @media (min-width: $fourKMinPx) {
    @include adaptiv-value(top, 30, 15);
    @include adaptiv-value(padding-top, 20, 10);
    //@include adaptiv-value(max-width, 2224, 1112);
    @include adaptiv-value(padding-bottom, 20, 10);
    //@include adaptiv-value(padding-right, 32, 16);
    //@include adaptiv-value(padding-left, 32, 16);

    &_links {
      &--link {
        @include adaptiv-value(font-size, 30, 15);
        @include adaptiv-value(line-height, 44, 22);
        @include adaptiv-value(padding-right, 48, 24);
      }

      &--link.dropdown {

        &:after {
          @include adaptiv-value(margin-left, 12, 6);
        }
      }
    }
    &_logoD {
      @include adaptiv-value(width, 232, 116);
      @include adaptiv-value(height, 56, 28);
    }
    #joinLudoBtn button{
      @include adaptiv-value(border-radius, 60, 30);
      @include adaptiv-value(padding-top, 18, 9);
      @include adaptiv-value(padding-left, 48, 24);
      @include adaptiv-value(padding-bottom, 18, 9);
      @include adaptiv-value(padding-right, 48, 24);
      @include adaptiv-value(font-size, 30, 15);
    }
  }


  @media screen and (max-width: $desktop + px) {
    &_logoD {
      width: 110px;
      height: 26px;
    }
  }

  @media screen and (max-width: $tablet + px) {
    margin: 0;
    padding: 20px;

    &_links {
      display: none;
    }
    &_mobileToggle {
      display: block;
    }
    &_logoM {
      display: block;
    }
    &_logoD {
      display: none;
    }
  }

  @media screen and (max-width: $desktop + px) {
    .get-extension {
      display: none;
    }
  }
}
