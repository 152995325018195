@import "src/styles/variables";
@import "src/styles/mixin/adaptiv-value";

@media (min-width: $fourKMinPx) {
  .container {
    @include adaptiv-value(max-width, 2400, 1200);
  }
  .hero-txt {
    max-width: unset;
  }
  .txt-block {
    max-width: unset;
  }
  #hero-card-img {
    @include adaptiv-value(width, 900, 650);
  }
  #hero .hero_social {
    @include adaptiv-value(gap, 64, 32);
    img {
      @include adaptiv-value(width, 48, 24);
      @include adaptiv-value(height, 48, 24);
    }
  }

  #ludo-rank img {
    @include adaptiv-value(width, 1000, 600);
  }

  #web3-user-acquisition img {
    @include adaptiv-value(width, 600, 360);
  }

  .brand-card {
    @include adaptiv-value(width, 500, 265);
    @include adaptiv-value(height, 220, 110);
  }
  .partners img {
    width: 80%;
  }
  .cbox-2-ico{
    font-size: 1.15rem;
    height: 2.6rem;
    line-height: 2.05rem;
    width: 2.6rem;
  }
  .cbox-2-txt h5 {
    padding-top: 5px !important;
  }

  .section-discovery .txt-block {
    max-width: unset;
    @include adaptiv-value(padding-top, 120, 60);
    @include adaptiv-value(padding-bottom, 120, 60);
  }

  .blockchains-wrapper .blockchains {
    @include adaptiv-value(max-width, 880, 476);
  }

  .btn-gradient-outline {
    @include adaptiv-value-double(padding, 32, 16, 80, 40);
    @include adaptiv-value(font-size, 36, 18);
  }
}

@media (min-width: 1400px) {


}



/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */

@media (min-width: 1200px) {

  /*------------------------------------------*/
  /*  BASE
  /*------------------------------------------*/

  html { font-size: 115.6%; }

  /* Button */
  .btn { padding: 0.8rem 1.7rem; }
  .btn.ico-30.ico-left { padding: 0.286rem 0; }
  .btns-group .btn.ico-30.ico-left { padding: 0.286rem 0.5rem; }
  .btn.ico-20 [class*="flaticon-"]:before, .btn.ico-20 [class*="flaticon-"]:after { top: 3px; }

  .btn-code-wrapper{
    justify-content: flex-start;
  }
  .cbox-2-txt h5 {
    padding-top: 10px;
  }
  #web3-curators-network, #ludo-rank-dao {
    .section-discovery .txt-block {
      padding-top: 65px;
      padding-bottom: 65px;
    }
  }
}




/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */

@media (min-width: 992px) and (max-width: 1199.95px) {
  #hero-card-img {
    width: 550px;
    left: -50px
  }
  .logo {
    img {
      width: 220px;
    }
  }
  .blockchains-wrapper {
    .blockchains {
      gap: 10px 24px;
      .center-row {
        gap: 10px 24px;
      }
      .blockChainsWithFooter_blockChains_blockchains--img {
        width: 60px;
        height: 60px;
      }
    }
  }

  /*------------------------------------------*/
  /*  BASE
  /*------------------------------------------*/

  .py-5 { padding-top: 0; padding-bottom: 0; }
  .py-10 { padding-top: 5px; padding-bottom: 5px; }
  .py-15 { padding-top: 10px; padding-bottom: 10px; }
  .py-20 { padding-top: 15px; padding-bottom: 15px; }
  .py-30 { padding-top: 25px; padding-bottom: 25px; }
  .py-40 { padding-top: 30px; padding-bottom: 30px; }
  .py-50 { padding-top: 40px; padding-bottom: 40px; }
  .py-60 { padding-top: 50px; padding-bottom: 50px; }
  .py-70 { padding-top: 60px; padding-bottom: 60px; }
  .py-80 { padding-top: 60px; padding-bottom: 60px; }
  .py-90 { padding-top: 70px; padding-bottom: 70px; }
  .py-100 { padding-top: 80px; padding-bottom: 80px; }
  .py-130 { padding-top: 110px; padding-bottom: 110px; }

  .pt-30 { padding-top: 20px; }
  .pt-40 { padding-top: 30px; }
  .pt-50 { padding-top: 40px; }
  .pt-60 { padding-top: 40px; }
  .pt-70 { padding-top: 60px; }
  .pt-80 { padding-top: 60px; }
  .pt-90 { padding-top: 70px; }
  .pt-100 { padding-top: 80px; }

  .pb-30 { padding-bottom: 20px; }
  .pb-40 { padding-bottom: 30px; }
  .pb-50 { padding-bottom: 40px; }
  .pb-60 { padding-bottom: 50px; }
  .pb-70 { padding-bottom: 60px; }
  .pb-80 { padding-bottom: 60px; }
  .pb-90 { padding-bottom: 70px; }
  .pb-100 { padding-bottom: 80px; }

  .mt-40 { margin-top: 30px; }
  .mt-45 { margin-top: 35px; }
  .mt-50 { margin-top: 45px; }
  .mt-55 { margin-top: 40px; }
  .mt-60 { margin-top: 50px; }
  .mt-65 { margin-top: 55px; }
  .mt-70 { margin-top: 60px; }
  .mt-75 { margin-top: 65px; }
  .mt-80 { margin-top: 60px; }
  .mt-85 { margin-top: 65px; }
  .mt-90 { margin-top: 70px; }
  .mt-95 { margin-top: 75px; }
  .mt-100 { margin-top: 80px; }

  .mb-40 { margin-bottom: 30px; }
  .mb-45 { margin-bottom: 35px; }
  .mb-50 { margin-bottom: 40px; }
  .mb-50 { margin-bottom: 45px; }
  .mb-60 { margin-bottom: 50px; }
  .mb-65 { margin-bottom: 55px; }
  .mb-70 { margin-bottom: 60px; }
  .mb-75 { margin-bottom: 65px; }
  .mb-80 { margin-bottom: 60px; }
  .mb-85 { margin-bottom: 65px; }
  .mb-90 { margin-bottom: 70px; }
  .mb-95 { margin-bottom: 75px; }
  .mb-100 { margin-bottom: 80px; }

  /* Backgroud Shape */
  .shape--02:after { width: 140%; height: 82%; top: 12%; left: -20%; }
  .shape--03:after { height: 127%; top: -70px; }
  .shape--04:after { height: 84%; top: 8%; }
  .shape--05:after { height: 85%; }

  /* Font Size */
  .s-15 { font-size: 0.9375rem; }  /* 15px */
  .s-16 { font-size: 1rem; }       /* 16px */
  .s-17 { font-size: 1.0625rem; }  /* 17px */
  .s-18 { font-size: 1.125rem; }   /* 18px */
  .s-19 { font-size: 1.125rem; }   /* 18px */
  .s-20 { font-size: 1.18755rem; } /* 19px */
  .s-21, .s-22 { font-size: 1.25rem; }  /* 20px */
  .s-23 { font-size: 1.3125rem; }  /* 21px */
  .s-24 { font-size: 1.375rem; }   /* 22px */  
  .s-26 { font-size: 1.5rem; }     /* 24px */
  .s-28 { font-size: 1.625rem; }   /* 26px */
  .s-30 { font-size: 1.75rem; }    /* 28px */
  .s-32 { font-size: 1.875rem; }   /* 30px */
  .s-34 { font-size: 2rem; }       /* 32px */
  .s-36 { font-size: 2.125rem; }   /* 34px */
  .s-38 { font-size: 2.25rem; }    /* 36px */
  .s-40 { font-size: 2.3125rem; }  /* 37px */
  .s-42 { font-size: 2.4375rem; }  /* 39px */  
  .s-43 { font-size: 2.5rem; }     /* 40px */   
  .s-44 { font-size: 2.5rem; }     /* 40px */
  .s-45 { font-size: 2.5625rem; }  /* 41px */
  .s-46 { font-size: 2.625rem; }   /* 42px */
  .s-47 { font-size: 2.6875rem; }  /* 43px */
  .s-48 { font-size: 2.75rem; }    /* 44px */ 
  .s-50 { font-size: 2.875rem; }   /* 46px */ 
  .s-52 { font-size: 3rem; }       /* 48px */ 
  .s-54 { font-size: 3.125rem; }   /* 50px */ 
  .s-56 { font-size: 3.25rem; }    /* 52px */
  .s-58 { font-size: 3.375rem; }   /* 54px */
  .s-60 { font-size: 3.5rem; }     /* 56px */
  .s-62 { font-size: 3.625rem; }   /* 58px */
  .s-64 { font-size: 3.75rem; }    /* 60px */
  .s-66 { font-size: 3.875rem; }   /* 62px */
  .s-68 { font-size: 4rem; }       /* 64px */

  /* Button */
  .btn.btn-sm { font-size: 0.975rem; padding: 0.65rem 1.4rem; }
  .btn.ico-30.ico-left { padding: 0.3845rem 0; }
  .btns-group .btn.ico-30.ico-left, .btns-group .btn.ico-30.ico-right { padding: 0.3845rem 0.5rem; }

  /* Buttons Group */
  .btns-group .btn:first-child { margin-right: 12px; }

  /* Button Icon */
  .btn.ico-20 [class*="flaticon-"]:before,
  .btn.ico-20 [class*="flaticon-"]:after { font-size: 1.15rem; line-height: 0.5rem!important;top: 3.5px; }

  .btn.ico-30.ico-left span.ico-30-txt { margin-right: 0; }
  .btn.ico-30.ico-right span.ico-30-txt { margin-left: 0; }
  .btn.ico-left span { margin-right: 2px; }
  .btn.ico-right span { margin-left: 2px; }

  /* Button Text */
  p.btn-txt, p.btns-group-txt { margin: 15px 0 0 0; }
  .text-center p.btns-group-txt { margin-top: 18px; }
  .btn-rating { margin: 15px 0 0 0; }

  /* Transparent Link */
  a.tra-link { font-size: 1.0625rem; }
  .tra-link.ico-20 [class*="flaticon-"]:before,
  .tra-link.ico-20 [class*="flaticon-"]:after { font-size: 0.785rem; line-height: 0.7rem!important; top: 2px; left: 2px; }

  /* OS Button */
  .os-btn { width: 280px; min-width: 270px; padding: 16px 16px 14px; }
  .os-btn-ico { margin-right: 16px; }

  /* Watch Video Link */
  .watch-video-link { margin-right: 10px; }
  p.video-txt-lg { font-size: 1.125rem; }
  p.video-txt-sm { font-size: 1rem; }

  /* Vector Icon Background */
  .ico-50 .shape-ico svg { width: 110px; height: 110px; top: -30px; left: calc(50% - 55px); }
  .fbox-11 .ico-50 .shape-ico svg { top: -35px; left: calc(50% - 60px); }
  .ico-55 .shape-ico svg { width: 115px; height: 115px; top: -30px; left: calc(50% - 60px); }
  .ico-60 .shape-ico svg { width: 120px; height: 120px; top: -25px; left: calc(50% - 55px); }

  /* Vector Rounded Icons */
  .ico-rounded { width: 74px; height: 74px; }
  .ico-rounded-md { width: 80px; height: 80px; }
  .ico-rounded-lg { width: 90px; height: 90px; }

  .ico-rounded [class*="flaticon-"]:before,
  .ico-rounded [class*="flaticon-"]:after { font-size: 2.35rem; line-height: 74px!important; }

  .ico-rounded-md [class*="flaticon-"]:before,
  .ico-rounded-md [class*="flaticon-"]:after { font-size: 2.5rem; line-height: 80px!important; }

  .ico-rounded-lg [class*="flaticon-"]:before,
  .ico-rounded-lg [class*="flaticon-"]:after { font-size: 2.875rem; line-height: 90px!important; }

  /* Vector Square Icons */
  .ico-square { width: 74px; height: 74px; }
  .ico-square [class*="flaticon-"]:before,
  .ico-square [class*="flaticon-"]:after { font-size: 2.35rem; line-height: 74px!important; }

  /* Section ID */
  .section-id { font-size: 0.85rem; margin-bottom: 30px; }
  .section-id.rounded-id { padding: 8px 22px; font-size: 0.8rem; margin-bottom: 35px; }

  /* Section Title */
  .section-title p.p-md, .section-title p.p-lg, 
  .section-title p.p-xl, .section-title p.s-20, .section-title p.s-21 { margin-top: 15px; }
  .section-title p.p-md, .section-title p.p-lg { padding: 0; }
  .section-title p.s-20, .section-title p.s-21 { font-size: 1.18755rem; }

  .section-title .btns-group.mt-25 { margin-top: 20px; }
  .section-title .btns-group.mt-35, .section-title .stores-badge.mt-30 { margin-top: 26px; }

  .section-title .advantages, .section-title .btns-txt, .section-title .btns-group-txt { margin-top: 16px; }

  /*------------------------------------------*/
  /*   HERO   
  /*------------------------------------------*/

  .hero-section h2 { margin-bottom: 24px; }
  .hero-section h2.s-48, .hero-section h2.s-50 { margin-bottom: 35px; }

  .hero-section p.p-md { margin-bottom: 25px; }
  .hero-section p.p-lg, .hero-section p.p-xl { margin-bottom: 28px; }
  .hero-section p.s-20, .hero-section p.s-22, .hero-section p.s-24 { margin-bottom: 32px; }
  .hero-section p.s-21 { font-size: 1.25rem; margin-bottom: 22px; }
  .hero-section .btn.mt-15, .hero-section .btns-group.mt-15 { margin-top: 10px; }

  /* HERO LOGO */
  .hero-logo, .hero-app-logo { margin-bottom: 35px; }
  .hero-logo img { max-height: 30px; }
  .hero-square-logo img { max-height: 80px; }
  .hero-app-logo img { max-height: 90px; }
  #hero-25 .hero-app-logo img { max-height: 100px; }

  /* hero */
  #hero { padding-top: 0px; padding-bottom: 0px; margin-top: 0;}
  .hero-txt h1 { margin-bottom: 20px; }
  .hero-txt p.p-lg { padding-right: 10%; margin-bottom: 25px; }

  /* FEATURES */
  .fbox-14-wrapper { padding: 70px 50px; }
  .fbox-14-wrapper .col { padding: 0 8px; }

  #features-12.py-100.shape--bg:after { width: 50%; height: 68%; top: 16%; left: 50%; }
  #features-12.pt-100.shape--bg:after { width: 50%; height: 76%; top: 18%; left: 50%; }
  #features-13.py-100.shape--bg:after { width: 50%; height: 68%; top: 16%; }
  #features-13.pt-100.shape--bg:after { width: 50%; height: 76%; top: 18%; }

  .fbox-12-wrapper { padding-left: 30px; }
  .fbox-13-wrapper {  padding-right: 30px; }

  .rows-2 .fb-1, .rows-2 .fb-2, .rows-2 .fb-3 { margin-bottom: 40px; }
  .rows-2 .fbox-7.fb-1, .rows-2 .fbox-7.fb-2, .rows-2 .fbox-7.fb-3,
  .rows-2 .fbox-9.fb-1, .rows-2 .fbox-9.fb-2, .rows-2 .fbox-9.fb-3 { margin-bottom: 30px; }

  .row-cols-md-3 .fbox-4 { padding: 40px 30px; }
  .row-cols-md-2 .fbox-4 { padding: 35px 20px; } 

  .fbox-7 { padding: 40px 25px 37px; } 
  .row-cols-md-2 .fbox-7 { padding: 28px 15px 25px; }

  .fbox-8, .fbox-8.bg--white-100 { padding: 40px 25px; } 
  .fbox-9 { padding: 30px 30px 27px; } 

  .fbox-12, .fbox-13 { padding: 30px; } 

  .fbox-14 { padding: 35px 25px 30px; } 

  .fbox-img.h-180 img { max-height: 165px; }
  .fbox-img.h-175 img { max-height: 160px; }
  .fbox-img.h-170 img { max-height: 155px; }
  .fbox-img.h-165 img { max-height: 150px; }
  .fbox-img.h-160 img { max-height: 145px; }
  .fbox-img.h-155 img { max-height: 140px; }
  .fbox-img.h-150 img { max-height: 135px; }
  .fbox-img.h-145 img { max-height: 130px; }
  .fbox-img.h-140 img { max-height: 125px; }

  .fbox-2 .fbox-img.h-135 img, .fbox-2 .fbox-img.h-140 img, 
  .fbox-3 .fbox-img.h-135 img, .fbox-4 .fbox-img.h-135 img { max-height: 115px; }

  .fbox-3 .fbox-img.h-175 img, .fbox-3 .fbox-img.h-180 img { max-height: 155px; }
  .fbox-4 .fbox-img.h-170 img, .fbox-4 .fbox-img.h-175 img { max-height: 150px; }

  .fbox-1 .fbox-img, .fbox-4 .fbox-img { margin-bottom: 25px; }
  .row-cols-md-2 .fbox-1 .fbox-img, .row-cols-md-2 .fbox-3 .fbox-img, .row-cols-md-2 .fbox-4 .fbox-img { margin-bottom: 25px; }

  .fbox-2 .fbox-img.h-135, .fbox-2 .fbox-img.h-140, .fbox-2 .fbox-img.h-145, .fbox-2 .fbox-img.h-150, 
  .fbox-2 .fbox-img.h-155 { padding-top: 25px; margin-bottom: 50px; }

  .fbox-2 .fbox-img.h-160 { padding-top: 30px; margin: 0 10px 50px; }
  .fbox-2 .fbox-img.h-165, .fbox-2 .fbox-img.h-170 { padding-top: 34px; margin: 0 5px 50px; } 
  .fbox-2 .fbox-img.h-175, .fbox-2 .fbox-img.h-180 { padding-top: 35px; margin: 0 0 50px; }  

  .fbox-3 .fbox-img.h-135 { padding-top: 25px; margin: 0 0 25px; }
  .fbox-3 .fbox-img.h-160 { padding-top: 30px; margin: 0 5px 25px; }
  .fbox-3 .fbox-img.h-170, .fbox-3 .fbox-img.h-175, .fbox-3 .fbox-img.h-180 { padding-top: 35px; margin: 0 0 25px; }
  
  .fbox-4 .fbox-img.h-135 { margin-bottom: 25px; }

  .fbox-5.fb-1 .fbox-5-img, .fbox-5.fb-5 .fbox-5-img, .fbox-5.fb-6 .fbox-5-img { margin: 35px 6% 0; }
  .fbox-5.fb-2 .fbox-5-img, .fbox-5.fb-3 .fbox-5-img, .fbox-5.fb-4 .fbox-5-img { margin: 0 6% 30px; }

  .fbox-ico { margin-bottom: 18px; }
  .row-cols-md-2 .fbox-ico { margin-bottom: 15px; }
  .row-cols-md-2 .fbox-ico.ico-rounded, .fbox-ico.ico-square { margin-bottom: 20px; }
  .fbox-ico.ico-rounded-md, .fbox-ico.ico-rounded-lg { margin-bottom: 22px; }

  .fbox-wrapper .row-cols-lg-3 .fbox-title { margin-bottom: 5px; }

  .rows-2 .fbox-ico-wrap .ico-50 [class*="flaticon-"]:before, 
  .rows-2 .fbox-ico-wrap .ico-50 [class*="flaticon-"]:after { font-size: 2.85rem; }

  .rows-2 .fbox-ico-wrap { margin-right: 15px; }

  .fbox-txt h5, .features-section h6.s-22, .rows-2 .fbox-11 h6, .fbox-wrapper .row-cols-md-2 h6 { margin-bottom: 12px; }
  .fbox-14 h5 { padding-right: 8px; margin-bottom: 12px; }

  /* CONTENT */
  .ct-10 .section-overlay { margin-bottom: 80px; }
  .cbox-6-wrapper { padding: 45px; }
  .ct-11 .txt-block { padding-right: 40px; }
  .ct-12 .txt-block { padding-left: 40px; }
  .ct-13 .row.mb-60 { margin-bottom: 50px }

  /* Content Box Wrapper */
  .bc-1-wrapper .section-overlay, .bc-4-wrapper .section-overlay, .bc-6-wrapper .section-overlay { padding: 65px 50px; }
  .bc-2-wrapper .section-overlay, .bc-3-wrapper .section-overlay, .bc-5-wrapper .section-overlay { padding: 65px 50px 0; }
  .bc-6 { padding: 30px 25px; }

  .bc-2-txt h2, .bc-3-txt h2, .bc-4-txt h2, .txt-block h3, .txt-block h2 { margin-bottom: 20px; }
  .txt-block h5, .bc-6 h4 { margin-bottom: 18px; }
  h5.h5-title { margin-top: 18px; margin-bottom: 18px; }
  .cbox-2-txt h5, .cbox-3-txt h5, .bc-6 h4 { margin-bottom: 12px; }

  .ct-08 .section-title h2, .ct-09 .section-title h2, .ct-10 .section-title h2 { padding: 0 5px; }

  .txt-block .btn { margin-top: 22px; } 
  .bc-6-link.mt-15 { margin-top: 10px; }
  .txt-block-tra-link.mt-25 { margin-top: 18px; }

  /* Text Block */
  .bc-2-txt, .ct-07 .txt-block { margin-top: 20px; }
  .bc-1-wrapper .txt-block.pr-25.left-column, .bc-2-wrapper .txt-block.pr-25.left-column { padding-right: 15px; }
  .bc-1-wrapper .txt-block.pl-25.right-column, .bc-2-wrapper .txt-block.pl-25.right-column { padding-left: 15px; }

  /* Content Box */
  .cbox-5-content { padding: 35px; margin: 0 10%; }
  .cbox-5-content .divider { margin-top: 22px; margin-bottom: 22px; }

  .txt-block .cbox-5-title h2 { font-size: 3.15rem; margin-bottom: 10px; }
  .cbox-5-title .statistic-number sup { font-size: 2.85rem; top: -5px; right: 6px; }

  .cbox-5-fea { padding: 15px 20px; }

  /* Content Box Icon */
  .cbox-2 .ico-wrap { margin-right: 1.1rem; }
  .cbox-2-ico { width: 2.35rem; height: 2.35rem; font-size: 1.135rem; line-height: 2rem; }
  .ct-03 .cbox-2-ico { width: 2.15rem; height: 2.15rem; font-size: 1.0625rem; line-height: 1.95rem; }
  .cbox-2-line { height: calc(100% - 74px); }

  .cbox-3 .cbox-3-ico { top: 4px; }
  .cbox-3-ico span[class*="flaticon-"]:before, .cbox-3-ico span[class*="flaticon-"]:after { font-size: 3rem; }

  /* Content Box Text */
  .cbox-2-txt, .cbox-3-txt { margin-bottom: 25px; }
  .ct-03 .cbox-2-txt { margin-bottom: 25px; }
  .cbox-4-txt { margin-bottom: 22px; }

  /* Content Box Typography */
  .ct-03 .cbox-2-txt h5 { margin-bottom: 12px; }
  .cbox-6 h6 { margin-bottom: 12px; }
  .cbox-1-txt p { margin-bottom: 5px; }

  /* Accordion */
  .txt-block .accordion-1 .accordion-item.is-active .accordion-thumb h5 { margin-bottom: 15px; }

  /* Tabs */
  .tabs-1 { margin: 0 auto 55px; }

  /* Advantages */
  .text-center .advantages { margin-top: 15px; }

  /* Compatibility */
  .compatibility { margin-top: 10px; }

  /* Tools List / Payments Icons */
  .tools-list p, .payment-methods p { margin-bottom: 10px; }
  .payment-icons img { max-height: 32px; }

  .tools-list.ico-35 [class*="flaticon-"]:before, .tools-list.ico-35 [class*="flaticon-"]:after { 
    font-size: 2.1875rem;line-height: 2.1875rem!important; margin-right: 4px; 
  }

  /* Quote */
  .quote-avatar img { width: 54px; height: 54px; }
  .quote-author { padding: 0 0 0 10px; } 
  .quote.pr-15, .quote.pr-40, .quote.pr-50, .quote.pr-60 { padding-right: 0; }

  /* Content Box Shapes */
  .cbox-5-shape-1 { right: -20px; top: -20px; }
  .cbox-5-shape-2 { left: 10px; bottom: -25px; }
  .cbox-5-shape-1 img, .cbox-5-shape-2 img { height: 70%; width: 70%; }

  /* Content Image */
  .ct-01 .img-block { margin-left: 5px; }
  .ct-02 .img-block { margin-right: 5px; }
  .ct-03 .img-block.left-column { margin-right: 15px; }
  .ct-03 .img-block.right-column { margin-left: 15px; }
  .ct-04 .img-block { margin-left: 15px; }
  .ct-04 .img-block img { max-width: 175%; }
  .ct-10 .img-block { margin-bottom: -80px; }

  .img-block-txt, .img-block-btn { margin-top: 55px; }

  .bc-1-wrapper .img-block.left-column, .bc-2-wrapper .img-block.left-column { padding-right: 10px; }
  .bc-1-wrapper .img-block.right-column, .bc-2-wrapper .img-block.right-column { padding-left: 10px; }
  .bc-3-img { margin: 0 -65% -25px 25px; }
  .bc-4-img { margin-left: 25px; margin-right: -65%; }
  .bc-6-img { padding: 40px 30px 0; margin-bottom: 25px; }

  /* Content Images */
  .img-block.img-block-bkg img { padding: 50px 35px; }
  .ct-11 .img-block { padding-left: 50px; }
  .ct-12 .img-block { padding-right: 50px; }

  /* STATISTIC */
  .sb-rounded .statistic-block { padding: 25px; }
  #statistic-5 .statistic-digit { padding-bottom: 18px; margin: 0 70px 18px 0; }
  .statistic-1-wrapper .statistic-block-digit { width: 32%; }
  .statistic-1-wrapper .statistic-block-txt { width: 68%; padding-left: 10px; }
  #sb-2-3, #sb-2-4 { padding-right: 12px; } 

  h2.statistic-number, #statistic-5 .statistic-digit h2 { letter-spacing: -0.5px; }
  #statistic-1 h2.statistic-number { letter-spacing: -0.5px; margin-top: 2px; }
  #sb-2-1 h2.statistic-number, #sb-2-2 h2.statistic-number { margin-bottom: 10px; }

  #statistic-5 .statistic-txt h5 { margin-bottom: 10px; }
  #sb-2-3 .statistic-block p.p-sm, #sb-2-4 .statistic-block p.p-sm { margin-top: 1px; }
  .statistic-4-wrapper p { margin-bottom: 12px; }

  /* PROJECTS */
  #pt-1-1, #pt-1-2, .inner-page-hero #pt-1-3, .inner-page-hero #pt-1-4 { margin-bottom: 40px; }
  .project-details h5 {margin-bottom: 25px; }
  .more-projects { margin-top: 45px; }

  /* PROJECT DETAILS */
  .single-project .project-title { margin-bottom: 60px; }
  .project-title h2 { padding-bottom: 35px; margin-bottom: 30px; }

  .project-txt h5.mt-35.mb-35 { margin-top: 30px; margin-bottom: 30px; }
  .project-txt h5.mt-50.mb-35 { margin-top: 50px; margin-bottom: 30px; }

  .project-description .more-projects { margin-top: 50px; }
  .project-description .more-projects span { top: 1px; left: 5px; }
  .project-description .more-projects.ico-25 [class*="flaticon-"]:before, 
  .project-description .more-projects.ico-25 [class*="flaticon-"]:after { font-size: 1.275rem; } 

  /* RATING */
  .rating-section .rating-1-wrapper { padding: 0 12%; }
  .rating-section .rating-2-wrapper { padding: 0 3%; }
  .inner-page-hero .rating-1-wrapper.mt-50 { margin-top: 40px; }

  .rbox-1-img img { max-height: 34px; } 

  .rbox-1 .star-rating { font-size: 0.9rem; line-height: 1; }

  /* TESTIMONIALS */
  .reviews-section .col-md-8 .section-title h2 { padding: 0 5%; }

  .reviews-4-wrapper, .reviews-5-wrapper { padding-left: 35px; }
  #reviews-4 .txt-block.left-column, #reviews-5 .txt-block.left-column { padding-left: 10px; }

  .review-1 { padding: 35px 25px 25px; }
  .review-2 { padding: 40px 35px 30px; }
  .review-3 { padding: 40px 35px 35px; }
  .review-4 { padding: 35px 35px 25px; }
  .review-5 { padding: 40px 35px 35px; }

  #rw-3-2 { margin-bottom: 25px; }
  #rw-3-2, #rw-3-3 { margin-left: 0; }
  #rw-4-1, #rw-5-1 { margin-bottom: 25px; }

  .review-txt { margin-top: -42px; }
  .review-txt h6 { margin-bottom: 10px; }

  .review-1 .author-data { margin-top: 15px; }
  .review-2 .author-data, .review-3 .author-data, .review-5 .author-data { margin-top: 8px; }
  .review-4 .author-data { margin-top: 0; }

  .review-avatar img, .owl-item .review-avatar img { width: 44px; height: 44px; }
  .review-2 .review-avatar img, .review-3 .review-avatar img, .review-5 .review-avatar img { width: 52px; height: 52px; }

  .review-author { padding: 0 0 0 12px; }
  .review-2 .review-author, .review-3 .review-author, .review-5 .review-author { margin-top: 5px; }

  .review-author h6 { margin-bottom: 8px; }
  .review-1 .review-author h6 { font-size: 1rem; }

  /* PRESS */
  .press-logo img { max-height: 74px; }
  .press-txt { margin-top: 10px; }

  /* BRANDS */
  .brands-title.mb-40 { margin-bottom: 30px; }
  .brands-title.mb-50 { margin-bottom: 40px; }

  .brands-3-wrapper { padding: 40px 30px; }
  .brands-3-wrapper.brands-3-static { margin-left: 3%; margin-right: 3%; }

  #brands-1 .brands-carousel-5 .brand-logo, #brands-1 .brands-carousel-6 .brand-logo { padding: 0 12px; } 
  #brands-1 .justify-content-center .brand-logo, #brands-3 .brand-logo { padding: 0 12px; }  
  #brands-3 .brands-3-static .brand-logo { padding: 0 8px; }

  .brands-section .brand-logo.mb-50 { margin-bottom: 40px; }

  .brands-section .more-btn { margin-top: 30px; }

  /* INTEGRATIONS */
  .integrations-2-wrapper { padding: 80px 60px; }
  .in_tool_logo { padding: 40px 30px; margin-bottom: 25px; }
  .integrations-section .more-btn.mt-60, .integrations-section .more-btn.mt-70 { margin-top: 50px; }

  #integrations-1 .in_tool-logo.ico-60 img { width: 48px; height: 48px; }
  .integrations-1-wrapper .in_tool { padding: 16px 20px; }
  .integrations-1-wrapper .in_tool.mb-30 { margin-bottom: 20px; }

  .integrations-section .more-btn.mt-60, .integrations-section .more-btn.mt-70 { margin-top: 40px; }

  /* BANNER */
  #banner-12 { margin-top: 30px; margin-bottom: 5px; }
  #banner-9 { padding-top: 70px; padding-bottom: 70px; }

  .banner-1-wrapper .banner-overlay { padding: 60px 15% 70px; } 
  .banner-2-wrapper .banner-overlay { padding: 60px 18% 70px; }
  .banner-3-wrapper .banner-overlay { padding: 60px 22% 70px; }
  .banner-11-wrapper .banner-overlay { padding: 10px 65px 0; } 
  .banner-12-wrapper .banner-overlay, .banner-13-wrapper .banner-overlay { padding-left: 65px; } 
  .banner-16-wrapper .banner-overlay { padding: 40px 20%; }

  .banner-9-txt { padding-left: 15px; padding-right: 30%; }
  .banner-10-txt { padding-left: 25px; }
  .banner-12-txt { padding-right: 10%; }

  .banner-section .section-id { margin-bottom: 25px; }
  #banner-15.banner-section .section-id { margin-bottom: 30px; }

  .banner-1-txt h2 { margin-bottom: 16px; }
  .banner-2-txt h2 { padding: 0 2%; margin-bottom: 30px; }
  .banner-4-txt h2 { margin-bottom: 25px; }
  .col-md-8 .banner-5-txt h2 {padding: 0 5%; }
  .banner-7-txt h2 { margin-bottom: 30px; }
  .banner-10-txt h2 { margin-bottom: 22px; }
  .banner-11-txt h2, .banner-12-txt h2 { margin-bottom: 18px; }
  .banner-13-txt h2 { padding-right: 4%; margin-bottom: 18px; }
  .banner-15-txt h2 { margin-bottom: 40px; }
  .banner-16-txt h4 { margin-bottom: 10px; }

  .banner-section p.p-lg, .banner-section p.p-xl { margin-bottom: 25px; }
  .sm-title .banner-1-txt p.p-lg, .sm-title .banner-1-txt p.p-xl { margin-top: 10px; margin-bottom: 20px!important; }
  .banner-3-txt p.p-lg, .banner-3-txt p.p-xl, .banner-5-txt p.p-lg, .banner-5-txt p.p-xl { margin-top: 15px; }
  .banner-5-txt p.s-21, .banner-5-txt p.s-22 { margin-top: 15px;margin-bottom: 21px; }
  .banner-6-txt p.p-lg, .banner-6-txt p.p-xl { margin-top: 12px; margin-bottom: 22px!important; }
  .banner-9-txt p.p-lg, .banner-9-txt p.p-xl { margin-top: 15px; margin-bottom: 22px!important; }
  .banner-14-txt p.p-lg { margin-top: 28px; margin-bottom: 28px; }

  .banner-5-txt .btns-group.mt-35 { margin-top: 30px; }
  .banner-8-buttons.mt-20 { margin-top: 8px; }
  .banner-15-txt .download-links { margin-top: 20px; }
  .banner-16-txt .txt-block-tra-link.mt-15 { margin-top: 10px; }
 
  .banner-9-img { padding-right: 15px; }
  .banner-10-img { padding-left: 25px; }
  .banner-12-img { margin: -30px -20px -20px -40px; }
  .banner-13-img { margin: -60px -120px -70px -25px; }

  /* FOOTER */
  .footer { padding-bottom: 35px; }
  .footer hr { margin-top: 10px; margin-bottom: 35px; }
  img.footer-logo, img.footer-logo-dark { max-height: 34px; }
  .footer h6 { margin-bottom: 20px; }

  .footer-mail-link { font-size: 1.0625rem; }

  .foo-links li a span.ext-link { left: 3px; top: 1px; }
  .foo-links.ico-15 [class*="flaticon-"]:before, .foo-links.ico-15 [class*="flaticon-"]:after { font-size: 0.815rem; }

  .footer-socials.ico-20 a, .footer-socials.ico-25 a { margin-right: 14px; } 
  .footer-socials.ico-25 [class*="flaticon-"]:before, .footer-socials.ico-25 [class*="flaticon-"]:after { font-size: 1.1875rem; }

  #footer-2 .footer-form, #footer-3 .footer-form { padding-left: 5%; }
  .footer-form .form-control, .footer-form .btn { height: 28px; }

  .footer-form .ico-15 [class*="flaticon-"]:before, 
  .footer-form .ico-15 [class*="flaticon-"]:after { line-height: 24px!important; }
}




/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
@media (max-width: 991.98px) {
  .logo {
    img {
      width: 190px;
    }
  }
  .blockchains-wrapper {
    .blockchains {
      gap: 10px 14px;
      .center-row {
        gap: 10px 14px;
      }
      .blockChainsWithFooter_blockChains_blockchains--img {
        width: 48px;
        height: 48px;
      }
    }
  }
}

    
@media (min-width: 768px) and (max-width: 991.98px) {
  #hero-card-img {
    left: -115px;
    width: 450px;
  }

  .hero-txt h1 {
    font-size: 40px;
  }


  /*------------------------------------------*/
  /*  BASE
  /*------------------------------------------*/

  html { font-size: 85%; } 

  .py-5 { padding-top: 0; padding-bottom: 0; }
  .py-10 { padding-top: 0; padding-bottom: 0; }
  .py-15 { padding-top: 5px; padding-bottom: 5px; }
  .py-20 { padding-top: 10px; padding-bottom: 10px; }
  .py-30 { padding-top: 15px; padding-bottom: 15px; }
  .py-40 { padding-top: 20px; padding-bottom: 20px; }
  .py-50 { padding-top: 30px; padding-bottom: 30px; }
  .py-60 { padding-top: 40px; padding-bottom: 40px; }
  .py-70 { padding-top: 50px; padding-bottom: 50px; }
  .py-80 { padding-top: 50px; padding-bottom: 50px; }
  .py-90 { padding-top: 60px; padding-bottom: 60px; }
  .py-100 { padding-top: 70px; padding-bottom: 70px; }
  .py-130 { padding-top: 100px; padding-bottom: 100px; }

  .pt-30 { padding-top: 10px; }
  .pt-40 { padding-top: 20px; }
  .pt-50 { padding-top: 30px; }
  .pt-60 { padding-top: 40px; }
  .pt-70 { padding-top: 50px; }
  .pt-80 { padding-top: 50px; }
  .pt-90 { padding-top: 60px; }
  .pt-100 { padding-top: 70px; }

  .pb-30 { padding-bottom: 10px; }
  .pb-40 { padding-bottom: 20px; }
  .pb-50 { padding-bottom: 30px; }
  .pb-60 { padding-bottom: 40px; }
  .pb-70 { padding-bottom: 50px; }
  .pb-80 { padding-bottom: 50px; }
  .pb-90 { padding-bottom: 60px; }
  .pb-100 { padding-bottom: 70px; }

  .mt-40 { margin-top: 30px; }
  .mt-45 { margin-top: 35px; }
  .mt-50 { margin-top: 30px; }
  .mt-55 { margin-top: 35px; }
  .mt-60 { margin-top: 50px; }
  .mt-65 { margin-top: 55px; }
  .mt-70 { margin-top: 50px; }
  .mt-75 { margin-top: 55px; }
  .mt-80 { margin-top: 50px; }
  .mt-85 { margin-top: 55px; }
  .mt-90 { margin-top: 60px; }
  .mt-95 { margin-top: 65px; }
  .mt-100 { margin-top: 70px; }

  .mb-40 { margin-bottom: 30px; }
  .mb-45 { margin-bottom: 35px; }
  .mb-50 { margin-bottom: 30px; }
  .mb-55 { margin-bottom: 35px; }
  .mb-60 { margin-bottom: 50px; }
  .mb-65 { margin-bottom: 55px; }
  .mb-70 { margin-bottom: 50px; }
  .mb-75 { margin-bottom: 55px; }
  .mb-80 { margin-bottom: 50px; }
  .mb-85 { margin-bottom: 55px; }
  .mb-90 { margin-bottom: 60px; }
  .mb-95 { margin-bottom: 65px; }
  .mb-100 { margin-bottom: 70px; }

  /* Backgroud Shape */
  .shape--02:after { width: 140%; height: 82%; top: 12%; left: -20%; }
  .shape--03:after { height: 129%; top: -60px; }
  .shape--04:after { height: 80%; top: 10%; }

  ol.digit-list p { margin-bottom: 5px; }

  /* Font Size */
  .s-15, .s-16 { font-size: 1rem; }                /* 13.6px */
  .s-17, .s-18 { font-size: 1.066176rem; }         /* 14.5px */
  .s-19 { font-size: 1.3rem; }                     /* 18px */
  .s-20, .s-21, .s-22, .s-23 { font-size: 1.397058rem; }  /* 19px */
  .s-24, .s-26 { font-size: 1.470588rem; }  /* 20px */
  .s-30, .s-32, .s-34 { font-size: 1.764705rem; }  /* 24px */ 
  .s-36, .s-38, .s-40 { font-size: 1.98529rem; }   /* 27px */   
  .s-42, .s-43, .s-44, .s-45 { font-size: 2.20588rem; }   /* 30px */
  .s-46 { font-size: 2.35294rem; }                 /* 32px */
  .s-47 { font-size: 2.42647rem; }                 /* 33px */
  .s-48 { font-size: 2.5rem; }                     /* 34px */ 
  .s-50 { font-size: 2.64705rem; }                 /* 36px */ 
  .s-52, .s-54 { font-size: 2.79411rem; }          /* 38px */
  .s-56 { font-size: 2.94117rem; }                 /* 40px */
  .s-58 { font-size: 3.08823rem; }                 /* 42px */
  .s-60 { font-size: 3.23529rem; }                 /* 44px */
  .s-62 { font-size: 3.38235rem; }                 /* 46px */
  .s-64 { font-size: 3.52941rem; }                 /* 48px */
  .s-66 { font-size: 3.67647rem; }                 /* 50px */
  .s-68 { font-size: 3.82352rem; }                 /* 52px */

  /* Paragraphs */
  p.p-sm { font-size: 1rem; }                 
  p.p-md, p.p-lg { font-size: 1.066176rem; } 

  /* Transparent Link */
  a.tra-link { font-size: 1.0625rem; }
  .tra-link.ico-20 [class*="flaticon-"]:before, 
  .tra-link.ico-20 [class*="flaticon-"]:after { font-size: 0.785rem; line-height: 0.7rem!important; top: 2px; left: 2px; }

  /* Button */
  .btn.btn-sm { font-size: 0.975rem; padding: 0.65rem 1.35rem; }
  .btn.ico-30.ico-left { padding: 0.3665rem 0; }
  .btns-group .btn.ico-30.ico-left, .btns-group .btn.ico-30.ico-right { padding: 0.31175rem 0.5rem; }

  /* Buttons Group */
  .btns-group .btn:first-child { margin-right: 10px; }

  /* Button Icon */
  .btn.ico-20 [class*="flaticon-"]:before, 
  .btn.ico-20 [class*="flaticon-"]:after { font-size: 1rem; line-height: 0.65rem!important;top: 3px; }

  .btn.ico-30 [class*="flaticon-"]:before, .btn.ico-30 [class*="flaticon-"]:after { font-size: 2rem; }

  .btn.ico-30.ico-left span.ico-30-txt { font-size: 1.065rem; top: -8px; margin-right: 0; }
  .btn.ico-30.ico-right span.ico-30-txt { font-size: 1.065rem; top: -8px; margin-left: 0; }
  .btn.ico-left span { margin-right: 2px; }
  .btn.ico-right span { margin-left: 2px; }

  /* Button Text */
  p.btn-txt { margin: 13px 0 0 0; }
  p.btns-group-txt, .btn-rating { margin: 15px 0 0 0; }
  .text-center p.btns-group-txt { margin-top: 18px; }
  .star-rating span.rating-txt { font-size: 1rem; }

  /* OS Button */
  .os-btn { width: 240px; min-width: 240px; padding: 14px 15px 12px; }
  .os-btn-ico { margin-right: 14px; }
  .os-btn-txt p { margin-top: 4px; }

  .btn-os.mb-15 { margin-bottom: 15px; }
  .btn-os.mr-15 { margin-right: 10px; }

  /* Section ID */
  .section-id { margin-bottom: 25px; }
  .section-id.rounded-id { padding: 7px 22px; margin-bottom: 30px; }

  /* Section Title */
  .section-title.mb-60, .section-title.mb-70, .section-title.mb-80 { margin-bottom: 40px; }

  .section-title p.p-md, .section-title p.p-lg, .section-title p.p-xl { margin-top: 12px; }
  .section-title p.s-20, .section-title p.s-21 { font-size: 1.32352rem; margin-top: 12px; }
  .section-title p.p-md, .section-title p.p-lg { padding: 0; }

  .section-title .btns-group.mt-25 { margin-top: 18px; }
  .section-title .btns-group.mt-35 { margin-top: 22px; }
  .section-title .stores-badge.mt-30 { margin-top: 25px; }

  .section-title .advantages, .section-title .btns-txt, .section-title .btns-group-txt { margin-top: 15px; }

  /*------------------------------------------*/
  /*   HERO   
  /*------------------------------------------*/

  .hero-section { margin-top: 80px; }

  .hero-section h2 { margin-bottom: 18px; }
  .hero-section h2.s-48, .hero-section h2.s-50 { margin-bottom: 24px; }

  .hero-section p.p-md, .hero-section p.p-lg, .hero-section p.p-xl { margin-bottom: 24px; }
  .hero-section p.s-20, .hero-section p.s-22, .hero-section p.s-24 { margin-bottom: 26px; }
  .hero-section p.s-21 { font-size: 1.397058rem; margin-bottom: 22px; }
  .hero-section .btn.mt-10, .hero-section .btns-group.mt-10 { margin-top: 5px; }
  .hero-section .btn.mt-15, .hero-section .btns-group.mt-15 { margin-top: 8px; }

  /* HERO LOGO */
  .hero-logo { margin-bottom: 30px; }
  .hero-logo img { max-height: 28px; }
  .hero-square-logo img { max-height: 65px; }

  /* HERO APP LOGO */
  .hero-app-logo { margin-bottom: 25px; }
  .hero-app-logo img { max-height: 78px; }

  /* hero */
  #hero { padding-top: 0px; padding-bottom: 0px; margin-top: 0; }
  .hero-txt h1 { margin-bottom: 20px; }
  .hero-txt p.p-lg { padding-right: 0; margin-bottom: 25px; }

  /*------------------------------------------*/
  /*  CONTENT
  /*------------------------------------------*/

  /* FEATURES */
  .fbox-14-wrapper { padding: 60px 50px; }
  .fbox-14-wrapper .col { padding: 0 14px; }

  #features-12.py-100.shape--bg:after { width: 52%; height: 68%; top: 16%; left: 48%; }
  #features-12.pt-100.shape--bg:after { width: 52%; height: 75%; top: 19%; left: 48%; }
  #features-13.py-100.shape--bg:after { width: 52%; height: 68%; top: 16%; }
  #features-13.pt-100.shape--bg:after { width: 52%; height: 75%; top: 19%; }

  .fbox-12-wrapper { padding-left: 0; }
  .fbox-13-wrapper {  padding-right: 0; }

  #features-4 .row-cols-md-3 .col { padding: 0 8px; }
  .row-cols-md-2 .fb-1, .row-cols-md-2 .fb-2, .rows-2 .fb-1, .rows-2 .fb-2, .rows-2 .fb-3  { margin-bottom: 35px; }

  .fbox-14.fb-1, .fbox-14.fb-2 { margin-bottom: 30px; }

  .row-cols-md-3 .fbox-4 { padding: 30px 15px; }
  .row-cols-md-2 .fbox-4 { padding: 30px 45px; }
  .fbox-5 { padding: 35px 25px; } 

  .row-cols-md-2 .fbox-4.fb-1, .row-cols-md-2 .fbox-4.fb-2, .row-cols-md-2 .fbox-7.fb-1, .row-cols-md-2 .fbox-7.fb-2, 
  .row-cols-md-2 .fbox-9.fb-1, .row-cols-md-2 .fbox-9.fb-2, .rows-2 .fbox-7.fb-1, .rows-2 .fbox-7.fb-2, .rows-2 .fbox-7.fb-3, 
  .rows-2 .fbox-9.fb-1, .rows-2 .fbox-9.fb-2, .rows-2 .fbox-9.fb-3 { margin-bottom: 22px; }

  .fbox-7 { padding: 30px 15px 27px; }
  .fbox-8, .fbox-8.bg--white-100 { padding: 30px 15px; }
  .fbox-9 { padding: 25px 15px 22px; } 

  .fbox-12, .fbox-13 { padding: 22px 18px; } 
  .fbox-12.mb-30, .fbox-13.mb-30 { margin-bottom: 20px; } 

  .fbox-14 { text-align: center; padding: 40px 30px 35px; } 

  .fbox-img.h-155 img, .fbox-img.h-160 img, .fbox-img.h-165 img, 
  .fbox-img.h-170 img, .fbox-img.h-175 img, .fbox-img.h-180 img { max-height: 128px; }

  .fbox-2 .fbox-img.h-135 img, .fbox-3-wrapper .fbox-img.h-135 img, 
  .fbox-img.h-140 img, .fbox-img.h-145 img, .fbox-img.h-150 img { max-height: 132px; }

  .fbox-2 .fbox-img.h-160 img, .fbox-2 .fbox-img.h-165 img, .fbox-2 .fbox-img.h-170 img, 
  .fbox-2 .fbox-img.h-175 img, .fbox-2 .fbox-img.h-180 img, .fbox-3 .fbox-img.h-160 img, 
  .fbox-3 .fbox-img.h-170 img, .fbox-3 .fbox-img.h-175 img, .fbox-3 .fbox-img.h-180 img { max-height: 120px; }

  .fbox-4 .fbox-img.h-160 img, .fbox-4 .fbox-img.h-165 img, 
  .fbox-4 .fbox-img.h-170 img, .fbox-4 .fbox-img.h-175 img { max-height: 110px; }

  .fbox-1 .fbox-img, .row-cols-md-2 .fbox-1 .fbox-img, .fbox-4 .fbox-img, .row-cols-md-2 .fbox-4 .fbox-img { margin-bottom: 25px; }

  .fbox-2 .fbox-img.h-135, .fbox-2 .fbox-img.h-140, .fbox-2 .fbox-img.h-145, .fbox-2 .fbox-img.h-150, 
  .fbox-2 .fbox-img.h-155 { padding-top: 25px; margin: 0 40px 45px; }

  .fbox-2 .fbox-img.h-160 { padding-top: 25px; margin: 0 0 45px; }

  .fbox-2 .fbox-img.h-165, .fbox-2 .fbox-img.h-170, 
  .fbox-2 .fbox-img.h-175, .fbox-2 .fbox-img.h-180 { padding-top: 30px; margin: 0 0 45px; }  

  .fbox-3 .fbox-img.h-160, .fbox-3 .fbox-img.h-170, 
  .fbox-3 .fbox-img.h-175, .fbox-3 .fbox-img.h-180 { padding-top: 25px; margin: 0 0 25px; }

  .fbox-3 .fbox-img.h-135 { padding-top: 30px; margin: 0 40px 25px; }

  .fbox-5.fb-1 .fbox-5-img, .fbox-5.fb-5 .fbox-5-img, .fbox-5.fb-6 .fbox-5-img { margin: 30px 6% 0; }
  .fbox-5.fb-2 .fbox-5-img, .fbox-5.fb-3 .fbox-5-img, .fbox-5.fb-4 .fbox-5-img { margin: 0 6% 25px; }

  .fbox-ico { margin-bottom: 18px; }
  .row-cols-md-2 .fbox-ico.ico-rounded, .fbox-ico.ico-rounded-md, 
  .fbox-ico.ico-rounded-lg, .fbox-ico.ico-square { margin-bottom: 18px; }

  .fbox-wrapper .row-cols-md-2 .ico-50 [class*="flaticon-"]:before, 
  .fbox-wrapper .row-cols-md-2 .ico-50 [class*="flaticon-"]:after { font-size: 3.75rem; }

  .fbox-wrapper .row-cols-md-2 .ico-rounded { width: 80px; height: 80px; }
  .fbox-wrapper .row-cols-md-2 .ico-rounded [class*="flaticon-"]:before, 
  .fbox-wrapper .row-cols-md-2 .ico-rounded [class*="flaticon-"]:after { font-size: 2.875rem; line-height: 80px!important; }

  .fbox-wrapper .row-cols-md-2 .ico-square { width: 80px; height: 80px; }
  .fbox-wrapper .row-cols-md-2 .ico-square [class*="flaticon-"]:before, 
  .fbox-wrapper .row-cols-md-2 .ico-square [class*="flaticon-"]:after { font-size: 2.875rem; line-height: 80px!important; }

  .fbox-10 .fbox-title.ico-35 [class*="flaticon-"]:before, 
  .fbox-10 .fbox-title.ico-35 [class*="flaticon-"]:after { font-size: 2.75rem; }

  .fbox-ico-wrap { margin-right: 18px; }
  .rows-2 .fbox-ico-wrap { margin-right: 0; }
  .rows-2 .fbox-11 { display: block; text-align: center; }
  .rows-2 .fbox-11 .fbox-ico { margin-top: 0; }

  .fbox-10 .fbox-title span { float: none; line-height: 1!important; margin-right: 0; }
  .fbox-10 .fbox-title h6 { line-height: 1!important; margin-top: 10px; margin-bottom: 15px; }

  .features-section h6.s-18 { font-size: 1.397058rem; margin-bottom: 12px; }
  .features-section h6.s-20, .features-section h6.s-22, .fbox-txt h5 { margin-bottom: 12px; }
  .rows-2 .fbox-11 h6, .fbox-wrapper .row-cols-md-2 h6 { margin-bottom: 12px; }
  .fbox-12 h5, .fbox-13 h5 { font-size: 1.3rem; margin-bottom: 10px; }
  .fbox-14 h5 { padding-right: 0; margin-bottom: 12px; }
  .row-cols-md-2 .fbox-7 p { padding: 0 15%; } 
  .fbox-14 p { padding: 0 20%; }

  /* CONTENT */
  .ct-10 .section-overlay { margin-bottom: 80px; }
  .cbox-6-wrapper { padding: 40px 35px; }

  .ct-11 .txt-block { padding-right: 25px; }
  .ct-12 .txt-block { padding-left: 25px; }
  .ct-13 .row.mb-60 { margin-bottom: 30px }

  /* Content Box Wrapper */
  .bc-1-wrapper .section-overlay, .bc-4-wrapper .section-overlay { padding: 60px 40px; }
  .bc-2-wrapper .section-overlay, .bc-3-wrapper .section-overlay, .bc-5-wrapper .section-overlay { padding: 60px 40px 0; }
  .bc-6-wrapper .section-overlay { padding: 60px 30px; }
  .bc-6 { padding: 24px 16px; }

  .bc-2-txt h2, .bc-3-txt h2, .bc-4-txt h2, .txt-block h3, .txt-block h2 { margin-bottom: 15px; }
  .txt-block h5, .bc-6 h4 { margin-bottom: 18px; }
  h5.h5-title { margin-top: 18px; margin-bottom: 18px; }

  .txt-block p.s-20, .txt-block p.s-21 { font-size: 1.14705rem; }

  .ct-08 .section-title h2, .ct-09 .section-title h2, .ct-10 .section-title h2 { padding: 0 5%; }
  .bc-5-wrapper .section-title {  margin-bottom: 35px; }

  .txt-box { margin-bottom: 5px; }
  .txt-block .txt-box h5 { margin-bottom: 13.5px; }
  .bc-6-txt h5 { margin-bottom: 12px; }
  ul.simple-list li:not(:last-child) p { margin-bottom: 8px; }
  .txt-block .btn { margin-top: 15px; } 
  .txt-block .btns-group .btn.btn--transparent { margin-top: 10px; } 
  .txt-block .stores-badge.mt-15, .ws-wrapper .stores-badge.mt-15 { margin-top: 5px; }
  .bc-6-link.mt-15 { margin-top: 10px; }
  .txt-block-tra-link.mt-25 { margin-top: 14px; }

  .img-block-txt .box-title h6, .img-block-txt .box-title h5 { line-height: 1; margin-bottom: 15px; }
  .img-block-txt .box-title span { display: block; top: 0; margin-right: 0; }

  /* Text Block */
  .ct-07 .txt-block { margin-top: 30px; }
  .bc-1-wrapper .txt-block.pr-25.left-column, .bc-2-wrapper .txt-block.pr-25.left-column { padding-right: 0; }
  .bc-1-wrapper .txt-block.pl-25.right-column, .bc-2-wrapper .txt-block.pl-25.right-column { padding-left: 0; }
  .bc-6-txt { padding: 0; }

  /* Content Box */
  .cbox-5-content { padding: 30px; margin: 0 5%; }
  .cbox-5-content .divider { margin-top: 22px; margin-bottom: 22px; }

  .txt-block .cbox-5-title h2 { font-size: 3rem; margin-bottom: 10px; }
  .cbox-5-title .statistic-number sup { font-size: 2.5rem; top: -5px; right: 6px; }

  .cbox-5-fea { padding: 12px 16px; margin-top: 18px; }

  #cb-6-1, #cb-6-2 { margin-bottom: 30px; }

  /* Content Box Icon */
  .cbox-2 .ico-wrap { margin-right: 1.15rem; }
  .cbox-2-ico { width: 2.15rem; height: 2.15rem; font-size: 1.125rem; line-height: 1.85rem; }
  .ct-03 .cbox-2-ico { width: 2rem; height: 2rem; font-size: 1.0625rem; line-height: 1.8rem; }
  .cbox-2-line { height: calc(100% - 74px); }

  .cbox-3 .cbox-3-ico { top: 5px; }
  .cbox-3-ico span[class*="flaticon-"]:before, .cbox-3-ico span[class*="flaticon-"]:after { font-size: 3rem; }

  /* Content Box Text */
  .cbox-2-txt, .ct-03 .cbox-2-txt, .cbox-3-txt { margin-bottom: 20px; }
  .cbox-4-txt { margin-bottom: 16px; }

  /* Content Box Typography */
  .cbox-2-txt h5, .cbox-3-txt h5 { margin-bottom: 10px; }
  .cbox-6 h6 { margin-bottom: 12px; }
  .cbox-1-txt p {  margin-bottom: 5px; }

  /* Accordion */
  .txt-block .accordion-1 .accordion-item { padding: 18px 30px; }
  .txt-block .accordion-2 .accordion-item { padding: 10px 0 20px; }
  .txt-block .accordion-1 .accordion-item.is-active { padding: 30px 30px 14px 30px; }
  .txt-block .accordion-2 .accordion-item.is-active { padding: 15px 0 20px; }

  .txt-block .accordion-1 .accordion-item .accordion-thumb:before { font-size: 0.85rem; top: 0.5px; }

  .txt-block .accordion-1 .accordion-panel { padding: 0; }

  .accordion-2 .accordion-thumb span[class*="flaticon-"]:before, 
  .accordion-2 .accordion-thumb span[class*="flaticon-"]:after { font-size: 2.1875rem; line-height: 1.5rem; top: 9px; }

  .txt-block .accordion-1 .accordion-thumb h5 { padding-left: 20px; }
  .txt-block .accordion-1 .accordion-item.is-active .accordion-thumb h5 { margin-bottom: 15px; }

  /* Tabs */
  .tabs-1 { margin: 0 auto 50px; }
  .tabs-1 li { font-size: 1.125rem; }

  /* Advantages */
  .text-center .advantages { margin-top: 15px; }

  /* Compatibility */
  .compatibility { margin-top: 5px; }

  /* Tools List / Payments Icons */
  .tools-list p, .payment-methods p { margin-bottom: 10px; }
  .payment-icons img { max-height: 26px; }

  .tools-list.ico-35 [class*="flaticon-"]:before, 
  .tools-list.ico-35 [class*="flaticon-"]:after {  font-size: 2rem; line-height: 2rem!important; margin-right: 4px; }

  /* Quote */
  .quote-avatar img { width: 54px; height: 54px; }
  .quote p { font-size: 1.05rem; padding-left: 14px; margin-top: 15px; }
  .quote-author { padding: 0 0 0 10px; } 
  .quote.pr-15, .quote.pr-40, .quote.pr-50, .quote.pr-60 { padding-right: 0; }

  /* Content Box Shapes */
  .cbox-5-shape-1 { right: -55px; top: -20px; }
  .cbox-5-shape-2 { left: -20px; bottom: -25px; }
  .cbox-5-shape-1 img, .cbox-5-shape-2 img { height: 55%; width: 55%; }

  /* Content Images */
  .img-block.img-block-bkg img { padding: 35px 20px; }
  .ct-10 .img-block { margin-bottom: -80px; }

  .ct-01 .img-block, .ct-03 .img-block.right-column { margin-left: 0; }
  .ct-02 .img-block, .ct-03 .img-block.left-column { margin-right: 0; }
  .ct-04 .img-block { margin-left: 15px; }
  .ct-04 .img-block img { max-width: 220%; }
  .ct-06 .img-block { margin-right: -20%; }
  .ct-07 .img-block { margin-left: -20%; }

  .img-block-txt, .img-block-btn { margin-top: 45px; }

  .bc-1-wrapper .img-block.left-column, .bc-2-wrapper .img-block.left-column { padding-right: 0; }
  .bc-1-wrapper .img-block.right-column, .bc-2-wrapper .img-block.right-column { padding-left: 0; }
  .bc-2-img { margin: 0 -45% 0 15px; }
  .bc-3-img { margin: 0 -90% -25px 15px; }
  .bc-4-img { margin-left: 15px; margin-right: -100%; }
  .bc-5-img.bc-5-tablet.img-block-hidden {
    .img-tablet {
      max-width: 100%;
    }
  }
  .bc-6-img { padding: 30px 20px 0; margin-bottom: 20px; }

  .bc-5-img.bc-5-tablet.img-block-hidden .video-btn { top: calc(50% - 60px); }

  .ct-11 .img-block { padding-left: 15px; }
  .ct-12 .img-block { padding-right: 15px; }
  .ct-13 .img-block { margin-top: 30px; }

  /* STATISTIC */
  .sb-rounded .statistic-block { padding: 20px; }
  #sb-2-1, #sb-2-2 { width: 50%; }

  #statistic-5 .statistic-digit { padding-bottom: 15px; margin: 0 40px 15px 0; }
  #statistic-1 .statistic-block { display: block!important; text-align: center; }
  .statistic-1-wrapper .statistic-block-digit { width: 100%; display: block!important; float: none; margin-bottom: 15px; }
  .statistic-1-wrapper .statistic-block-txt { width: 100%; display: inline-block!important; padding: 0 8%; }

  #sb-2-3, #sb-2-4 { width: 50%; padding-right: 0; }
  #sb-2-3 .statistic-block, #sb-2-4 .statistic-block { display: block!important; }
  #sb-2-3 .statistic-block-txt, #sb-2-4 .statistic-block-txt { padding-left: 0; }
  #statistic-2 .statistic-block-digit { display: block!important; }

  h2.statistic-number { letter-spacing: -0.5px; }
  .txt-block-stat h2.statistic-number, #statistic-5 .statistic-digit h2 { font-size: 2.5rem; letter-spacing: -0.5px; }
  #statistic-1 h2.statistic-number { font-size: 3.5rem; line-height: 1; letter-spacing: -0.5px; }
  #sb-2-1 h2.statistic-number, #sb-2-2 h2.statistic-number { margin-bottom: 10px; }
  #sb-2-3 .statistic-block p.p-sm, #sb-2-4 .statistic-block p.p-sm { margin-top: 10px; }

  #statistic-5 .statistic-txt h5 { margin-bottom: 10px; }
  .statistic-3-wrapper p { margin-top: 12px; }
  .statistic-4-wrapper p { margin-bottom: 12px; }
  #statistic-5 .statistic-txt p { padding-right: 0; }

  .txt-block-stat .statistic-block .txt-block-rating { margin-top: -2px; margin-bottom: -1px; }

  /* PROJECTS */
  .projects-wrapper { padding: 0; }
  #pt-1-1, #pt-1-2, .inner-page-hero #pt-1-3, .inner-page-hero #pt-1-4 { margin-bottom: 35px; }
  .project-details h5 { margin-bottom: 20px; }
  .more-projects { margin-top: 35px; }

  /* PROJECT DETAILS */
  .single-project .project-title { margin-bottom: 40px; }
  .project-title h2 { padding-right: 10%; padding-bottom: 30px; margin-bottom: 25px; }

  .project-priview-img.mb-50 { margin-bottom: 40px; }
  .project-txt h5.mt-35.mb-35 { margin-top: 20px; margin-bottom: 20px; }
  .project-txt h5.mt-50.mb-35 { margin-top: 40px; margin-bottom: 20px; }

  .project-description .more-projects { margin-top: 45px; }
  .project-description .more-projects span { top: 1px; left: 4px; }
  .project-description .more-projects.ico-25 [class*="flaticon-"]:before, 
  .project-description .more-projects.ico-25 [class*="flaticon-"]:after { font-size: 1.175rem; } 

  /* RATING */
  .rating-section .rating-1-wrapper { padding: 0 10%; }
  .rating-section .rating-2-wrapper { padding: 0; }
  .inner-page-hero .rating-1-wrapper.mt-50 { margin-top: 40px; }

  .rbox-1 .star-rating { font-size: 1rem; }
  .rbox-2-txt { margin-top: 14px; }
  p.rbox-2-txt { margin-top: 10px; line-height: 1; }

  .rbox-1-img { margin-bottom: 12px; }
  .rbox-1-img img { max-height: 28px; }
  .rbox-2-txt img { display: block; max-height: 28px; margin: 8px auto 0; }

  .rbox-2 .ico-25 [class*="flaticon-"]:before, .rbox-2 .ico-25 [class*="flaticon-"]:after { font-size: 1.3rem; } 

  /* TESTIMONIALS */
  .reviews-2-wrapper, .reviews-3-wrapper { padding: 0; }
  .reviews-4-wrapper, .reviews-5-wrapper { padding-left: 15px; }
  #reviews-4 .txt-block.left-column p, #reviews-5 .txt-block.left-column p { margin-right: -10px; }

  .review-1, .review-2, .review-3 { padding: 35px 25px 25px; }
  .review-4, .review-5 { padding: 35px 30px 25px; }

  #rw-2-1, #rw-2-2 { margin-bottom: 12px; }
  #rw-3-2 { margin-bottom: 30px; }
  #rw-3-2, #rw-3-3 { margin-left: 8px; }
  #rw-4-1, #rw-5-1 { margin-bottom: 25px; }

  .review-txt { margin-top: -38px; }
  .review-txt h6 { margin-bottom: 10px; }

  .review-1 .author-data, .review-2 .author-data, 
  .review-3 .author-data, .review-4 .author-data, .review-5 .author-data { margin-top: 0; }

  .review-avatar img, .owl-item .review-avatar img { width: 40px; height: 40px; }
  .review-2 .review-avatar img, .review-3 .review-avatar img, .review-5 .review-avatar img { width: 46px; height: 46px; }

  .review-author { padding: 0 0 0 12px; }
  .review-1 .review-author, .review-2 .review-author, .review-3 .review-author, .review-5 .review-author { margin-top: 3px; }

  .review-author h6 { margin-bottom: 5px; }
  .review-1 .review-author h6 { font-size: 1rem; }

  /* PRESS */
  #press-05, #press-06 { margin-bottom: 30px; }
  .press-logo img { max-height: 74px; }
  .press-txt { margin-top: 10px; }

  /* BRANDS */
  .brands-title.mb-35, .brands-title.mb-40 { margin-bottom: 25px; }
  .brands-title.mb-50 { margin-bottom: 35px; }

  .brands-title h4, .brands-title h3, .brands-title h2 { margin-bottom: 5px; }

  .brands-3-wrapper { padding: 35px 25px; }
  .brands-3-wrapper.brands-3-static { margin-left: 3%; margin-right: 3%; }

  #brands-1 .brands-carousel-5 .brand-logo { padding: 0 12px; } 
  #brands-1 .brands-carousel-6 .brand-logo { padding: 0 10px; } 
  #brands-1 .justify-content-center .brand-logo, #brands-3 .brand-logo { padding: 0 10px; }  
  #brands-3 .brands-3-static .brand-logo { padding: 0 8px; }
  #brands-4 .brand-logo { padding: 0 8px; } 

  .brands-section .brand-logo.mb-50 { margin-bottom: 40px; }
  .brands-section .more-btn { margin-top: 30px; }

  /* INTEGRATIONS */
  .integrations-2-wrapper { padding: 60px 40px; }

  .in_tool_logo { padding: 30px; margin-bottom: 25px; }
  .in_tool_logo.ico-65 img { width: 48px; height: 48px; }
  .in_tool h6 { line-height: 1.3; }

  #integrations-1 .in_tool-logo.ico-60 img { width: 52px; height: 52px; }
  .integrations-1-wrapper .in_tool { padding: 16px; }
  .integrations-1-wrapper .in_tool.mb-30, .integrations-1-wrapper .it-8 { margin-bottom: 20px; }
  .integrations-1-wrapper .in_tool-txt h6 { margin-top: 2px; }
  .integrations-1-wrapper .in_tool-txt p { font-size: 1.0625rem; margin-top: 0; }  #footer-10.pt-50 { padding-top: 10px; }

  img.footer-logo, img.footer-logo-dark { max-height: 33px; }

  .footer h6 { font-size: 1.17647rem; margin-bottom: 16px; } /* 16px */

  .footer .foo-links li p { margin-bottom: 8px; }

  .foo-links li a span.ext-link { left: 3px; top: 1px; }
  .foo-links.ico-15 [class*="flaticon-"]:before, .foo-links.ico-15 [class*="flaticon-"]:after { font-size: 0.775rem; }

  .footer-socials.ico-20 a, .footer-socials.ico-25 a { margin-right: 11px; } 
  .footer-socials.ico-20 li:last-child a, .footer-socials.ico-25 li:last-child a { margin-right: 0; } 
  .footer-socials.ico-25 [class*="flaticon-"]:before, .footer-socials.ico-25 [class*="flaticon-"]:after { font-size: 1.175rem; }

  #footer-2 .footer-form { padding-left: 3%; }
  #footer-7 .footer-form { padding-left: 0; }
  .footer-form .form-control, .footer-form .btn { height: 28px; }
  .footer-form .ico-15 [class*="flaticon-"]:before, .footer-form .ico-15 [class*="flaticon-"]:after { line-height: 24px!important; }

  #footer-8 .bottom-footer { margin-top: 0; }
}




/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */

@media (max-width: 767px) {

  .md-text-center {
    text-align: center;
  }

  .logo {
    img {
      width: 125px;
    }
  }

  .s-60, .s-62, .hero-txt .s-60  { font-size: 2.625rem; }                 /* 42px */
  .hero-section p.p-md, .hero-section p.p-lg, .hero-section p.p-xl { margin-bottom: 25px; font-size: 16px}
  .hero-section p.s-21 { font-size: 1.375rem; margin-bottom: 22px; }

  .scroll-bound .content {
    justify-content: flex-start;
  }

  #hero-card-img {
    left: -20px;
    width: unset;
    max-height: calc(100vh - 500px);
  }

  .header {
    //margin: 0 0 20px;
    padding: 15px;
    top: 0
  }

  #hero .hero_social {
    margin-top: 30px;
    justify-content: center;
  }

  /*------------------------------------------*/
  /*  BASE
  /*------------------------------------------*/

  .block-shadow { 
    -webkit-box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .08); 
    -moz-box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .08); 
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .08); 
  }

  .ts-0 { padding-top: 80px; }

  .py-5 { padding-top: 0; padding-bottom: 0; }
  .py-10 { padding-top: 0; padding-bottom: 0; }
  .py-15 { padding-top: 5px; padding-bottom: 5px; }
  .py-20 { padding-top: 10px; padding-bottom: 10px; }
  .py-30 { padding-top: 15px; padding-bottom: 15px; }
  .py-40 { padding-top: 20px; padding-bottom: 20px; }
  .py-50 { padding-top: 30px; padding-bottom: 30px; }
  .py-60 { padding-top: 40px; padding-bottom: 40px; }
  .py-70 { padding-top: 50px; padding-bottom: 50px; }
  .py-80 { padding-top: 50px; padding-bottom: 50px; }
  .py-90 { padding-top: 60px; padding-bottom: 60px; }
  .py-100 { padding-top: 70px; padding-bottom: 70px; }
  .py-130 { padding-top: 100px; padding-bottom: 100px; }

  .pt-30 { padding-top: 10px; }
  .pt-40 { padding-top: 20px; }
  .pt-50 { padding-top: 30px; }
  .pt-60 { padding-top: 40px; }
  .pt-70 { padding-top: 50px; }
  .pt-80 { padding-top: 50px; }
  .pt-90 { padding-top: 60px; }
  .pt-100 { padding-top: 70px; }

  .pb-30 { padding-bottom: 10px; }
  .pb-40 { padding-bottom: 20px; }
  .pb-50 { padding-bottom: 30px; }
  .pb-60 { padding-bottom: 40px; }
  .pb-70 { padding-bottom: 50px; }
  .pb-80 { padding-bottom: 50px; }
  .pb-90 { padding-bottom: 60px; }
  .pb-100 { padding-bottom: 70px; }

  .mt-40 { margin-top: 30px; }
  .mt-45 { margin-top: 35px; }
  .mt-50 { margin-top: 30px; }
  .mt-55 { margin-top: 35px; }
  .mt-60 { margin-top: 50px; }
  .mt-65 { margin-top: 55px; }
  .mt-70 { margin-top: 50px; }
  .mt-75 { margin-top: 55px; }
  .mt-80 { margin-top: 50px; }
  .mt-85 { margin-top: 55px; }
  .mt-90 { margin-top: 60px; }
  .mt-95 { margin-top: 65px; }
  .mt-100 { margin-top: 70px; }

  .mb-40 { margin-bottom: 30px; }
  .mb-45 { margin-bottom: 35px; }
  .mb-50 { margin-bottom: 30px; }
  .mb-55 { margin-bottom: 35px; }
  .mb-60 { margin-bottom: 50px; }
  .mb-65 { margin-bottom: 55px; }
  .mb-70 { margin-bottom: 50px; }
  .mb-75 { margin-bottom: 55px; }
  .mb-80 { margin-bottom: 50px; }
  .mb-85 { margin-bottom: 55px; }
  .mb-90 { margin-bottom: 60px; }
  .mb-95 { margin-bottom: 65px; }
  .mb-100 { margin-bottom: 70px; }

  h2, h3, h4, h5, h6 { line-height: 1.35; }

  p.p-sm { font-size: 1rem; }

  /* Button Text */
  p.btn-txt { margin: 12px 0 0 0; }
  p.btns-group-txt { margin: 12px 0 0 0; }
  .text-center p.btns-group-txt { margin-top: 16px; }

  /* Section ID */
  .section-id { margin-bottom: 24px; }
  .section-id.rounded-id { margin-bottom: 35px; }

  /* Section Title */
  .section-title.mb-60 { margin-bottom: 40px; }
  .section-title.mb-70, .section-title.mb-80 { margin-bottom: 50px; }

  .section-title .btns-group.mt-25 { margin-top: 18px; }
  .section-title .btns-group.mt-35 { margin-top: 22px; }
  .section-title .stores-badge.mt-30 { margin-top: 25px; }

  .section-title .advantages, .section-title .btns-txt, .section-title .btns-group-txt { margin-top: 15px; }

  /*------------------------------------------*/
  /*   HERO   
  /*------------------------------------------*/

  .hero-section { margin-top: 80px; }

  .hero-section h2 { margin-bottom: 15px; }
  .hero-section h2.s-48, .hero-section h2.s-50 { margin-bottom: 25px; }
  .hero-section h2.s-62 { margin-bottom: 15px; }

  .hero-section p.p-sm { padding: 0!important; }
  .hero-section p.p-md, .hero-section p.p-lg, .hero-section p.p-xl { margin-bottom: 22px; }
  .hero-section p.s-20, .hero-section p.s-21, .hero-section p.s-22, .hero-section p.s-24 { margin-bottom: 24px; }
  .hero-section .btn.mt-10, .hero-section .btns-group.mt-10 { margin-top: 5px; }
  .hero-section .btn.mt-15, .hero-section .btns-group.mt-15 { margin-top: 8px; }

  /* hero */
  #hero { text-align: center; padding-top: 0px; padding-bottom: 0; }
  .hero-txt p.p-lg { padding-right: 0%; }
  .hero-img { margin: 0 0 -50px; }

   /*------------------------------------------*/
  /*  CONTENT
  /*------------------------------------------*/

  /* ABOUT */
  .about-2-title { margin-bottom: 30px; }

  .a-2-1 { display: none; margin-left: 0; margin-bottom: 30px; } 
  .a-2-2 { margin-left: 0; margin-bottom: 30px; }
  .a-2-3 { margin-right: 0; margin-bottom: 30px; }
  .a-2-4 { display: none; margin-right: 0; }

  #a3-1 { margin-bottom: 25px; }

  #about-1 .txt-block h3.mb-30, .about-2-title h2.mb-30 { margin-bottom: 20px; }
  .txt-block h5.mb-20 { margin-bottom: 15px; }
  #about-4 .txt-block p { margin-top: 18px; }

  #a4-1 { display: none; }
  #a4-2 { padding-left: 0; margin-top: 30px; margin-bottom: 0; }

  /* FEATURES */
  .fbox-14-wrapper .col { padding: 0 12px; }

  #features-12.py-100.shape--bg:after { width: 100%; height: 70%; top: 30%; left: 0; }
  #features-12.pt-100.shape--bg:after { width: 100%; height: 66%; top: 30.25%; left: 0; }
  #features-13.py-100.shape--bg:after { width: 100%; height: 70%; top: 30%; }
  #features-13.pt-100.shape--bg:after { width: 100%; height: 66%; top: 30.25%; }

  .fbox-12-wrapper { text-align: center; padding-left: 0; }
  .fbox-13-wrapper { text-align: center; padding-right: 0; }

  .fb-1, .fb-2, .rows-2 .fb-1, .rows-2 .fb-2, .rows-2 .fb-3, .rows-2 .fb-4, .rows-2 .fb-5, .row-cols-md-2 .fb-3, .fbox-5.fb-3, 
  .fbox-5.fb-5, .rows-2 .fbox-7.fb-1, .rows-2 .fbox-7.fb-2, .rows-2 .fbox-7.fb-3, .rows-2 .fbox-9.fb-1, .rows-2 .fbox-9.fb-2, 
  .rows-2 .fbox-9.fb-3, .rows-3 .fbox-11.fb-5 { margin-bottom: 40px; }

  .rows-3 .fb-3.fbox-11-lst { margin-bottom: 40px; }

  .fbox-5.fb-2, .fbox-5.fb-4, .fbox-5.fb-6 { margin-bottom: 0; }

  .fbox-5.fb-1, .fbox-5.fb-5, .fbox-5.fb-6 { display: flex; flex-flow: wrap; }

  .fbox-8.fb-1 { padding-top: 0; margin-bottom: 0; }
  .fbox-8.fb-2 { margin-bottom: 0; }
  .fbox-8.fb-3 { padding-bottom: 0; margin-bottom: 0; } 

  .fbox-9, .fbox-10 { text-align: center; }

  #fb-12-1, #fb-12-2, #fb-12-3, #fb-13-1, #fb-13-2, #fb-13-3 { margin-bottom: 40px; }
  #fb-12-1, #fb-13-1 { margin-top: 75px; }
  #features-12 .txt-block, #features-13 .txt-block { margin-bottom: 40px; }

  .fbox-14 { text-align: center; }
  .fbox-14.fb-1, .fbox-14.fb-2 { margin-bottom: 35px; }

  .fbox-1 .fbox-img { margin-bottom: 30px; }
  .fbox-2 .fbox-img img { margin-bottom: -20px; } 

  .fbox-5-1 .fbox-5-img, .fbox-5-2 .fbox-5-img, .fbox-5-3 .fbox-5-img, 
  .fbox-5-4 .fbox-5-img, .fbox-5-5 .fbox-5-img, .fbox-5-6 .fbox-5-img { margin: 0 5% 40px; }

  .fbox-ico { margin-bottom: 15px; }
  .row-cols-md-2 .fbox-ico.ico-rounded, .fbox-ico.ico-rounded-md, 
  .fbox-ico.ico-rounded-lg, .fbox-ico.ico-square { margin-bottom: 20px; }

  .fbox-11 { display: block; text-align: center; }
  .fbox-11 .fbox-ico { margin-top: 0; }
  .fbox-ico-wrap, .rows-2 .fbox-ico-wrap  { margin-right: 0; }

  .fbox-title { display: inline-block; text-align: center; margin: 0 auto 8px; }
  .fbox-wrapper .row-cols-lg-3 .fbox-title { margin-bottom: 0; } 
  .fbox-title h6 { display: inline-block; }
  .fbox-14 h5 { padding-right: 0; margin-bottom: 12px; }

  /* CONTENT */
  .ct-11, .ct-12 { padding: 0; }
  .ct-11 .txt-block { padding-right: 0; }
  .ct-12 .txt-block { padding-left: 0; }
  .ct-top { margin-bottom: 80px; }

  .ct-13 .row.mb-60 { margin-bottom: 0; }
  .ct-13 .txt-block, .ct-13 .img-block { margin-bottom: 40px; }
  .ct-13 .txt-block.ml-block, .ct-13 .img-block.ml-block { margin-bottom: 0; }
  .bc-6-txt { padding: 0; }

  .ct-11:after, .ct-12:after { width: 100%; top: 0; left: 0; }

  .bc-2-txt h2, .bc-3-txt h2, .bc-4-txt h2, .txt-block h3, .txt-block h2 { margin-bottom: 15px; }
  .txt-block h5 { margin-bottom: 18px; }
  h5.h5-title { margin-top: 18px; margin-bottom: 18px; }

  .txt-block .btn, .txt-block .btns-group .btn.btn--transparent { margin-top: 10px; }

  /* Content Box Wrapper */
  .bc-1-wrapper .section-overlay, .bc-4-wrapper .section-overlay, 
  .bc-2-wrapper .section-overlay, .bc-3-wrapper .section-overlay { padding: 60px 50px; }

  /* Text Block */
  .ct-06 { padding-top: 0!important; padding-bottom: 80px; margin-top: 80px; margin-bottom: 0; }
  .ct-10 .section-overlay { margin-bottom: 80px; }
  .ct-06 .txt-block, .ct-07 .txt-block, .bc-2-txt, .bc-2-wrapper .txt-block, .bc-3-txt { margin-top: 0; }

  #ib-box-1, #ib-box-2 { margin-bottom: 25px; }
  #cb-6-1, #cb-6-2, #cb-6-3 { margin-bottom: 30px; }

  .bc-1-wrapper .txt-block.pr-25.left-column, .bc-2-wrapper .txt-block.pr-25.left-column { padding-right: 0; }
  .bc-1-wrapper .txt-block.pl-25.right-column, .bc-2-wrapper .txt-block.pl-25.right-column { padding-left: 0; }

  .bc-6-top { margin-bottom: 40px; }

  /* Tabs */
  .tabs-1 { margin: 0 auto 40px; }
  .tabs-1 li { display: block;font-size: 1.135rem; margin: 0 20px 10px; } 
  .tabs-1 li.current { margin: 0 20px 20px; }
  #tabs-1 .tab-content .img-block { margin-bottom: 40px; }

  /* Advantages */
  .text-center .advantages { margin-top: 16px; }

  /* Quote */
  .quote-avatar img { width: 54px; height: 54px; }
  .quote p { padding-left: 14px; margin-top: 15px; }
  .quote-author { padding: 0 0 0 10px; } 
  .quote.pr-15, .quote.pr-40, .quote.pr-50, .quote.pr-60 { padding-right: 0; }

  /* Content Images */
  .img-block.img-block-bkg { margin-left: 0!important; margin-right: 0!important; }
  .ct-10 .img-block{ margin-bottom: -80px; }
  .ct-01 .img-block { margin-left: 0; margin-bottom: 40px; }
  .ct-02 .img-block { margin-right: 0; margin-bottom: 40px; }
  .ct-03 .img-block, .ct-05 .img-block, .ct-07 .img-block { margin-bottom: 40px; }
  .ct-03 .img-block.right-column { margin-left: 0; }
  .ct-03 .img-block.left-column { margin-right: 0; }
  .ct-04 .img-block { margin-left: 0; margin-bottom: 40px; }
  .ct-04 .img-block img { max-width: 100%; }
  .ct-06 .img-block { margin-top: -80px; margin-bottom: 40px; }

  .bc-1-wrapper .img-block.left-column, .bc-2-wrapper .img-block.left-column { padding-right: 0; }
  .bc-1-wrapper .img-block.right-column, .bc-2-wrapper .img-block.right-column { padding-left: 0; }

  //.bc-1-wrapper .img-block, .bc-2-wrapper .img-block { margin-bottom: 40px; }
  .bc-2-img, .bc-3-img, .bc-4-img { margin: 0 0 40px 0; }
  .bc-5-img.bc-5-tablet.img-block-hidden {
    .img-tablet {
      max-width: 100%;
    }
  }

  .bc-5-img.bc-5-tablet.img-block-hidden .video-btn { top: calc(50% - 30px); }

  .ct-11 .img-block { margin-bottom: 40px; padding-left: 0; }
  .ct-12 .img-block { margin-bottom: 40px; padding-right: 0; }

  .ct-11 .img-block, .ct-12 .img-block { margin-bottom: 40px; padding: 50px 15%; border-radius: 12px; overflow: hidden; }
  .ct-11 .img-block .tra-bkg, .ct-12 .img-block .tra-bkg { opacity: 100; -moz-opacity: 100; }

  /* STATISTIC */
  #sb-2-1, #sb-2-2, #sb-2-3, #sb-2-4 { width: 40%; padding-right: 0; }
  #sb-1-1, #sb-1-2, #sb-3-1, #sb-3-2 { margin-bottom: 40px; }
  #sb-4-1, #sb-4-2 { margin-bottom: 30px; }
  #sb-5-1, #sb-5-2 { margin-bottom: 40px; }

  #statistic-5 { text-align: center; }
  #statistic-1 .statistic-block { display: block!important; text-align: center; }
  .statistic-1-wrapper .statistic-block-digit { width: 100%; display: block!important; float: none; margin-bottom: 15px; }
  .statistic-1-wrapper .statistic-block-txt { width: 100%; display: inline-block!important; padding-left: 0; }
  #statistic-5 .statistic-digit { padding-bottom: 20px; margin: 0 0 20px 0; }

  #sb-2-3 .statistic-block, #sb-2-4 .statistic-block { display: block!important; }
  #sb-2-3 .statistic-block-txt, #sb-2-4 .statistic-block-txt { padding-left: 0; }
  #statistic-2 .statistic-block-digit { display: block!important; }

  #sb-2-1 h2.statistic-number, #sb-2-2 h2.statistic-number { margin-bottom: 10px; }
  #sb-2-3 .statistic-block p.p-sm, #sb-2-4 .statistic-block p.p-sm { margin-top: 10px; }
  .statistic-3-wrapper p { margin-top: 12px; }
  .statistic-4-wrapper p { margin-bottom: 12px; }
  #statistic-5 .statistic-txt p { padding-right: 0; }

  .txt-block-stat .statistic-block .txt-block-rating { margin-top: -2px; margin-bottom: -1px; }

  /* PROJECTS */
  .projects-wrapper { padding: 0; }
  #pt-1-1, #pt-1-2, #pt-1-3, .inner-page-hero #pt-1-3, .inner-page-hero #pt-1-4, .inner-page-hero #pt-1-5 { margin-bottom: 40px; }
  .project-details h5 { margin-bottom: 25px; }
  .more-projects { margin-top: 40px; }

  /* RATING */
  .rating-section .rating-1-wrapper, .rating-section .rating-2-wrapper { padding: 0; }
  .inner-page-hero .rating-1-wrapper.mt-50 { margin-top: 40px; }

  #rb-1-1, #rb-1-2, #rb-2-1, #rb-2-2 { margin-bottom: 30px; }
  .rbox-2-txt img { max-height: 24px; top: -1px; left: 6px; }
  p.rbox-2-txt { line-height: 24px; margin-top: 8px; }

  /* TESTIMONIALS */
  .reviews-2-wrapper, .reviews-3-wrapper { padding: 0; }
  .reviews-4-wrapper, .reviews-5-wrapper { padding-left: 0; }
  #reviews-4 .txt-block, #reviews-5 .txt-block { margin-bottom: 40px; }

  #rw-2-1, #rw-2-2, #rw-2-3, #rw-3-1, #rw-3-2 { margin-bottom: 30px; }
  #rw-3-2, #rw-3-3 { margin-left: 0; }
  .inner-page-hero #rw-2-3, .inner-page-hero #rw-2-4, .inner-page-hero #rw-2-5 { margin-bottom: 30px; }

  /* INTEGRATIONS */
  .integrations-2-wrapper { padding: 60px 40px 70px; }
  .it-1, .it-2, .it-3, .it-4, .it-5 { display: block; margin-bottom: 30px; }
  .in_tool_logo { display: inline-block; padding: 30px; margin-bottom: 25px; }
  .in_tool_logo.ico-65 img { width: 52px; height: 52px; }
  .in_tool h6 { font-size: 1rem; }

  .integrations-1-wrapper .it-7, .integrations-1-wrapper .it-8 { margin-bottom: 30px; }

  /* FOOTER */

  .bottom-footer .bottom-secondary-link.text-end, .bottom-footer .bottom-footer-list.text-end { text-align: left!important; }

}

@media (min-width: 767.98px){
  .trimmed-description-wrapper .description{
    -webkit-line-clamp: unset;
    line-clamp: unset;
  }
  .see-more-link {
    display: none !important;
  }
}


/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */

@media (min-width: 480px) and (max-width: 767.98px) {

  .logo {
    img {
      width: 116px;
    }
  }
  .blockchains-wrapper {
    flex-direction: column;
    align-items: center;
    gap: 17px;
    .blockchains {
      justify-content: center;
      gap: 10px 18px;
      max-width: 230px;
      .center-row {
        gap: 10px 18px;
      }
      .blockChainsWithFooter_blockChains_blockchains--img {
        width: 50px;
        height: 50px;
      }
    }
  }


  /*------------------------------------------*/
  /*  BASE
  /*------------------------------------------*/

  .bg--fixed { width: auto; }
  .bg--scroll { width: auto; background-attachment: scroll!important; }

  /* Backgroud Shape */
  .shape--01:after { height: 90%; top: 6%; }
  .shape--02:after { width: 140%; height: 92%; top: 5.5%; left: -20%; }
  .shape--03:after { height: 128%; top: -65px; }
  .shape--04:after { height: 90%; top: 0; }
  .shape--07:after { height: 80%; top: 15%; }

  .font--jakarta h1, .font--jakarta h2 { line-height: 1.35; }
  .font--jakarta h3, .font--jakarta h4, .font--jakarta h5, .font--jakarta h6 { line-height: 1.4; }

  /* Font Size */
  .s-15, .s-16 { font-size: 1.0625rem; }                /* 17px */
  .s-17 { font-size: 1.125rem; }                        /* 18px */
  .s-18, .s-19, .s-20, .s-21, .s-22 { font-size: 1.4375rem; }  /* 23px */
  .s-24, .s-26 { font-size: 16px; }          /* 26px */
  .s-30, .s-32, .s-34 { font-size: 1.81255rem; }        /* 29px */
  .s-36, .s-38, .s-40 { font-size: 2.0625rem; }         /* 33px */  
  .s-42, .s-43, .s-44, .s-45, .s-46, .s-47, .s-48 { font-size: 2.25rem; }  /* 36px */
  .s-50, .s-52, .s-54, .s-56 { font-size: 2.375rem; }   /* 38px */ 
  .s-58 { font-size: 2.5rem; }                          /* 40px */
  .s-60, .s-62, .hero-txt .s-60  { font-size: 2.625rem; }                 /* 42px */


  .s-64, .s-66 { font-size: 2.75rem; }                  /* 44px */
  .s-68 { font-size: 2.875rem; }                        /* 46px */

  /* Paragraphs */
  p.p-sm { font-size: 1rem; } 
  p { font-size: 1.0625rem; } 
  p.p-md, p.p-lg { font-size: 1.125rem; }
  p.p-xl { font-size: 1.1875rem; }

  /* Transparent Link */
  a.tra-link { font-size: 1.125rem; }
  .tra-link.ico-20 [class*="flaticon-"]:before, 
  .tra-link.ico-20 [class*="flaticon-"]:after { font-size: 0.785rem; line-height: 0.7rem!important; top: 1px; left: 2px; }

  /* Button */
  .btn { font-size: 1.125rem; }
  .btn.btn-sm { font-size: 1rem; padding: 0.7rem 1.5rem; }
  .btns-group .btn.ico-30.ico-left, .btns-group .btn.ico-30.ico-right { padding: 0.3845rem 0.5rem; }

  /* Button Icon */
  .btn.ico-20 [class*="flaticon-"]:before, 
  .btn.ico-20 [class*="flaticon-"]:after { font-size: 1.225rem; line-height: 0.45rem!important;top: 4px; }

  .btn.ico-30 [class*="flaticon-"]:before, .btn.ico-30 [class*="flaticon-"]:after { font-size: 2.05rem; }

  .btn.ico-30.ico-left span.ico-30-txt { font-size: 1.15rem; top: -10px; margin-right: 0; }
  .btn.ico-30.ico-right span.ico-30-txt { font-size: 1.15rem; top: -10px; margin-left: 0; }
  .btn.ico-left span { margin-right: 3px; }
  .btn.ico-right span { margin-left: 3px; }

  /* Button Text */
  p.btn-txt, p.btns-group-txt, .btn-rating { margin: 15px 0 0 0; }
  .text-center p.btns-group-txt { margin-top: 18px; }
  .star-rating span.rating-txt { font-size: 1.0625rem; }

  /* Section ID */
  .section-id { margin-bottom: 25px; }

  /* Section Title */
  .section-title p.p-md, .section-title p.p-lg, .section-title p.p-xl { margin-top: 14px; }
  .section-title p.s-20, .section-title p.s-21 { font-size: 1.21875rem; margin-top: 12px; }  /* 19.5px */
  .section-title p.p-md, .section-title p.p-lg { padding: 0; }

  .section-title .btns-group.mt-25 { margin-top: 20px; }
  .section-title .btns-group.mt-35, .section-title .stores-badge.mt-30 { margin-top: 25px; }

  /*------------------------------------------*/
  /*   HERO   
  /*------------------------------------------*/

  .hero-section h2 { margin-bottom: 15px; }
  .hero-section h2.s-48, .hero-section h2.s-50 { margin-bottom: 25px; }
  .hero-section p.p-md, .hero-section p.p-lg, .hero-section p.p-xl { margin-bottom: 25px; font-size: 16px}
  .hero-section p.s-21 { font-size: 1.375rem; margin-bottom: 22px; }

  /* hero */
  #hero { margin-bottom: 0; margin-top: 0; }
  .hero-txt {padding: 40px 5% 0 }

  /*------------------------------------------*/
  /*  CONTENT
  /*------------------------------------------*/
  /* FEATURES */
  .fbox-14-wrapper { padding: 70px 50px; }

  .row-cols-md-3 .fbox-4, .row-cols-md-2 .fbox-4 { padding: 45px 65px 40px; }
  .fbox-5 { padding: 50px 50px 45px; } 
  .fbox-7, .row-cols-md-2 .fbox-7 { padding: 45px 50px 40px; }
  .fbox-8.fb-1 { padding: 0 75px 50px; }
  .fbox-8.bg--white-100 {padding: 45px 75px; }
  .fbox-8.fb-3 { padding: 55px 75px 0; }
  .fbox-9 { padding: 40px 75px 35px; } 

  .fbox-12, .fbox-13 { padding: 40px 50px; } 

  .fbox-img.h-135 img, .fbox-img.h-140 img, .fbox-img.h-145 img, .fbox-img.h-150 img, .fbox-img.h-155 img, 
  .fbox-img.h-160 img, .fbox-img.h-165 img, .fbox-img.h-170 img, .fbox-img.h-175 img, .fbox-img.h-180 img { max-height: 220px; }

  .fbox-1 .fbox-img, .row-cols-md-2 .fbox-1 .fbox-img, .fbox-4 .fbox-img, .row-cols-md-2 .fbox-4 .fbox-img { margin-bottom: 30px; }

  .fbox-2 .fbox-img.h-135, .fbox-2 .fbox-img.h-140, .fbox-2 .fbox-img.h-145, 
  .fbox-2 .fbox-img.h-150, .fbox-2 .fbox-img.h-155, .fbox-2 .fbox-img.h-160, 
  .fbox-2 .fbox-img.h-165, .fbox-2 .fbox-img.h-170, .fbox-2 .fbox-img.h-175, 
  .fbox-2 .fbox-img.h-180 { padding-top: 46px; margin: 0 10% 50px; }

  .fbox-3 .fbox-img.h-135, .fbox-3 .fbox-img.h-140, .fbox-3 .fbox-img.h-145, 
  .fbox-3 .fbox-img.h-150, .fbox-3 .fbox-img.h-155, .fbox-3 .fbox-img.h-160, 
  .fbox-3 .fbox-img.h-165, .fbox-3 .fbox-img.h-170, .fbox-3 .fbox-img.h-175, 
  .fbox-3 .fbox-img.h-180 { padding-top: 46px; margin: 0 10% 30px; }

  .fbox-5.fb-1 .fbox-5-img, .fbox-5.fb-2 .fbox-5-img, .fbox-5.fb-3 .fbox-5-img, 
  .fbox-5.fb-4 .fbox-5-img, .fbox-5.fb-5 .fbox-5-img, .fbox-5.fb-6 .fbox-5-img { margin: 0 10% 30px; }

  .fbox-ico { margin-bottom: 18px; }
  .row-cols-md-2 .fbox-ico.ico-rounded, .fbox-ico.ico-rounded-md, 
  .fbox-ico.ico-rounded-lg, .fbox-ico.ico-square { margin-bottom: 22px; }

  .fbox-wrapper .ico-50 [class*="flaticon-"]:before, 
  .fbox-wrapper .ico-50 [class*="flaticon-"]:after,
  .fbox-wrapper .ico-55 [class*="flaticon-"]:before, 
  .fbox-wrapper .ico-55 [class*="flaticon-"]:after,
  .fbox-wrapper .ico-60 [class*="flaticon-"]:before, 
  .fbox-wrapper .ico-60 [class*="flaticon-"]:after,
  .fbox-10 .fbox-title.ico-35 [class*="flaticon-"]:before,
  .fbox-10 .fbox-title.ico-35 [class*="flaticon-"]:after { font-size: 4rem; }

  .features-section h6.s-18, .features-section h6.s-20, .features-section h6.s-22 { font-size: 1.625rem; }  /* 26px */
  .features-section h6, .fbox-txt h5 { margin-bottom: 15px; }

  .fbox-10 .fbox-title { margin: 0 auto; }
  .fbox-10 .fbox-title span { float: none; line-height: 1!important; margin-right: 0; }
  .fbox-10 .fbox-title h6 { display: block; line-height: 1!important; margin-top: 15px; margin-bottom: 15px; }
  .fbox-14 h5 { padding: 0 14% }

  .fbox-1 .fbox-txt p, .fbox-10 .fbox-txt p { padding: 0 5%; }
  .fbox-2 .fbox-txt p { padding: 0 6%; }
  .rows-2 .fbox-11 p { padding: 0 8%; } 
  .fbox-6 .fbox-txt p { padding: 0 5%; }
  .row-cols-lg-3 .fbox-10 .fbox-txt p { padding: 0; }
  .rows-3 .fbox-11 p { padding: 0 2%; }

  /* CONTENT */
  .ct-10 .section-overlay { margin-bottom: 50px; }

  /* Content Box Wrapper */
  .bc-1-wrapper .section-overlay, .bc-2-wrapper .section-overlay, 
  .bc-3-wrapper .section-overlay, .bc-4-wrapper .section-overlay, .bc-6-wrapper .section-overlay { padding: 70px 50px; }
  #ludo-rank-dao .section-overlay { padding: 15px 32px 40px; }
  #web3-curators-network .bc-1-wrapper .section-overlay { padding: 40px 32px 15px; }
  .bc-5-wrapper .section-overlay { padding: 70px 40px 0; }
  .cbox-6-wrapper { padding: 45px 35px; }

  .bc-6 { padding: 35px 30px 40px; }
  
  .bc-2-txt h2, .bc-3-txt h2, .bc-4-txt h2, .txt-block h3, .txt-block h2 { margin-bottom: 20px; }
  .txt-block h5 { margin-bottom: 18px; }
  h5.h5-title { margin-top: 18px; margin-bottom: 18px; }
  .cbox-2-txt h5, .cbox-3-txt h5, .bc-6-txt h5 { margin-bottom: 12px; }

  .txt-block p.s-20, .txt-block p.s-21 { font-size: 1.3125rem; }

  .txt-block .btn { margin-top: 18px; } 
  .txt-block .stores-badge.mt-15, .ws-wrapper .stores-badge.mt-15 { margin-top: 8px; }
  .txt-block-tra-link.mt-25 { margin-top: 18px; }

  .box-title h6, .box-title h5 { margin-bottom: 20px; }
  .cbox-6 h6 { margin-bottom: 12px; }

  /* Content Box */
  .ct-03 .cbox-2-ico { width: 2.15rem; height: 2.15rem; font-size: 1.0625rem; line-height: 1.95rem; }

  .right-column .cbox-5 { margin-bottom: 60px; }
  .cbox-5-content { padding: 35px; margin: 0 ; }
  .cbox-5-content .divider { margin-top: 22px; margin-bottom: 22px; }

  .cbox-5-fea { margin-top: 15px; }
  .txt-block .cbox-5-title h2 { font-size: 3.25rem; margin-bottom: 10px; }
  .cbox-5-title .statistic-number sup { font-size: 2.75rem; top: -5px; right: 6px; }

  .cbox-5-fea { padding: 15px 20px; margin-top: 20px; }
  .cbox-5-txt .btn { margin-top: 10px; }

  .cbox-3 .cbox-3-ico { top: 4px; }

  /* Accordion */
  .txt-block .accordion-1 .accordion-item { padding: 18px 25px; }
  .txt-block .accordion-1 .accordion-item.is-active { padding: 30px 25px 14px 25px; }

  .txt-block .accordion-1 .accordion-item .accordion-thumb:before { top: 0; }
  .txt-block .accordion-1 .accordion-panel { padding: 0; }

  .txt-block .accordion-2 .accordion-item .accordion-thumb:after, 
  .txt-block .accordion-2 .accordion-item.is-active .accordion-thumb:after {top: 14px; }

  /* Advantages */
  .advantages, .text-center .advantages { margin-top: 15px; }

  /* Compatibility */
  .compatibility { margin-top: 10px; }

  /* Tools List / Payments Icons */
  .tools-list p, .payment-methods p { margin-bottom: 15px; }
  .payment-icons img { max-height: 32px; }

  .tools-list.ico-35 [class*="flaticon-"]:before, .tools-list.ico-35 [class*="flaticon-"]:after { 
    font-size: 2.125rem; line-height: 2.5rem!important; margin-right: 5px; 
  }

  /* Content Box Shapes */
  .cbox-5-shape-1 { right: -50px; top: -30px; }
  .cbox-5-shape-2 { left: -30px; bottom: -25px; }
  .cbox-5-shape-1 img, .cbox-5-shape-2 img { height: 80%; width: 80%; }

  /* Content Images */
  .ct-10 .img-block{ margin-bottom: -50px; }
  .ct-01 .img-block, .ct-02 .img-block, 
  .ct-03 .img-block.right-column, .ct-03 .img-block.left-column { margin-left: 3%; margin-right: 3%; }

  .ct-01 .img-block.j-img, .ct-02 .img-block.j-img { margin-left: 0%; margin-right: 0; }
  .img-block-txt, .img-block-btn { margin-top: 45px; }

  .ct-11 .img-block, .ct-12 .img-block { margin-bottom: 40px; padding: 50px 10%; }
  .bc-6-img { padding: 35px 30px 0; margin-bottom: 25px; }

  /* STATISTIC */
  .sb-rounded .statistic-block { text-align: center; padding: 25px; }
  #statistic-5 .statistic-digit { padding-bottom: 20px; margin: 0 12% 20px; }

  .statistic-1-wrapper .statistic-block-digit { margin-bottom: 12px; }

  h2.statistic-number { font-size: 3.5rem; letter-spacing: -0.5px; }
  .txt-block-stat h2.statistic-number { font-size: 2.35rem; }
  #statistic-1 h2.statistic-number { letter-spacing: -0.5px; }
  .txt-block-stat h2.statistic-number { font-size: 2.5rem; letter-spacing: -0.5px; }
  #statistic-5 .statistic-digit h2 { font-size: 2.5rem; letter-spacing: -0.5px; }
  #sb-2-1 h2.statistic-number, #sb-2-2 h2.statistic-number { margin-bottom: 12px; }

  #statistic-5 .statistic-txt h5 { margin-bottom: 12px; }
  .txt-block-stat .statistic-block p { font-size: 0.95rem; }
  #sb-2-3 .statistic-block p.p-sm, #sb-2-4 .statistic-block p.p-sm { margin-top: 12px; }
  #statistic-5 .statistic-txt p { padding: 0 5%; }

  /* PROJECTS */
  #pt-1-1, #pt-1-2, #pt-1-3, .inner-page-hero #pt-1-3, .inner-page-hero #pt-1-4, .inner-page-hero #pt-1-5 { margin-bottom: 40px; }

  /* PROJECT DETAILS */
  .single-project .project-title { margin-bottom: 50px; }
  .project-title h2 { padding-bottom: 35px; margin-bottom: 30px; }
  .project-data p { margin-bottom: 10px; }

  .project-priview-img.mb-50 { margin-bottom: 40px; }
  .project-inner-img .top-img { margin-bottom: 30px; }
  .project-txt h5.mt-35.mb-35 { margin-top: 25px; margin-bottom: 25px; }
  .project-txt h5.mt-50.mb-35 { margin-top: 40px; margin-bottom: 25px; }

  .project-description .more-projects { margin-top: 45px; }
  .project-description .more-projects span { top: 1px; left: 5px; }
  .project-description .more-projects.ico-25 [class*="flaticon-"]:before, 
  .project-description .more-projects.ico-25 [class*="flaticon-"]:after { font-size: 1.2125rem; } 

  /* RATING */
  .rating-title h5 { font-size: 1.1875rem; }
  .rbox-2-txt img { max-height: 34px; }
  p.rbox-2-txt { line-height: 30px; margin-top: 8px; }
  .rbox-1 .star-rating { font-size: 0.9rem; line-height: 1; }

  .rbox-1 .star-rating span { top: 2px; }
  .rbox-1 .ico-10 [class*="flaticon-"]:before, .rbox-1 .ico-10 [class*="flaticon-"]:after { font-size: 0.9rem; } 
  .rbox-1 .ico-15 [class*="flaticon-"]:before, .rbox-1 .ico-15 [class*="flaticon-"]:after { font-size: 1rem; } 
  .rbox-2 .ico-20 [class*="flaticon-"]:before, .rbox-2 .ico-20 [class*="flaticon-"]:after { font-size: 1.35rem; } 
  .rbox-2 .ico-25 [class*="flaticon-"]:before, .rbox-2 .ico-25 [class*="flaticon-"]:after { font-size: 1.35rem; } 

  /* TESTIMONIALS */
  .reviews-section .col-md-8 .section-title h2 { padding: 0 5%; }

  #reviews-4 .txt-block, #reviews-5 .txt-block { text-align: center; padding: 0 10%; }
  .review-1 { padding: 35px 32px 30px; }
  .review-2, .review-3, .review-4, .review-5 { padding: 35px 35px 30px; }

  .review-txt { margin-top: -43px; }
  .review-txt h6 { margin-bottom: 10px; }

  .review-1 .author-data, .review-2 .author-data, 
  .review-3 .author-data, .review-4 .author-data, .review-5 .author-data { margin-top: 20px; }

  .review-avatar img, .owl-item .review-avatar img { width: 52px; height: 52px; }
  .review-2 .review-avatar img, .review-3 .review-avatar img, .review-5 .review-avatar img { width: 52px; height: 52px; }

  .review-author { padding: 0 0 0 12px; }
  .review-1 .review-author, .review-2 .review-author, .review-3 .review-author, .review-5 .review-author { margin-top: 3px; }

  .review-author h6 { margin-bottom: 8px; }
  .review-1 .review-author h6, .review-2 .review-author h6, .review-3 .review-author h6, 
  .review-4 .review-author h6, .review-5 .review-author h6 { font-size: 1.25rem; }

  /* BRANDS */
  .brands-title { padding: 0; } 
  .brands-title.mb-35, .brands-title.mb-40 { margin-bottom: 30px; }
  .brands-title.mb-50 { margin-bottom: 40px; }

  .brands-title h5.s-19 { font-size: 1.25rem; }
  .brands-title h4, .brands-title h3, .brands-title h2 { margin-bottom: 8px; }

  .brands-3-wrapper { padding: 35px 25px; }
  .brands-3-wrapper.brands-3-static { padding: 35px 30px; }

  #brand-2-1, #brand-2-2, #brand-2-3, #brand-4-6, #brands-4 .brand-logo.mb-40 { margin-bottom: 35px; }
  #brand-3-1, #brand-3-2 { margin-bottom: 25px; }
  #brand-4-10 { display: none; }

  #brands-1 .brands-carousel-5 .brand-logo, #brands-1 .justify-content-center .brand-logo { padding: 0 20px; }   
  #brands-2 .brand-logo { padding: 0 10px; } 
  #brands-3 .brand-logo { padding: 0 15px; } 
  #brands-3 .brands-3-static .brand-logo { padding: 0 8px; } 
  #brands-4 .brand-logo { padding: 0 15px; } 

  .brands-section .brand-logo.mb-50 { margin-bottom: 35px; }

  .brands-section .more-btn { margin-top: 40px; }

  /* INTEGRATIONS */
  #integrations-1 .section-title h2 { padding: 0 5%; }

  .integrations-1-wrapper .in_tool { padding: 25px; }
  .integrations-1-wrapper .in_tool-txt h6 { font-size: 1.4rem; line-height: 1; margin-top: 4px; }
  .integrations-1-wrapper .in_tool-txt p { font-size: 1.0625rem; margin-top: 6px; }

  .integrations-section .more-btn.mt-60, .integrations-section .more-btn.mt-70 { margin-top: 30px; }

  /* FOOTER */
  .footer { padding-bottom: 30px; }
  .footer hr { margin-top: 15px; margin-bottom: 30px; }
  .footer h6 { font-size: 1.21875rem; margin-bottom: 20px; } /* 19.5px */
}




/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */

@media (min-width: 414px) and (max-width: 479.98px) {

  .logo {
    img {
      width: 116px;
    }
  }
  .blockchains-wrapper {
    flex-direction: column;
    align-items: center;
    gap: 17px;
    .blockchains {
      justify-content: center;
      gap: 10px 18px;
      max-width: 230px;
      .center-row {
        gap: 10px 18px;
      }
      .blockChainsWithFooter_blockChains_blockchains--img {
        width: 50px;
        height: 50px;
      }
    }
  }

  /*------------------------------------------*/
  /*  BASE
  /*------------------------------------------*/

  #stlChanger { display: none; }
  .division { padding-left: 16px; padding-right: 16px; }
  .features { padding-left: 10px; padding-right: 10px; }
  .bg--fixed { width: auto; }
  .bg--scroll { width: auto; background-attachment: scroll!important; }

  /* Backgroud Shape */
  .shape--01:after { height: 90%; top: 6.5%; }
  .shape--02:after { width: 140%; height: 90%; top: 6.5%; left: -20%; }
  .shape--03:after { width: 140%; height: 129%; top: -70px; left: -20%; }
  .shape--04:after { height: 94%; top: 0; }
  .shape--06:after { height: 92%; }
  .shape--07:after { height: 80%; top: 14%; }

  ol.digit-list p { margin-bottom: 5px; }

  .font--jakarta h1, .font--jakarta h2 { line-height: 1.35; }
  .font--jakarta h3, .font--jakarta h4, .font--jakarta h5, .font--jakarta h6 { line-height: 1.4; }

  /* Font Size */
  .s-15, .s-16 { font-size: 1.0625rem; }                              /* 17px */
  .s-17 { font-size: 1.125rem; }                                      /* 18px */
  .s-18, .s-19, .s-20, .s-21, .s-22, .s-23 { font-size: 1.3125rem; }  /* 21px */
  .s-24, .s-26 { font-size: 1.4375rem; }                       /* 23px */
  .s-30, .s-32, .s-34 { font-size: 1.5625rem; }                       /* 25px */
  .s-36, .s-38, .s-40 { font-size: 1.6875rem; }                       /* 27px */
  .s-42, .s-43, .s-44, .s-45, .s-46, .s-47 { font-size: 1.8125rem; }  /* 29px */
  .s-48 { font-size: 1.9375rem; }                                     /* 31px */
  .s-50, .s-52, .s-54, .s-56, .s-58, .s-60 { font-size: 2.0625rem; }  /* 33px */
  .s-62 { font-size: 2.125rem; }                                      /* 34px */
  .s-64, .s-66 { font-size: 2.25rem; }                                /* 36px */
  .s-68 { font-size: 2.375rem; }                                      /* 38px */

  /* Paragraphs */
  p.p-sm { font-size: 1rem; } 
  p { font-size: 1.0625rem; } 
  p.p-md, p.p-lg { font-size: 1.125rem; }

  /* Transparent Link */
  a.tra-link { font-size: 1.125rem; }
  .tra-link.ico-20 [class*="flaticon-"]:before, 
  .tra-link.ico-20 [class*="flaticon-"]:after { font-size: 0.785rem; line-height: 0.7rem!important; top: 1px; left: 2px; }

  /* Transparent Link */
  a.tra-link { font-size: 1.125rem; }
  .tra-link.ico-20 [class*="flaticon-"]:before, 
  .tra-link.ico-20 [class*="flaticon-"]:after { font-size: 0.785rem; line-height: 0.7rem!important; top: 1px; left: 2px; }

  /* Button */
  .btn { font-size: 1.125rem; }
  .btn.btn-sm { font-size: 1rem; padding: 0.7rem 1.5rem; }
  .btns-group .btn.ico-30.ico-left, .btns-group .btn.ico-30.ico-right { padding: 0.68165rem 0.5rem 0; }

  /* Buttons Group */
  .btns-group .btn:first-child { margin-left: 8px; margin-right: 8px; margin-bottom: 15px; }
  .btns-group .btn:last-child { margin-left: 8px; margin-right: 8px; }

  /* Button Icon */
  .btn.ico-20 [class*="flaticon-"]:before, 
  .btn.ico-20 [class*="flaticon-"]:after { font-size: 1.225rem; line-height: 0.45rem!important;top: 4px; }

  .btn.ico-30 [class*="flaticon-"]:before, .btn.ico-30 [class*="flaticon-"]:after { font-size: 2.05rem; }

  .btn.ico-30.ico-left span.ico-30-txt { font-size: 1.15rem; top: -10px; margin-right: 0; }
  .btn.ico-30.ico-right span.ico-30-txt { font-size: 1.15rem; top: -10px; margin-left: 0; }
  .btn.ico-left span { margin-right: 3px; }
  .btn.ico-right span { margin-left: 3px; }

  /* Button Text */
  p.btn-txt, .text-center p.btns-group-txt { margin-top: 18px; margin-bottom: 0; }
  .btn-rating { margin: 15px 0 0 0; }
  .btn-rating p.p-sm { font-size: 0.9375rem; }


  /* Section ID */
  .section-id.rounded-id { padding: 8px 22px; font-size: 0.8rem; margin-bottom: 30px; }

  /* Section Title */
  .section-title.mb-60 { margin-bottom: 35px; }
  .section-title.mb-70, .section-title.mb-80 { margin-bottom: 45px; }
  #ludo-rank .section-title.mb-80,
  #web3-curators-network .section-title.mb-80,
  #ludo-rank-dao .section-title.mb-80 {  padding: 0 20px; }

  .section-title h2 { padding: 0 1%; }
  .content-section .col-md-8 .section-title h2 { padding: 0 1.5%; }

  .section-title p.p-md, .section-title p.p-lg { margin-top: 14px; }
  .section-title p.p-xl { font-size: 1.125rem; margin-top: 14px; }
  .section-title p.s-20, .section-title p.s-21 { font-size: 1.1875rem; padding: 0 3%; margin-top: 12px; }  /* 19px */
  .section-title p.p-md, .section-title p.p-lg { padding: 0; }

  .section-title .btns-group.mt-35 { margin-top: 20px; }

  /* Header Social Links */
  .header-socials.ico-20 [class*="flaticon-"]:before, 
  .header-socials.ico-25 [class*="flaticon-"]:after { line-height: 52px!important; } 

  .wsmenu > .wsmenu-list > li > .wsmegamenu ul li.title { margin-top: 12px; } 

  .icons-menu-ico { margin-right: 18px; }
  .icons-menu-txt span { font-size: 1rem; }

  /*------------------------------------------*/
  /*   HERO   
  /*------------------------------------------*/

  .hero-section.blur--purple:after { top: -25%; opacity: .18; }

  .hero-section h2 { margin-bottom: 18px; }
  .hero-section h2.s-48, .hero-section h2.s-50 { margin-bottom: 20px; }
  .hero-section p.p-md, .hero-section p.p-lg, .hero-section p.p-xl { margin-bottom: 25px; }
  .hero-section p.s-21 { font-size: 1.3125rem; margin-bottom: 22px; }
  .hero-section .btn.mt-15, .hero-section .btns-group.mt-15 { margin-top: 8px; }

  /* Hero Advantages */
  .hero-section .text-center .advantages { margin-top: 18px; }
  .hero-section .advantages li { display: block!important; margin-top: 4px; }
  .hero-section .advantages li p { display: block; float: none; }
  .hero-section li.advantages-links-divider { display: none!important; }

  /* HERO LOGO */
  .hero-logo, .hero-app-logo { margin-bottom: 30px; }
  .hero-logo img { max-height: 34px; }

  .hero-square-logo { margin-bottom: 12px; }
  .hero-square-logo img { max-height: 64px; }

  .hero-app-logo img { max-height: 96px; }

  /* hero */
  #hero { margin-bottom: 0; margin-top: 0; }
  .hero-txt h1 { margin-bottom: 15px; }
  .hero-txt p.p-lg { margin-bottom: 20px; }

  /*------------------------------------------*/
  /*  CONTENT
  /*------------------------------------------*/
  /* FEATURES */
  #features-14 .container { max-width: 100%; }
  .fbox-14-wrapper { padding: 70px 30px; margin: 0 -15px; border-radius: 0 0!important; } 

  #features-12.py-100.shape--bg:after, #features-13.py-100.shape--bg:after { height: 68%; top: 32%; }
  #features-12.pt-100.shape--bg:after, #features-13.pt-100.shape--bg:after { height: 64%; top: 32.35%; }

  .row-cols-md-3 .fbox-4, .row-cols-md-2 .fbox-4, .fbox-7, .fbox-9 { padding: 40px 25px 35px; }
  .fbox-5 { padding: 45px 25px 40px; } 
  .row-cols-md-2 .fbox-7 { padding: 40px 55px 35px; }
  .fbox-8.fb-1 { padding: 0 25px 40px; }
  .fbox-8.bg--white-100 {padding: 45px 25px; }
  .fbox-8.fb-3 { padding: 45px 25px 0; }

  .fbox-12, .fbox-13 { padding: 35px 30px; } 
  #fb-12-1, #fb-13-1 { margin-top: 65px; }

  .fbox-14 { padding: 45px 40px; } 
  .fbox-14.fb-1, .fbox-14.fb-2 { margin-bottom: 30px; }

  .fbox-img.h-135 img, .fbox-img.h-140 img, .fbox-img.h-145 img, .fbox-img.h-150 img, .fbox-img.h-155 img, 
  .fbox-img.h-160 img, .fbox-img.h-165 img, .fbox-img.h-170 img, .fbox-img.h-175 img, .fbox-img.h-180 img { max-height: 185px; }

  .fbox-2 .fbox-img.h-135 img, .fbox-2 .fbox-img.h-140 img, .fbox-2 .fbox-img.h-145 img, .fbox-2 .fbox-img.h-150 img, 
  .fbox-2 .fbox-img.h-155 img, .fbox-2 .fbox-img.h-160 img, .fbox-2 .fbox-img.h-165 img, .fbox-2 .fbox-img.h-170 img, 
  .fbox-2 .fbox-img.h-175 img, .fbox-2 .fbox-img.h-180 img { max-height: 180px; }

  .fbox-3 .fbox-img.h-135 img, .fbox-3 .fbox-img.h-140 img, .fbox-3 .fbox-img.h-145 img, .fbox-3 .fbox-img.h-150 img, 
  .fbox-3 .fbox-img.h-155 img, .fbox-3 .fbox-img.h-160 img, .fbox-3 .fbox-img.h-165 img, .fbox-3 .fbox-img.h-170 img, 
  .fbox-3 .fbox-img.h-175 img, .fbox-3 .fbox-img.h-180 img { max-height: 180px; }

  .fbox-4 .fbox-img.h-135 img, .fbox-4.fbox-img.h-140 img, .fbox-4 .fbox-img.h-145 img, .fbox-4 .fbox-img.h-150 img, 
  .fbox-4 .fbox-img.h-155 img, .fbox-4 .fbox-img.h-160 img, .fbox-4 .fbox-img.h-165 img, .fbox-4 .fbox-img.h-170 img, 
  .fbox-4 .fbox-img.h-175 img { max-height: 165px; }

  .fbox-1 .fbox-img, .row-cols-md-2 .fbox-1 .fbox-img, .fbox-4 .fbox-img, .row-cols-md-2 .fbox-4 .fbox-img { margin-bottom: 30px; }

  .fbox-2 .fbox-img.h-135, .fbox-2 .fbox-img.h-140, .fbox-2 .fbox-img.h-145, 
  .fbox-2 .fbox-img.h-150, .fbox-2 .fbox-img.h-155, .fbox-2 .fbox-img.h-160, 
  .fbox-2 .fbox-img.h-165, .fbox-2 .fbox-img.h-170, .fbox-2 .fbox-img.h-175, 
  .fbox-2 .fbox-img.h-180 { padding-top: 40px; margin: 0 12px 50px; }

  .fbox-3 .fbox-img.h-135, .fbox-3 .fbox-img.h-140, .fbox-3 .fbox-img.h-145, 
  .fbox-3 .fbox-img.h-150, .fbox-3 .fbox-img.h-155,  .fbox-3 .fbox-img.h-160, 
  .fbox-3 .fbox-img.h-165, .fbox-3 .fbox-img.h-170, .fbox-3 .fbox-img.h-175, 
  .fbox-3 .fbox-img.h-180 { padding-top: 40px; margin: 0 12px 30px; }

  .fbox-5.fb-1 .fbox-5-img, .fbox-5.fb-2 .fbox-5-img, .fbox-5.fb-3 .fbox-5-img, 
  .fbox-5.fb-4 .fbox-5-img, .fbox-5.fb-5 .fbox-5-img, .fbox-5.fb-6 .fbox-5-img { margin: 0 9% 30px; }

  .fbox-ico, .fbox-ico.ico-rounded, .fbox-ico.ico-square { margin-bottom: 20px; }

  .fbox-wrapper .ico-50 [class*="flaticon-"]:before, 
  .fbox-wrapper .ico-50 [class*="flaticon-"]:after,
  .fbox-wrapper .ico-55 [class*="flaticon-"]:before, 
  .fbox-wrapper .ico-55 [class*="flaticon-"]:after,
  .fbox-wrapper .ico-60 [class*="flaticon-"]:before, 
  .fbox-wrapper .ico-60 [class*="flaticon-"]:after,
  .fbox-10 .fbox-title.ico-35 [class*="flaticon-"]:before, 
  .fbox-10 .fbox-title.ico-35 [class*="flaticon-"]:after{ font-size: 3.65rem; }

  .fbox-10 .fbox-title { margin: 0 auto; }
  .fbox-10 .fbox-title span { float: none; line-height: 1!important; margin-right: 0; }
  .fbox-10 .fbox-title h6 { display: block; line-height: 1!important; margin-top: 15px; margin-bottom: 15px; }

  .features-section h6.s-18, .features-section h6.s-20, .features-section h6.s-22 { font-size: 1.4375rem; }  /* 23px */
  .features-section h6, .fbox-txt h5, .rows-2 .fbox-11 h6, .fbox-wrapper .row-cols-md-2 h6 { margin-bottom: 15px; }
  .fbox-14 h5 { padding: 0 10%; margin-bottom: 12px; }

  .row-cols-md-3 .fbox-1 p, .row-cols-md-3 .fbox-2 p, .row-cols-md-3 .fbox-3 p, .row-cols-md-3 .fbox-6 p, 
  .row-cols-md-2 .fbox-1 p, .row-cols-md-2 .fbox-2 p, .row-cols-md-2 .fbox-3 p, .fbox-5 .fbox-txt p, .fbox-10 p { padding: 0 8%; } 

  .row-cols-md-3 .fbox-4 p, .row-cols-md-2 .fbox-4 p { padding: 0 4%; } 
  .fbox-5 .fbox-txt p, .rows-3 .fbox-11 p { padding: 0 2%; } 
  .row-cols-md-2 .fbox-6 p { padding: 0 10%; } 
  .row-cols-lg-3 .fbox-10 .fbox-txt p { padding: 0 2%; }

  /* CONTENT */
  .ct-10 .section-overlay { margin-bottom: 40px; }
  .ct-top { margin-bottom: 70px; }

  .ct-13 .txt-block, .ct-13 .img-block { margin-bottom: 35px; }
  .ct-13 .txt-block.ml-block, .ct-13 .img-block.ml-block { margin-bottom: 0; }

  /* Content Box Wrapper */
  .content-section.ws-wrapper { margin: 0 -15px; }
  .bc-1-wrapper .section-overlay, .bc-4-wrapper .section-overlay { padding: 70px 32px; }
  #ludo-rank-dao .section-overlay { padding: 15px 32px 40px; }
  #web3-curators-network .bc-1-wrapper .section-overlay { padding: 40px 32px 15px; }
  .bc-2-wrapper .section-overlay, .bc-3-wrapper .section-overlay { padding: 70px 14px; }
  .bc-5-wrapper .section-overlay { padding: 70px 14px 0; }
  .cbox-6-wrapper { padding: 30px 20px; }
  .bc-6-wrapper .section-overlay { padding: 70px 20px; }
  .bc-6 { padding: 25px 17px 35px; }

  .bc-1-wrapper, .bc-2-wrapper, .bc-3-wrapper, .bc-4-wrapper, .bc-5-wrapper, .bc-6-wrapper { border-radius: 0 0!important; } 

  .bc-5-wrapper .section-title p { padding: 0 5%; } 

  .bc-2-txt h2, .bc-3-txt h2, .bc-4-txt h2, .txt-block h3, .txt-block h2 { margin-bottom: 15px; }
  .cbox-2-txt h5, .cbox-3-txt h5, .bc-6-txt h5 { margin-bottom: 12px; }

  .txt-block p.p-sm, .txt-block p { margin-bottom: 0.875rem; }
  .txt-block p.s-20, .txt-block p.s-21 { font-size: 1.1875rem; }
  .cbox-5-fea p, .txt-block p.video-txt-sm, .txt-block p.btn-txt, .txt-block p.btns-group-txt,
  .txt-block p.compatibility { margin-bottom: 0; }
  .txt-block p.video-txt-lg {margin-bottom: 6px; }

  .txt-block .btn { margin-top: 18px; } 
  .txt-block .btns-group .btn.btn--transparent { margin-top: 0; } 
  .txt-block .stores-badge.mt-15, .ws-wrapper .stores-badge.mt-15 { margin-top: 8px; }
  .bc-6-link.mt-15 { margin-top: 12px; }
  .txt-block-tra-link.mt-25 { margin-top: 16px; }

  /* Content Box */
  .right-column .cbox-5 { margin-bottom: 40px; }
  .cbox-5-content { padding: 35px; margin: 0 ; }
  .cbox-5-content .divider { margin-top: 22px; margin-bottom: 22px; }

  .txt-block .cbox-5-title h2 { font-size: 3rem; margin-bottom: 10px; }
  .cbox-5-title .statistic-number sup { font-size: 2.5rem; top: -5px; right: 6px; }

  .cbox-5-fea { padding: 15px 20px; margin-top: 20px; }
  .txt-block .cbox-5-fea h4 { font-size: 1.625rem; }
  .cbox-5-txt .btn { margin-top: 14px; }

  #cb-6-1, #cb-6-2, #cb-6-3 { margin-bottom: 24px; }

  /* Content Box Icon */
  .cbox-1.ico-10 span { right: 6px; }
  .cbox-1.ico-15 span { top: 0.5px; right: 6px; }
  .cbox-1.ico-15 [class*="flaticon-"]:before, .cbox-1.ico-15 [class*="flaticon-"]:after { font-size: 0.7rem; }

  .cbox-2 .ico-wrap { margin-right: 1rem; }
  .cbox-2-ico { width: 2.3rem; height: 2.3rem; font-size: 1.15rem; line-height: 2.05rem; }
  .ct-03 .cbox-2-ico { width: 2rem; height: 2rem; font-size: 1.0625rem; line-height: 1.8rem; }
  .cbox-2-line { height: calc(100% - 74px); }

  .cbox-3 { display: block; text-align: center; }
  .cbox-3 .ico-wrap { margin-right: 0; }
  .cbox-3 .cbox-3-ico { top: 0; margin-bottom: 10px; }

  .cbox-2-txt, .ct-03 .cbox-2-txt, .cbox-3-txt { margin-bottom: 25px; }
  .ct-03 .cbox-2-txt { margin-bottom: 20px; }

  .cbox-4 { text-align: center; }
  .box-title h6, .box-title h5 { display: block; line-height: 1!important; margin-bottom: 15px; }
  .cbox-4-txt { margin-bottom: 30px; }
  .img-block-txt .box-title h6, 
  .img-block-txt .box-title h5 { display: inline-block; line-height: 2.35rem!important; margin-bottom: 20px; }
  .box-title span { display: block; position: relative; top:0; margin-right: 0; margin-bottom: 10px; }
  .img-block-txt .box-title span { display: inline-block; top: 10px; margin-right: 10px; margin-bottom: 0; }
  .box-title span[class*="flaticon-"]:before, .box-title span[class*="flaticon-"]:after { font-size: 3.25rem; }

  .img-block-txt .box-title span[class*="flaticon-"]:before, 
  .img-block-txt .box-title span[class*="flaticon-"]:after { font-size: 2.35rem; }

  /* Content Box Typography */
  .cbox-1-txt p { margin-bottom: 5px; }
  .cbox-6 h6 { margin-bottom: 12px; }

  /* Accordion */
  .txt-block .accordion-1 .accordion-item { padding: 18px 25px; }
  .txt-block .accordion-2 .accordion-item { padding: 10px 0 20px; }

  .txt-block .accordion-1 .accordion-item .accordion-thumb:before { font-size: 0.95rem; top: 0; }
  .txt-block .accordion-2 .accordion-item .accordion-thumb:after,
  .txt-block .accordion-2 .accordion-item.is-active .accordion-thumb:after { font-size: 0.95rem; top: 8px; }

  .txt-block .accordion-1 .accordion-item.is-active { padding: 30px 25px 14px 25px; }
  .txt-block .accordion-2 .accordion-item.is-active { padding: 15px 0 20px; }

  .txt-block .accordion-1 .accordion-panel { padding: 0; }

  .txt-block .accordion-2 .accordion-thumb h5 { padding-left: 12px; padding-right: 0; }

  .accordion-2 .accordion-thumb span[class*="flaticon-"]:before,
  .accordion-2 .accordion-thumb span[class*="flaticon-"]:after { font-size: 1.75rem; top: 6px; }

  /* Tabs */
  .tabs-1 li { font-size: 1.125rem; } 

  /* Advantages */
  .advantages, .text-center .advantages { margin-top: 15px; }
  .img-block-btn .advantages li { display: block!important; margin-top: 4px; }
  .img-block-btn .advantages li p { display: block; float: none; }
  .img-block-btn li.advantages-links-divider { display: none!important; }

  /* Compatibility */
  .compatibility { margin-top: 5px; }

  /* Tools List / Payments Icons */
  .tools-list p, .payment-methods p { margin-bottom: 12px; }
  .tools-list span { padding-right: 1px; }
  .payment-icons img { max-height: 30px; }

  .tools-list.ico-35 [class*="flaticon-"]:before, 
  .tools-list.ico-35 [class*="flaticon-"]:after {  font-size: 2.125rem; line-height: 2.125rem!important; margin-right: 4px; }

  /* Content Box Shapes */
  .cbox-5-shape-1, .cbox-5-shape-2 { display: none; }

  /* Content Images */
  .img-block.img-block-bkg img { padding: 40px 25px; }
  .ct-10 .img-block { margin-bottom: -40px; }

  .ct-01 .img-block, .ct-02 .img-block, .ct-03 .img-block.right-column, .ct-03 .img-block.left-column { margin: 0 2% 35px; }
  .ct-01 .img-block.j-img, .ct-02 .img-block.j-img { margin-left: 0%; margin-right: 0; }
  .ct-06 .img-block, .ct-07 .img-block { margin-top: -80px; margin-bottom: 40px; }
  .bc-5-img.bc-5-tablet.img-block-hidden {
    .img-tablet {
      max-width: 100%;
    }
  }
  .bc-6-img { padding: 30px 15px 0; margin-bottom: 25px; }

  .video-btn { top: 50%; }
  .bc-5-img.bc-5-tablet.img-block-hidden .video-btn { top: calc(50% - 30px); }
  .img-block-txt, .img-block-btn { margin-top: 35px; }
  .ct-11 .img-block, .ct-12 .img-block { margin-bottom: 40px; padding: 40px 8%; }

  /* STATISTIC */
  .sb-rounded .statistic-block { text-align: center; padding: 25px; }
  #statistic-5 .statistic-digit { padding-bottom: 18px; margin: 0 12% 18px; }

  #sb-2-1, #sb-2-2, #sb-2-3, #sb-2-4 { width: 50%; }
  #sb-3-3, #sb-4-3 { margin-bottom: 30px; }
  #sb-2-3, #sb-2-4 { padding-right: 12px; }
  #sb-5-1, #sb-5-2 { margin-bottom: 35px; }

  .statistic-1-wrapper .statistic-block-digit { margin-bottom: 12px; }

  h2.statistic-number { font-size: 3.5rem; line-height: 1; letter-spacing: -0.5px; }
  .txt-block-stat h2.statistic-number { font-size: 2.125rem; }
  #statistic-1 h2.statistic-number { letter-spacing: -0.5px; }
  #sb-2-1 h2.statistic-number, #sb-2-2 h2.statistic-number { margin-bottom: 12px; }
  #statistic-5 .statistic-digit h2 { font-size: 2.125rem; letter-spacing: -0.5px; }

  #sb-2-3 .statistic-block p.p-sm, #sb-2-4 .statistic-block p.p-sm { margin-top: 12px; }
  .txt-block-stat .statistic-block p { font-size: 0.95rem; }
  #statistic-5 .statistic-txt p { padding: 0 8%; }

  /* PROJECTS */
  #pt-1-1, #pt-1-2, #pt-1-3, .inner-page-hero #pt-1-3, .inner-page-hero #pt-1-4, .inner-page-hero #pt-1-5 { margin-bottom: 40px; }
  .project-details h5 { margin-bottom: 25px; }
  .more-projects { margin-top: 45px; }

  /* PROJECT DETAILS */
  .single-project .project-title { margin-bottom: 40px; }
  .project-title h2 { padding-right: 0; padding-bottom: 25px; margin-bottom: 25px; }
  .project-data p { margin-bottom: 5px; }

  .project-priview-img.mb-50 { margin-bottom: 35px; }
  .project-inner-img .top-img { margin-bottom: 30px; }
  .project-inner-img.mt-50 { margin-top: 35px; }
  .project-txt h5.mt-35.mb-35 { margin-top: 20px; margin-bottom: 20px; }
  .project-txt h5.mt-50.mb-35 { margin-top: 35px; margin-bottom: 20px; }

  .project-description .more-projects { margin-top: 45px; }
  .project-description .more-projects span { top: 1px; left: 4px; }
  .project-description .more-projects.ico-25 [class*="flaticon-"]:before, 
  .project-description .more-projects.ico-25 [class*="flaticon-"]:after { font-size: 1.125rem; } 

  /* RATING */
  #rb-2-1, #rb-2-2 { margin-bottom: 25px; }

  .rating-title h5 { font-size: 1.125rem; }
  .rbox-1-img { margin-bottom: 15px; }
  .rbox-1-img img { max-height: 31px; }
  .rbox-2-txt img { max-height: 31px; }
  p.rbox-2-txt { line-height: 31px; margin-top: 8px; }

  .rbox-1 .star-rating { padding: 5px 16px; }
  .rbox-1 .star-rating { font-size: 0.9rem; line-height: 1; }

  .rbox-1 .star-rating span { top: 2px; }
  .rbox-1 .ico-10 [class*="flaticon-"]:before, .rbox-1 .ico-10 [class*="flaticon-"]:after { font-size: 0.9rem; } 
  .rbox-1 .ico-15 [class*="flaticon-"]:before, .rbox-1 .ico-15 [class*="flaticon-"]:after { font-size: 1rem; } 
  .rbox-2 .ico-20 [class*="flaticon-"]:before, .rbox-2 .ico-20 [class*="flaticon-"]:after { font-size: 1.35rem; } 
  .rbox-2 .ico-25 [class*="flaticon-"]:before, .rbox-2 .ico-25 [class*="flaticon-"]:after { font-size: 1.35rem; } 

  /* TESTIMONIALS */
  #reviews-4 .txt-block, #reviews-5 .txt-block { text-align: center; }
  #reviews-4 .txt-block h2, #reviews-5 .txt-block h2 { font-size: 1.90625rem; padding: 0 6%; }  /* 30.5px */

  .review-1 { padding: 35px 20px 30px; margin: 0 6px 20px; }
  .review-2, .review-3, .review-4, .review-5 { padding: 35px 20px 30px; }

  .review-txt { margin-top: -40px; }
  .review-txt h6 { margin-bottom: 10px; }
  .review-3 .review-txt p { font-size: 1.0625rem; }  

  .review-1 .author-data, .review-2 .author-data, 
  .review-3 .author-data, .review-4 .author-data, .review-5 .author-data { margin-top: 20px; }

  .review-avatar img, .owl-item .review-avatar img { width: 52px; height: 52px; }
  .review-2 .review-avatar img, .review-3 .review-avatar img, .review-5 .review-avatar img { width: 52px; height: 52px; }

  .review-author { padding: 0 0 0 12px; }
  .review-1 .review-author, .review-2 .review-author, .review-3 .review-author, .review-5 .review-author { margin-top: 3px; }

  .review-author h6 { margin-bottom: 8px; }
  .review-1 .review-author h6, .review-2 .review-author h6, .review-3 .review-author h6, 
  .review-4 .review-author h6, .review-5 .review-author h6 { font-size: 1.2rem; }

  /* BRANDS */
  #brands-3:after { height: 75%; }

  .brands-title { padding: 0 4%; } 
  .brands-title.mb-35, .brands-title.mb-40 { margin-bottom: 25px; }
  .brands-title.mb-50 { margin-bottom: 30px; }

  .brands-title h4, .brands-title h3, .brands-title h2 { margin-bottom: 8px; }
  .brands-title p.p-md, .brands-title p.p-lg { font-size: 1.0625rem; }

  .brands-3-wrapper, .brands-3-wrapper.brands-3-static { padding: 25px 15px; }

  #brand-2-1, #brand-2-2, #brand-2-3, #brand-2-4, #brand-3-1, #brand-3-2, #brand-4-1, #brand-4-2, #brand-4-3, 
  #brand-4-4, #brand-4-5, #brand-4-6, #brand-4-7, #brand-4-8, #brands-4 .brand-logo.mb-40 { margin-bottom: 30px; }
  #brand-3-5 { display: none; }

  #brands-1 .brands-carousel-6 .brand-logo, #brands-1 .brands-carousel-5 .brand-logo { padding: 0 25px; }   
  #brands-1 .justify-content-center .brand-logo, #brands-4 .brand-logo { padding: 0 25px; }   
  #brands-2 .brand-logo, #brands-3 .brand-logo, #brands-3 .brands-3-static .brand-logo { padding: 0 20px; } 

  .brands-section .more-btn { margin-top: 30px; }

  /* INTEGRATIONS */
  #integrations-2 .container { max-width: 100%; }
  .integrations-2-wrapper { padding: 70px 20px; margin: 0 -15px; border-radius: 0 0!important; }
  .in_tool_logo.ico-65 img { width: 55px; height: 55px; }
  .in_tool h6 { font-size: 1.125rem; }

  .integrations-1-wrapper .in_tool { padding: 20px 25px; }
  .integrations-1-wrapper .in_tool-txt h6 { font-size: 1.4rem; line-height: 1; margin-top: 4px; }
  .integrations-1-wrapper .in_tool-txt p { font-size: 1.0625rem; margin-top: 6px; }

  .integrations-section .more-btn.mt-60, .integrations-section .more-btn.mt-70 { margin-top: 30px; }

  /* FOOTER */
  .footer { padding-bottom: 30px; padding-top: 80px;}
  .footer hr { margin-top: 5px; margin-bottom: 30px; }
  .bottom-footer p.p-sm { margin-bottom: 0; }
  .footer .col-sm-4, .footer .col-sm-6, .footer .col-sm-8 { width: 50%!important; float: left; }
  .footer h6 { font-size: 1.21875rem; margin-bottom: 20px; } /* 19.5px */

}




/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */

@media (min-width: 390px) and (max-width: 413.98px) {

  .logo {
    img {
      width: 116px;
    }
  }
  .blockchains-wrapper {
    flex-direction: column;
    align-items: center;
    gap: 17px;
    .blockchains {
      justify-content: center;
      gap: 10px 18px;
      max-width: 230px;
      .center-row {
        gap: 10px 18px;
      }
      .blockChainsWithFooter_blockChains_blockchains--img {
        width: 50px;
        height: 50px;
      }
    }
  }

  /*------------------------------------------*/
  /*  BASE
  /*------------------------------------------*/
  #ludo-rank .section-title.mb-80, #web3-curators-network .section-title.mb-80, #ludo-rank-dao .section-title.mb-80{  padding: 0 20px; }
  #stlChanger { display: none; }
  .division { padding-left: 16px; padding-right: 16px; }
  .features { padding-left: 10px; padding-right: 10px; }
  .bg--fixed { width: auto; }
  .bg--scroll { width: auto; background-attachment: scroll!important; }

  /* Backgroud Shape */
  .shape--01:after { height: 90%; top: 6.5%; }
  .shape--02:after { width: 140%; height: 90%; top: 6.5%; left: -20%; }
  .shape--03:after { width: 140%; height: 129%; top: -70px; left: -20%; }
  .shape--04:after { height: 94%; top: 0; }
  .shape--06:after { height: 92%; }
  .shape--07:after { height: 80%; top: 15%; }

  ol.digit-list p { margin-bottom: 5px; }

  .font--jakarta h1, .font--jakarta h2 { line-height: 1.35; }
  .font--jakarta h3, .font--jakarta h4, .font--jakarta h5, .font--jakarta h6 { line-height: 1.4; }

  /* Font Size */
  .s-15, .s-16 { font-size: 1.0625rem; }                             /* 17px */
  .s-17 { font-size: 1.125rem; }                                     /* 18px */
  .s-18, .s-19, .s-20, .s-21, .s-22, .s-23 { font-size: 1.3125rem; } /* 21px */
  .s-24, .s-26, { font-size: 1.4375rem; }                      /* 23px */
  .s-30, .s-32, .s-34 { font-size: 1.5625rem; }                      /* 25px */
  .s-36, .s-38, .s-40 { font-size: 1.6875rem; }                      /* 27px */ 
  .s-42, .s-43, .s-44, .s-45, .s-46, .s-47 { font-size: 1.8125rem; } /* 29px */
  .s-48 { font-size: 1.9375rem; }                                    /* 31px */
  .s-50, .s-52, .s-54, .s-56, .s-58, .s-60 { font-size: 2rem; }      /* 32px */ 
  .s-62 { font-size: 2.0625rem; }                                    /* 33px */
  .s-64, .s-66 { font-size: 2.125rem; }                              /* 34px */
  .s-68 { font-size: 2.25rem; }                                      /* 36px */

  /* Paragraphs */
  p.p-sm { font-size: 1rem; } 
  p { font-size: 1.0625rem; } 
  p.p-md, p.p-lg { font-size: 1.125rem; }

  /* Transparent Link */
  a.tra-link { font-size: 1.125rem; }
  .tra-link.ico-20 [class*="flaticon-"]:before, 
  .tra-link.ico-20 [class*="flaticon-"]:after { font-size: 0.785rem; line-height: 0.7rem!important; top: 1px; left: 2px; }

  /* Transparent Link */
  a.tra-link { font-size: 1.0625rem; }
  .tra-link.ico-20 [class*="flaticon-"]:before, 
  .tra-link.ico-20 [class*="flaticon-"]:after { font-size: 0.785rem; line-height: 0.7rem!important; top: 2px; left: 2px; }

  /* Button */
  .btn { font-size: 1.0625rem; }
  .btn.btn-sm { font-size: 1rem; padding: 0.7rem 1.5rem; }
  .btns-group .btn.ico-30.ico-left, .btns-group .btn.ico-30.ico-right { padding: 0.61915rem 0.5rem 0; }

  /* Buttons Group */
  .btns-group .btn:first-child { margin-left: 8px; margin-right: 8px; margin-bottom: 15px; }
  .btns-group .btn:last-child { margin-left: 8px; margin-right: 8px; }

  /* Button Icon */
  .btn.ico-20 [class*="flaticon-"]:before, 
  .btn.ico-20 [class*="flaticon-"]:after { font-size: 1.225rem; line-height: 0.45rem!important;top: 4px; }

  .btn.ico-30 [class*="flaticon-"]:before, .btn.ico-30 [class*="flaticon-"]:after { font-size: 2.05rem; }

  .btn.ico-30.ico-left span.ico-30-txt { font-size: 1.15rem; top: -10px; margin-right: 0; }
  .btn.ico-30.ico-right span.ico-30-txt { font-size: 1.15rem; top: -10px; margin-left: 0; }
  .btn.ico-left span { margin-right: 3px; }
  .btn.ico-right span { margin-left: 3px; }

  /* Button Text */
  p.btn-txt, .text-center p.btns-group-txt { margin-top: 18px; margin-bottom: 0; }
  .btn-rating { margin: 15px 0 0 0; }
  .btn-rating p.p-sm { font-size: 0.9375rem; }

  /* OS Button */
  .os-btn { width: 280px; min-width: 280px; padding: 14px 15px 10px; }
  .os-btn-ico { margin-right: 14px; }
  .os-btn-txt p { margin-top: 4px; }

  .btn-os.mb-15 { margin-bottom: 15px; }
  .btn-os.mr-15 { margin-right: 0; margin-bottom: 15px; }

  /* Section ID */
  .section-id.rounded-id { padding: 8px 22px; font-size: 0.8rem; margin-bottom: 30px; }

  /* Section Title */
  .section-title.mb-60 { margin-bottom: 35px; }
  .section-title.mb-70, .section-title.mb-80 { margin-bottom: 40px; }

  .section-title h2 { padding: 0 1%; }

  .section-title p.p-md, .section-title p.p-lg { margin-top: 14px; }
  .section-title p.p-xl { font-size: 1.125rem; margin-top: 14px; }
  .section-title p.s-20, .section-title p.s-21 { font-size: 1.1875rem; padding: 0 2%; margin-top: 12px; }  /* 19px */
  .section-title p.p-md, .section-title p.p-lg { padding: 0; }

  .section-title .btns-group.mt-35 { margin-top: 20px; }
  .section-title .btns-group .btn { margin-left: 10px; margin-right: 10px; }  
  .section-title .btns-group .btn:first-child { margin-bottom: 15px; }


  /*------------------------------------------*/
  /*   HERO   
  /*------------------------------------------*/

  .hero-section.blur--purple:after { top: -25%; opacity: .18; }

  .hero-section h2 { margin-bottom: 15px; }
  .hero-section h2.s-48, .hero-section h2.s-50 { margin-bottom: 20px; }
  .hero-section p.p-md, .hero-section p.p-lg, .hero-section p.p-xl { margin-bottom: 25px; }
  .hero-section p.s-21 { font-size: 1.3125rem; margin-bottom: 22px; }
  .hero-section .btn.mt-15, .hero-section .btns-group.mt-15 { margin-top: 8px; }


  /* HERO LOGO */
  .hero-logo { margin-bottom: 28px; }
  .hero-logo img { max-height: 32px; }

  .hero-square-logo { margin-bottom: 10px; }
  .hero-square-logo img { max-height: 64px; }

  /* HERO APP LOGO */
  .hero-app-logo { margin-bottom: 25px; }
  .hero-app-logo img { max-height: 90px; }
  #hero-25 .hero-app-logo img { max-height: 90px; }

   /* hero */
  #hero { margin-bottom: 0px; margin-top: 0;}
  .hero-txt h1 { margin-bottom: 15px; }
  .hero-txt p.p-lg { margin-bottom: 20px; }

  /*------------------------------------------*/
  /*  CONTENT
  /*------------------------------------------*/

  /* FEATURES */
  #features-14 .container { max-width: 100%; }
  .fbox-14-wrapper { padding: 70px 30px; margin: 0 -15px; border-radius: 0 0!important; } 

  #features-12.py-100.shape--bg:after, #features-13.py-100.shape--bg:after { height: 67%; top: 33%; }
  #features-12.pt-100.shape--bg:after, #features-13.pt-100.shape--bg:after { height: 64%; top: 32.6%; }

  .row-cols-md-3 .fbox-4, .row-cols-md-2 .fbox-4, .fbox-7, .fbox-9 { padding: 40px 25px 35px; }
  .fbox-5 { padding: 45px 25px 40px; } 
  .row-cols-md-2 .fbox-7 { padding: 40px 35px 35px; }
  .fbox-8.fb-1 { padding: 0 25px 40px; }
  .fbox-8.bg--white-100 { padding: 45px 25px; }
  .fbox-8.fb-3 { padding: 45px 25px 0; }
  .fbox-12, .fbox-13 { padding: 32px 25px; } 
  #fb-12-1, #fb-13-1 { margin-top: 60px; }

  .fbox-14 { padding: 40px 30px; } 
  .fbox-14.fb-1, .fbox-14.fb-2 { margin-bottom: 30px; }

  .fbox-img.h-135 img, .fbox-img.h-140 img, .fbox-img.h-145 img, .fbox-img.h-150 img, .fbox-img.h-155 img, 
  .fbox-img.h-160 img, .fbox-img.h-165 img, .fbox-img.h-170 img, .fbox-img.h-175 img, .fbox-img.h-180 img { max-height: 185px; }

  .fbox-2 .fbox-img.h-135 img, .fbox-2 .fbox-img.h-140 img, .fbox-2 .fbox-img.h-145 img, .fbox-2 .fbox-img.h-150 img, 
  .fbox-2 .fbox-img.h-155 img, .fbox-2 .fbox-img.h-160 img, .fbox-2 .fbox-img.h-165 img, .fbox-2 .fbox-img.h-170 img, 
  .fbox-2 .fbox-img.h-175 img, .fbox-2 .fbox-img.h-180 img { max-height: 180px; }

  .fbox-3 .fbox-img.h-135 img, .fbox-3 .fbox-img.h-140 img, .fbox-3 .fbox-img.h-145 img, .fbox-3 .fbox-img.h-150 img, 
  .fbox-3 .fbox-img.h-155 img, .fbox-3 .fbox-img.h-160 img, .fbox-3 .fbox-img.h-165 img, .fbox-3 .fbox-img.h-170 img, 
  .fbox-3 .fbox-img.h-175 img, .fbox-3 .fbox-img.h-180 img { max-height: 180px; }

  .fbox-4 .fbox-img.h-135 img, .fbox-4.fbox-img.h-140 img, .fbox-4 .fbox-img.h-145 img, .fbox-4 .fbox-img.h-150 img, 
  .fbox-4 .fbox-img.h-155 img, .fbox-4 .fbox-img.h-160 img, .fbox-4 .fbox-img.h-165 img, .fbox-4 .fbox-img.h-170 img, 
  .fbox-4 .fbox-img.h-175 img { max-height: 165px; }

  .fbox-1 .fbox-img, .row-cols-md-2 .fbox-1 .fbox-img, .fbox-4 .fbox-img, .row-cols-md-2 .fbox-4 .fbox-img { margin-bottom: 30px; }

  .fbox-2 .fbox-img.h-135, .fbox-2 .fbox-img.h-140, .fbox-2 .fbox-img.h-145, 
  .fbox-2 .fbox-img.h-150, .fbox-2 .fbox-img.h-155, .fbox-2 .fbox-img.h-160, 
  .fbox-2 .fbox-img.h-165, .fbox-2 .fbox-img.h-170, .fbox-2 .fbox-img.h-175, 
  .fbox-2 .fbox-img.h-180 { padding-top: 40px; margin: 0 12px 50px; }

  .fbox-3 .fbox-img.h-135, .fbox-3 .fbox-img.h-140, .fbox-3 .fbox-img.h-145, 
  .fbox-3 .fbox-img.h-150, .fbox-3 .fbox-img.h-155, .fbox-3 .fbox-img.h-160, 
  .fbox-3 .fbox-img.h-165, .fbox-3 .fbox-img.h-170, .fbox-3 .fbox-img.h-175, 
  .fbox-3 .fbox-img.h-180 { padding-top: 40px; margin: 0 12px 30px; }

  .fbox-5.fb-1 .fbox-5-img, .fbox-5.fb-2 .fbox-5-img, .fbox-5.fb-3 .fbox-5-img, 
  .fbox-5.fb-4 .fbox-5-img, .fbox-5.fb-5 .fbox-5-img, .fbox-5.fb-6 .fbox-5-img { margin: 0 9% 30px; }

  .fbox-ico, .fbox-ico.ico-rounded, .fbox-ico.ico-square { margin-bottom: 20px; }

  .fbox-wrapper .ico-50 [class*="flaticon-"]:before, 
  .fbox-wrapper .ico-50 [class*="flaticon-"]:after,
  .fbox-wrapper .ico-55 [class*="flaticon-"]:before, 
  .fbox-wrapper .ico-55 [class*="flaticon-"]:after,
  .fbox-wrapper .ico-60 [class*="flaticon-"]:before, 
  .fbox-wrapper .ico-60 [class*="flaticon-"]:after,
  .fbox-10 .fbox-title.ico-35 [class*="flaticon-"]:before, 
  .fbox-10 .fbox-title.ico-35 [class*="flaticon-"]:after{ font-size: 3.65rem; }

  .fbox-10 .fbox-title { margin: 0 auto; }
  .fbox-10 .fbox-title span { float: none; line-height: 1!important; margin-right: 0; }
  .fbox-10 .fbox-title h6 { display: block; line-height: 1!important; margin-top: 15px; margin-bottom: 15px; }

  .features-section h6.s-18, .features-section h6.s-20, .features-section h6.s-22 { font-size: 1.4375rem; }  /* 23px */
  .features-section h6, .fbox-txt h5, .rows-2 .fbox-11 h6, .fbox-wrapper .row-cols-md-2 h6 { margin-bottom: 15px; }

  .fbox-14 h5 { padding: 0 10%; margin-bottom: 12px; }

  .row-cols-md-3 .fbox-1 p, .row-cols-md-3 .fbox-2 p, .row-cols-md-3 .fbox-3 p, .row-cols-md-3 .fbox-6 p, .row-cols-md-2 .fbox-1 p,
  .row-cols-md-2 .fbox-2 p, .row-cols-md-2 .fbox-3 p, .row-cols-md-2 .fbox-6 p, .fbox-5 .fbox-txt p, .fbox-10 p { padding: 0 8%; } 
  .row-cols-md-3 .fbox-4 p, .row-cols-md-2 .fbox-4 p { padding: 0 4%; } 
  .fbox-5 .fbox-txt p, .rows-3 .fbox-11 p { padding: 0 2%; } 
  .row-cols-lg-3 .fbox-10 .fbox-txt p { padding: 0; }

  /* CONTENT */
  .ct-10 .section-overlay { margin-bottom: 40px; }
  .ct-top { margin-bottom: 70px; }

  .ct-13 .txt-block, .ct-13 .img-block { margin-bottom: 35px; }
  .ct-13 .txt-block.ml-block, .ct-13 .img-block.ml-block { margin-bottom: 0; }

  /* Content Box Wrapper */
  .content-section.ws-wrapper { margin: 0 -15px; }
  .bc-1-wrapper .section-overlay, .bc-2-wrapper .section-overlay, .bc-3-wrapper .section-overlay { padding: 70px 32px; }
  #ludo-rank-dao .section-overlay { padding: 15px 32px 40px; }
  #web3-curators-network .bc-1-wrapper .section-overlay { padding: 40px 32px 15px; }

  .bc-4-wrapper .section-overlay { padding: 70px 20px; }
  .bc-5-wrapper .section-overlay { padding: 40px 17px 0; }
  .cbox-6-wrapper { padding: 30px 17px; }
  .bc-6-wrapper .section-overlay { padding: 70px 22px; }
  .bc-6 { padding: 20px 15px 30px; }

  .bc-1-wrapper, .bc-2-wrapper, .bc-3-wrapper, .bc-4-wrapper, .bc-5-wrapper, .bc-6-wrapper { border-radius: 0 0!important; }  

  .bc-5-wrapper .section-title p { padding: 0 3%; }

  .txt-box { margin-bottom: 15px; } 

  .bc-2-txt h2, .bc-3-txt h2, .bc-4-txt h2, .txt-block h3, .txt-block h2 { margin-bottom: 14px; }
  .cbox-2-txt h5, .cbox-3-txt h5, .bc-6-txt h5 { margin-bottom: 12px; }

  .txt-block p.p-sm, .txt-block p { margin-bottom: 0.875rem; }
  .txt-block p.s-20, .txt-block p.s-21 { font-size: 1.1875rem; }
  .cbox-5-fea p, .txt-block p.video-txt-sm, .txt-block p.btn-txt, .txt-block p.btns-group-txt,
  .txt-block p.compatibility { margin-bottom: 0; }
  .txt-block p.video-txt-lg {margin-bottom: 6px; }

  .txt-block .btn { margin-top: 18px; }
  .txt-block .btns-group .btn.btn--transparent { margin-top: 0; } 
  .txt-block .stores-badge.mt-15, .ws-wrapper .stores-badge.mt-15 { margin-top: 8px; }
  .bc-6-link.mt-15 { margin-top: 12px; }
  .txt-block-tra-link.mt-25 { margin-top: 16px; }

  /* Content Box */
  .right-column .cbox-5 { margin-bottom: 40px; }
  .cbox-5-content { padding: 35px; margin: 0 ; }
  .cbox-5-content .divider { margin-top: 22px; margin-bottom: 22px; }

  .txt-block .cbox-5-title h2 { font-size: 2.65rem; margin-bottom: 10px; }
  .cbox-5-title .statistic-number sup { font-size: 2.15rem; top: -5px; right: 6px; }

  .cbox-5-fea { padding: 15px 20px; margin-top: 20px; }
  .txt-block .cbox-5-fea h4 { font-size: 1.625rem; }
  .cbox-5-txt .btn { margin-top: 14px; }

  #cb-6-1, #cb-6-2, #cb-6-3 { margin-bottom: 24px; }

  /* Content Box Icon */
  .cbox-1.ico-10 span { right: 6px; }
  .cbox-1.ico-15 span { top: 0.5px; right: 6px; }
  .cbox-1.ico-15 [class*="flaticon-"]:before, .cbox-1.ico-15 [class*="flaticon-"]:after { font-size: 0.7rem; }

  .cbox-2 .ico-wrap { margin-right: 1rem; }
  .cbox-2-ico { width: 2.3rem; height: 2.3rem; font-size: 1.15rem; line-height: 2.05rem; }
  .ct-03 .cbox-2-ico { width: 1.6rem; height: 1.6rem; font-size: 1rem; line-height: 1.4rem; }
  .cbox-2-line { height: calc(100% - 74px); }

  .cbox-3 { display: block; text-align: center; }
  .cbox-3 .ico-wrap { margin-right: 0; }
  .cbox-3 .cbox-3-ico { top: 0; margin-bottom: 10px; }

  .cbox-2-txt, .cbox-3-txt { margin-bottom: 25px; }
  .ct-03 .cbox-2-txt { margin-bottom: 20px; }

  .cbox-4 { text-align: center; }
  .box-title h6, .box-title h5 { display: block; line-height: 1!important; margin-bottom: 15px; }
  .cbox-4-txt { margin-bottom: 30px; }
  .img-block-txt .box-title h6, 
  .img-block-txt .box-title h5 { display: inline-block; line-height: 2.35rem!important; margin-bottom: 20px; }
  .box-title span { display: block; position: relative; top:0; margin-right: 0; margin-bottom: 10px; }
  .img-block-txt .box-title span { display: inline-block; top: 10px; margin-right: 10px; margin-bottom: 0; }
  .box-title span[class*="flaticon-"]:before, .box-title span[class*="flaticon-"]:after { font-size: 3.25rem; }

  .img-block-txt .box-title span[class*="flaticon-"]:before, 
  .img-block-txt .box-title span[class*="flaticon-"]:after { font-size: 2.35rem; }

  /* Content Box Typography */
  .cbox-1-txt p { margin-bottom: 5px; }
  .cbox-6 h6 { margin-bottom: 12px; }

  /* Accordion */
  .txt-block .accordion-1 .accordion-item { padding: 18px 25px; }
  .txt-block .accordion-2 .accordion-item { padding: 10px 0 20px; }

  .txt-block .accordion-1 .accordion-item .accordion-thumb:before { font-size: 0.95rem; top: 0; }
  .txt-block .accordion-2 .accordion-item .accordion-thumb:after,
  .txt-block .accordion-2 .accordion-item.is-active .accordion-thumb:after { font-size: 0.95rem; top: 8px; }

  .txt-block .accordion-1 .accordion-item.is-active { padding: 30px 25px 14px 25px; }
  .txt-block .accordion-2 .accordion-item.is-active { padding: 15px 0 20px; }

  .txt-block .accordion-1 .accordion-panel { padding: 0; }

  .txt-block .accordion-2 .accordion-thumb h5 { padding-left: 12px; padding-right: 0; }

  .accordion-2 .accordion-thumb span[class*="flaticon-"]:before,
  .accordion-2 .accordion-thumb span[class*="flaticon-"]:after { font-size: 1.75rem; top: 6px; }

  /* Tabs */
  .tabs-1 li { font-size: 1.125rem; } 

  /* Advantages */
  .advantages, .text-center .advantages { margin-top: 15px; }
  .img-block-btn .advantages li { display: block!important; margin-top: 4px; }
  .img-block-btn .advantages li p { display: block; float: none; }
  .img-block-btn li.advantages-links-divider { display: none!important; }

  /* Compatibility */
  .compatibility { margin-top: 10px; }

  /* Tools List / Payments Icons */
  .tools-list p, .payment-methods p { margin-bottom: 12px; }
  .tools-list span { padding-right: 1px; }
  .payment-icons img { max-height: 30px; }

  .tools-list.ico-35 [class*="flaticon-"]:before, 
  .tools-list.ico-35 [class*="flaticon-"]:after {  font-size: 2.125rem; line-height: 2.125rem!important; margin-right: 4px; }

  /* Content Box Shapes */
  .cbox-5-shape-1, .cbox-5-shape-2 { display: none; }

  /* Content Images */
  .img-block.img-block-bkg img { padding: 35px 20px; }
  .ct-10 .img-block { margin-bottom: -40px; }

  .ct-01 .img-block, .ct-02 .img-block, .ct-03 .img-block.right-column, .ct-03 .img-block.left-column { margin: 0 2% 35px; }
  .ct-01 .img-block.j-img, .ct-02 .img-block.j-img { margin-left: 0%; margin-right: 0; }
  .ct-06 .img-block, .ct-07 .img-block { margin-top: -80px; margin-bottom: 40px; }
  .bc-5-img.bc-5-tablet.img-block-hidden {
    .img-tablet {
      max-width: 100%;
    }
  }
  .bc-6-img { padding: 30px 15px 0; margin-bottom: 25px; }

  .img-block-hidden .video-btn, .bc-5-img.bc-5-dashboard.img-block-hidden .video-btn { top: 50%; }
  .bc-5-img.bc-5-tablet.img-block-hidden .video-btn { top: calc(50% - 30px); }
  .img-block-txt, .img-block-btn { margin-top: 40px; }
  .ct-11 .img-block, .ct-12 .img-block { margin-bottom: 40px; padding: 40px 7%; }

  /* STATISTIC */
  .sb-rounded .statistic-block { text-align: center; padding: 25px; }
  #statistic-5 .statistic-digit { padding-bottom: 18px; margin: 0 12% 18px; }

  #sb-1-1, #sb-1-2, #sb-3-1, #sb-3-2, #sb-3-3 { margin-bottom: 35px; }
  #sb-2-1, #sb-2-2, #sb-2-3, #sb-2-4 { width: 50%; }
  #sb-2-3, #sb-2-4 { padding-right: 12px; }
  #sb-4-3 { margin-bottom: 30px; }
  #sb-5-1, #sb-5-2 { margin-bottom: 35px; }

  .statistic-1-wrapper .statistic-block-digit { margin-bottom: 12px; }

  h2.statistic-number { font-size: 3.5rem; line-height: 1; letter-spacing: -0.5px; }
  .txt-block-stat h2.statistic-number { font-size: 2rem; }
  #statistic-1 h2.statistic-number { letter-spacing: -0.5px; }
  #sb-2-1 h2.statistic-number, #sb-2-2 h2.statistic-number { margin-bottom: 12px; }
  #statistic-5 .statistic-digit h2 { font-size: 2.25rem; letter-spacing: -0.5px; }

  #sb-2-3 .statistic-block p.p-sm, #sb-2-4 .statistic-block p.p-sm { margin-top: 12px; }
  .txt-block-stat .statistic-block p { font-size: 0.95rem; }
  #statistic-5 .statistic-txt p { padding: 0 8%; }

  /* PROJECTS */
  #pt-1-1, #pt-1-2, #pt-1-3, .inner-page-hero #pt-1-3, .inner-page-hero #pt-1-4, .inner-page-hero #pt-1-5 { margin-bottom: 40px; }
  .project-details h5 { margin-bottom: 25px; }
  .more-projects { margin-top: 45px; }

  /* PROJECT DETAILS */
  .single-project .project-title { margin-bottom: 40px; }
  .project-title h2 { padding-right: 0; padding-bottom: 25px; margin-bottom: 25px; }
  .project-data p { margin-bottom: 5px; }

  .project-priview-img.mb-50 { margin-bottom: 35px; }
  .project-inner-img .top-img { margin-bottom: 30px; }
  .project-inner-img.mt-50 { margin-top: 35px; }
  .project-txt h5.mt-35.mb-35 { margin-top: 20px; margin-bottom: 20px; }
  .project-txt h5.mt-50.mb-35 { margin-top: 35px; margin-bottom: 20px; }

  .project-description .more-projects { margin-top: 45px; }
  .project-description .more-projects span { top: 1px; left: 4px; }
  .project-description .more-projects.ico-25 [class*="flaticon-"]:before, 
  .project-description .more-projects.ico-25 [class*="flaticon-"]:after { font-size: 1.125rem; } 

  /* RATING */
  #rb-2-1, #rb-2-2 { margin-bottom: 25px; }

  .rating-title h5 { font-size: 1.125rem; padding: 0 15%; }
  .rbox-1-img { margin-bottom: 15px; }
  .rbox-1-img img { max-height: 31px; }
  .rbox-2-txt img { max-height: 31px; }
  p.rbox-2-txt { line-height: 31px; margin-top: 8px; }

  .rbox-1 .star-rating { padding: 5px 16px; }
  .rbox-1 .star-rating { font-size: 0.9rem; line-height: 1; }

  .rbox-1 .star-rating span { top: 2px; }
  .rbox-1 .ico-10 [class*="flaticon-"]:before, .rbox-1 .ico-10 [class*="flaticon-"]:after { font-size: 0.9rem; } 
  .rbox-1 .ico-15 [class*="flaticon-"]:before, .rbox-1 .ico-15 [class*="flaticon-"]:after { font-size: 1rem; } 
  .rbox-2 .ico-20 [class*="flaticon-"]:before, .rbox-2 .ico-20 [class*="flaticon-"]:after { font-size: 1.35rem; } 
  .rbox-2 .ico-25 [class*="flaticon-"]:before, .rbox-2 .ico-25 [class*="flaticon-"]:after { font-size: 1.35rem; } 

  /* TESTIMONIALS */
  #reviews-4 .txt-block, #reviews-5 .txt-block { text-align: center; }
  #reviews-4 .txt-block h2, #reviews-5 .txt-block h2 { font-size: 1.90625rem; padding: 0 4%; }  /* 30.5px */

  .review-1 { padding: 35px 20px 30px; margin: 0 6px 20px; }
  .review-2, .review-3, .review-4, .review-5 { padding: 35px 20px 30px; }

  .review-txt { margin-top: -40px; }
  .review-txt h6 { margin-bottom: 10px; }
  .review-3 .review-txt p { font-size: 1.0625rem; }  

  .review-1 .author-data, .review-2 .author-data, 
  .review-3 .author-data, .review-4 .author-data, .review-5 .author-data { margin-top: 20px; }

  .review-avatar img, .owl-item .review-avatar img { width: 52px; height: 52px; }
  .review-2 .review-avatar img, .review-3 .review-avatar img, .review-5 .review-avatar img { width: 52px; height: 52px; }

  .review-author { padding: 0 0 0 12px; }
  .review-1 .review-author, .review-2 .review-author, .review-3 .review-author, .review-5 .review-author { margin-top: 3px; }

  .review-author h6 { margin-bottom: 8px; }
  .review-1 .review-author h6, .review-2 .review-author h6, .review-3 .review-author h6, 
  .review-4 .review-author h6, .review-5 .review-author h6 { font-size: 1.2rem; }


  /* BRANDS */
  #brands-3:after { height: 75%; }

  .brands-title { padding: 0 4%; } 
  .brands-title.mb-35, .brands-title.mb-40 { margin-bottom: 25px; }
  .brands-title.mb-50 { margin-bottom: 30px; }

  .brands-title h4, .brands-title h3, .brands-title h2 { margin-bottom: 8px; }
  .brands-title p.p-md, .brands-title p.p-lg { font-size: 1.0625rem; }

  .brands-3-wrapper, .brands-3-wrapper.brands-3-static { padding: 25px 15px; }

  #brand-2-1, #brand-2-2, #brand-2-3, #brand-2-4, #brand-3-1, #brand-3-2, #brand-4-1, #brand-4-2, 
  #brand-4-3, #brand-4-4, #brand-4-5, #brand-4-6, #brand-4-7, #brand-4-8, #brands-4 .brand-logo.mb-40 { margin-bottom: 25px; }
  #brand-3-5 { display: none; }

  #brands-1 .brands-carousel-6 .brand-logo, #brands-1 .brands-carousel-5 .brand-logo { padding: 0 25px; }   
  #brands-1 .justify-content-center .brand-logo, #brands-4 .brand-logo { padding: 0 25px; }   
  #brands-2 .brand-logo, #brands-3 .brand-logo, #brands-3 .brands-3-static .brand-logo { padding: 0 17px; } 

  .brands-section .more-btn { margin-top: 30px; }

  /* INTEGRATIONS */
  #integrations-2 .container { max-width: 100%; }
  .integrations-2-wrapper { padding: 70px 20px; margin: 0 -15px; border-radius: 0 0!important; }
  .in_tool_logo.ico-65 img { width: 55px; height: 55px; }
  .in_tool h6 { font-size: 1.125rem; }

  #integrations-1 .in_tool-logo.ico-60 img { width: 52px; height: 52px; }
  .integrations-1-wrapper .in_tool { padding: 18px 22px; }
  .integrations-1-wrapper .in_tool.mb-30, .integrations-1-wrapper .it-7, .integrations-1-wrapper .it-8 { margin-bottom: 25px; }
  .integrations-1-wrapper .in_tool-txt h6 { font-size: 1.325rem; line-height: 1; margin-top: 5px; }
  .integrations-1-wrapper .in_tool-txt p { font-size: 1.0625rem; margin-top: 6px; }

  .integrations-section .more-btn.mt-60, .integrations-section .more-btn.mt-70 { margin-top: 30px; }

  /* FOOTER */
  .footer { padding-bottom: 30px; padding-top: 40px }
  .footer hr { margin-top: 5px; margin-bottom: 30px; }
  .bottom-footer p.p-sm { margin-bottom: 0; }
}




/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */

@media (min-width: 321px) and (max-width: 389.98px) {
  .logo {
    img {
      width: 116px;
    }
  }
  .blockchains-wrapper {
    flex-direction: column;
    align-items: center;
    gap: 17px;
    .blockchains {
      justify-content: center;
      gap: 10px 18px;
      max-width: 230px;
      .center-row {
        gap: 10px 18px;
      }
      .blockChainsWithFooter_blockChains_blockchains--img {
        width: 50px;
        height: 50px;
      }
    }
  }
  html { font-size: 97.5%; } 

  /*------------------------------------------*/
  /*  BASE
  /*------------------------------------------*/

  #ludo-rank .section-title.mb-80, #web3-curators-network .section-title.mb-80, #ludo-rank-dao .section-title.mb-80{  padding: 0 20px; }
  #stlChanger { display: none; }
  .ts-0 { padding-top: 80px; }
  .division { padding-left: 16px; padding-right: 16px; }
  .features { padding-left: 10px; padding-right: 10px; }
  .bg--fixed { width: auto; }
  .bg--scroll { width: auto; background-attachment: scroll!important; }

  /* Backgroud Shape */
  .shape--01:after { height: 90%; top: 7%; }
  .shape--02:after { width: 140%; height: 90%; top: 7%; left: -20%; }
  .shape--03:after { width: 140%; height: 129%; top: -70px; left: -20%; }
  .shape--04:after { height: 94%; top: 0; }
  .shape--06:after { height: 92%; }
  .shape--07:after { height: 80%; top: 15%; }

  ol.digit-list p { margin-bottom: 5px; }

  .font--jakarta h1, .font--jakarta h2 { line-height: 1.35; }
  .font--jakarta h3, .font--jakarta h4, .font--jakarta h5, .font--jakarta h6 { line-height: 1.4; }

  /* Font Size */
  .s-15, .s-16 { font-size: 1.0625rem; }                                /* 16.5px */
  .s-17 { font-size: 1.125rem; }                                        /* 17.5px */
  .s-18, .s-19, .s-20, .s-21, .s-22, .s-23 { font-size: 1.217948rem; }  /* 19px */
  .s-24, .s-26, { font-size: 1.34615rem; }                        /* 21px */
  .s-30, .s-32, .s-34 { font-size: 1.47435rem; }                        /* 23px */
  .s-36, .s-38, .s-40 { font-size: 1.60256rem; }                        /* 25px */  
  .s-42, .s-43, .s-44, .s-45, .s-46, .s-47 { font-size: 1.730769rem; }  /* 27px */
  .s-48 { font-size: 1.85897rem; }                                      /* 29px */  
  .s-50, .s-52, .s-54, .s-56, .s-58, .s-60, .hero-txt .s-60 { font-size: 1.923076rem; }  /* 30px */
  .s-62 { font-size: 1.98717rem; }                                      /* 31px */ 
  .s-64, .s-66 { font-size: 2.05128rem; }                               /* 32px */
  .s-68 { font-size: 2.17948rem; }                                      /* 34px */

  /* Paragraphs */
  p.p-md, p.p-lg { font-size: 1.0625rem; }
  p.p-xl { font-size: 1.125rem; }

  /* Transparent Link */
  a.tra-link { font-size: 1.0625rem; }
  .tra-link.ico-20 [class*="flaticon-"]:before, 
  .tra-link.ico-20 [class*="flaticon-"]:after { font-size: 0.785rem; line-height: 0.7rem!important; top: 1px; left: 2px; }

  /* Button */
  .btn { font-size: 1rem; }
  .btn.btn-sm { font-size: 1rem; padding: 0.7rem 1.5rem; }
  .btns-group .btn.ico-30.ico-left, .btns-group .btn.ico-30.ico-right { padding: 0.61095rem 0.5rem 0; }

  /* Buttons Group */
  .btns-group .btn:first-child { margin-left: 5px; margin-right: 5px; margin-bottom: 15px; }

  /* Button Icon */
  .btn.ico-20 [class*="flaticon-"]:before, 
  .btn.ico-20 [class*="flaticon-"]:after { font-size: 1.225rem; line-height: 0.5rem!important;top: 4px; }

  .btn.ico-30 [class*="flaticon-"]:before, .btn.ico-30 [class*="flaticon-"]:after { font-size: 2rem; }

  .btn.ico-30.ico-left span.ico-30-txt { font-size: 1.1rem; top: -10px; margin-right: 0; }
  .btn.ico-30.ico-right span.ico-30-txt { font-size: 1.1rem; top: -10px; margin-left: 0; }
  .btn.ico-left span { margin-right: 2px; }
  .btn.ico-right span { margin-left: 2px; }


  /* Section ID */
  .section-id { margin-bottom: 25px; }
  .section-id.rounded-id { padding: 8px 22px; font-size: 0.8rem; margin-bottom: 30px; }

  /* Section Title */
  .section-title.mb-60 { margin-bottom: 35px; }
  .section-title.mb-70, .section-title.mb-80 { margin-bottom: 40px; }

  .section-title p.p-md, .section-title p.p-lg { margin-top: 14px; }
  .section-title p.p-xl { font-size: 1.0625rem; margin-top: 14px; }
  .section-title p.s-20, .section-title p.s-21 { font-size: 1.15384rem; padding: 0 2%; margin-top: 12px; }  /* 18px */
  .section-title p.p-md, .section-title p.p-lg { padding: 0; }

  .section-title .btns-group.mt-35, .section-title .stores-badge.mt-30 { margin-top: 22px; }
  .section-title .btns-group .btn { margin-left: 10px; margin-right: 10px; }  
  .section-title .btns-group .btn:first-child { margin-bottom: 15px; }

  /* Header Store Badges */
  .store.header-store img { max-height: 44px; }

  /* Header Social Links */
  .header-socials.ico-20 [class*="flaticon-"]:before, 
  .header-socials.ico-25 [class*="flaticon-"]:after { line-height: 52px!important; } 

  .wsmenu > .wsmenu-list > li > .wsmegamenu ul li.title { margin-top: 12px; } 

  .icons-menu-ico { margin-right: 17px; }
  .icons-menu-txt span { font-size: 0.9rem; }

  /*------------------------------------------*/
  /*   HERO   
  /*------------------------------------------*/

  .hero-section.blur--purple:after { top: -25%; opacity: .16; }

  .hero-section h2 { margin-bottom: 15px; }
  .hero-section h2.s-48, .hero-section h2.s-50 { margin-bottom: 20px; }
  .hero-section p.p-md, .hero-section p.p-lg, .hero-section p.p-xl { margin-bottom: 25px; }
  .hero-section p.s-21 { font-size: 1.217948rem; margin-bottom: 22px; }
  .hero-section .btn.mt-15, .hero-section .btns-group.mt-15 { margin-top: 8px; }

  /* Hero Advantages */
  .hero-section .text-center .advantages { margin-top: 18px; }
  .hero-section .advantages li { display: block!important; margin-top: 3px; }
  .hero-section .advantages li p { display: block; float: none; }
  .hero-section li.advantages-links-divider { display: none!important; }

  /* HERO LOGO */
  .hero-logo { margin-bottom: 30px; }
  .hero-logo img { max-height: 30px; }

  .hero-square-logo { margin-bottom: 10px; }
  .hero-square-logo img { max-height: 60px; }

   /* hero */
  #hero { margin-bottom: 0; margin-top: 0;}
  .hero-txt h1 { margin-bottom: 12px;}
  .hero-txt p.p-lg { margin-bottom: 20px; }

  /*------------------------------------------*/
  /*  CONTENT
  /*------------------------------------------*/ 

  /* FEATURES */
  #features-14 .container { max-width: 100%; }
  .fbox-14-wrapper { padding: 70px 30px; margin: 0 -15px; border-radius: 0 0!important; } 

  #features-12.py-100.shape--bg:after, #features-13.py-100.shape--bg:after { height: 68%; top: 32%; }
  #features-12.pt-100.shape--bg:after, #features-13.pt-100.shape--bg:after { height: 65%; top: 32%; }

  .row-cols-md-3 .fbox-4, .row-cols-md-2 .fbox-4, .fbox-7, .fbox-9 { padding: 40px 25px 35px; }
  .fbox-5 { padding: 45px 25px 40px; } 
  .row-cols-md-2 .fbox-7 { padding: 40px 40px 35px; }
  .fbox-8.fb-1 { padding: 0 25px 40px; }
  .fbox-8.bg--white-100 { padding: 45px 25px; }
  .fbox-8.fb-3 { padding: 45px 25px 0; }
  .fbox-12, .fbox-13 { padding: 32px 25px; } 
  #fb-12-1, #fb-13-1 { margin-top: 50px; }
  #features-12 .txt-block, #features-13 .txt-block { margin-bottom: 35px; }

  .fbox-14 { padding: 30px 25px; } 
  .fbox-14.fb-1, .fbox-14.fb-2 { margin-bottom: 30px; }

  .fbox-img.h-135 img, .fbox-img.h-140 img, .fbox-img.h-145 img, .fbox-img.h-150 img, .fbox-img.h-155 img, 
  .fbox-img.h-160 img, .fbox-img.h-165 img, .fbox-img.h-170 img, .fbox-img.h-175 img, .fbox-img.h-180 img { max-height: 170px; }

  .fbox-2 .fbox-img.h-135 img, .fbox-2 .fbox-img.h-140 img, .fbox-2 .fbox-img.h-145 img, .fbox-2 .fbox-img.h-150 img, 
  .fbox-2 .fbox-img.h-155 img, .fbox-2 .fbox-img.h-160 img, .fbox-2 .fbox-img.h-165 img, .fbox-2 .fbox-img.h-170 img, 
  .fbox-2 .fbox-img.h-175 img, .fbox-2 .fbox-img.h-180 img { max-height: 170px; }

  .fbox-3 .fbox-img.h-135 img, .fbox-3 .fbox-img.h-140 img, .fbox-3 .fbox-img.h-145 img, .fbox-3 .fbox-img.h-150 img, 
  .fbox-3 .fbox-img.h-155 img, .fbox-3 .fbox-img.h-160 img, .fbox-3 .fbox-img.h-165 img, .fbox-3 .fbox-img.h-170 img, 
  .fbox-3 .fbox-img.h-175 img, .fbox-3 .fbox-img.h-180 img { max-height: 170px; }

  .fbox-4 .fbox-img.h-135 img, .fbox-4.fbox-img.h-140 img, .fbox-4 .fbox-img.h-145 img, .fbox-4 .fbox-img.h-150 img, 
  .fbox-4 .fbox-img.h-155 img, .fbox-4 .fbox-img.h-160 img, .fbox-4 .fbox-img.h-165 img, .fbox-4 .fbox-img.h-170 img, 
  .fbox-4 .fbox-img.h-175 img { max-height: 155px; }

  .fbox-1 .fbox-img, .row-cols-md-2 .fbox-1 .fbox-img, .fbox-4 .fbox-img, .row-cols-md-2 .fbox-4 .fbox-img { margin-bottom: 30px; }

  .fbox-2 .fbox-img.h-135, .fbox-2 .fbox-img.h-140, .fbox-2 .fbox-img.h-145, 
  .fbox-2 .fbox-img.h-150, .fbox-2 .fbox-img.h-155, .fbox-2 .fbox-img.h-160, 
  .fbox-2 .fbox-img.h-165, .fbox-2 .fbox-img.h-170, .fbox-2 .fbox-img.h-175, 
  .fbox-2 .fbox-img.h-180 { padding-top: 40px; margin: 0 0 50px; }

  .fbox-3 .fbox-img.h-135, .fbox-3 .fbox-img.h-140, .fbox-3 .fbox-img.h-145, 
  .fbox-3 .fbox-img.h-150, .fbox-3 .fbox-img.h-155, .fbox-3 .fbox-img.h-160, 
  .fbox-3 .fbox-img.h-165, .fbox-3 .fbox-img.h-170, .fbox-3 .fbox-img.h-175, 
  .fbox-3 .fbox-img.h-180 { padding-top: 40px; margin: 0 0 30px; }

  .fbox-5.fb-1 .fbox-5-img, .fbox-5.fb-2 .fbox-5-img, .fbox-5.fb-3 .fbox-5-img, 
  .fbox-5.fb-4 .fbox-5-img, .fbox-5.fb-5 .fbox-5-img, .fbox-5.fb-6 .fbox-5-img { margin: 0 6% 30px; }

  .fbox-ico, .fbox-ico.ico-rounded, .fbox-ico.ico-square { margin-bottom: 20px; }

  .fbox-wrapper .ico-50 [class*="flaticon-"]:before, 
  .fbox-wrapper .ico-50 [class*="flaticon-"]:after,
  .fbox-wrapper .ico-55 [class*="flaticon-"]:before, 
  .fbox-wrapper .ico-55 [class*="flaticon-"]:after,
  .fbox-wrapper .ico-60 [class*="flaticon-"]:before, 
  .fbox-wrapper .ico-60 [class*="flaticon-"]:after,
  .fbox-10 .fbox-title.ico-35 [class*="flaticon-"]:before, 
  .fbox-10 .fbox-title.ico-35 [class*="flaticon-"]:after{ font-size: 3.6rem; }

  .features-section h6.s-18, .features-section h6.s-20, .features-section h6.s-22 { font-size: 1.34615rem; }  /* 21px */
  .features-section h6, .fbox-txt h5, .rows-2 .fbox-11 h6, .fbox-wrapper .row-cols-md-2 h6 { margin-bottom: 15px; }

  .fbox-10 .fbox-title { margin: 0 auto; }
  .fbox-10 .fbox-title span { float: none; line-height: 1!important; margin-right: 0; }
  .fbox-10 .fbox-title h6 { display: block; line-height: 1!important; margin-top: 15px; margin-bottom: 15px; }
  .fbox-14 h5 { padding: 0 8%; margin-bottom: 12px; }

  .row-cols-md-3 .fbox-1 p, .row-cols-md-3 .fbox-2 p, 
  .row-cols-md-3 .fbox-3 p, .row-cols-md-3 .fbox-6 p, .fbox-10 .fbox-txt p { padding: 0 4%; }

  .row-cols-md-2 .fbox-1 p, .row-cols-md-2 .fbox-2 p, .row-cols-md-2 .fbox-3 p, .row-cols-md-2 .fbox-6 p { padding: 0 8%; }
  .row-cols-md-2 .fbox-4 p { padding: 0 7%; }
  .fbox-5 .fbox-txt p, .rows-2 .fbox-11 p { padding: 0 5%; }
  .rows-3 .fbox-11 p { padding: 0 2%; }
  .row-cols-lg-3 .fbox-10 .fbox-txt p { padding: 0 1%; }

  /* CONTENT */
  .ct-06 { padding-bottom: 70px; }
  .ct-10 .section-overlay { margin-bottom: 40px; }
  .ct-top { margin-bottom: 70px; }

  .ct-13 .txt-block, .ct-13 .img-block { margin-bottom: 30px; }
  .ct-13 .txt-block.ml-block, .ct-13 .img-block.ml-block { margin-bottom: 0; }

  /* Content Box Wrapper */
  .content-section.ws-wrapper { margin: 0 -15px; }
  .bc-1-wrapper .section-overlay, .bc-4-wrapper .section-overlay { padding: 70px 32px; }
  #ludo-rank-dao .section-overlay { padding: 15px 32px 40px; }
  #web3-curators-network .bc-1-wrapper .section-overlay { padding: 40px 32px 15px; }
  .bc-2-wrapper .section-overlay, .bc-3-wrapper .section-overlay { padding: 70px 15px; }
  .bc-5-wrapper .section-overlay { padding: 40px 15px 0; }
  .cbox-6-wrapper { padding: 30px 20px; }
  .bc-6-wrapper .section-overlay { padding: 70px 15px; }
  .bc-6 { padding: 20px 15px 30px; }

  .bc-1-wrapper, .bc-2-wrapper, .bc-3-wrapper, .bc-4-wrapper, .bc-5-wrapper, .bc-6-wrapper { border-radius: 0 0!important; } 

  .bc-5-wrapper .section-title p { padding: 0 2%; }

  .bc-2-txt h2, .bc-3-txt h2, .bc-4-txt h2, .txt-block h3, .txt-block h2 { margin-bottom: 14px; }
  .cbox-2-txt h5, .cbox-3-txt h5, .bc-6-txt h5 { margin-bottom: 12px; }

  .txt-block p.p-sm, .txt-block p { margin-bottom: 0.897435rem; }
  .txt-block p.s-20, .txt-block p.s-21 { font-size: 1.12820rem; }
  .cbox-5-fea p, .txt-block p.video-txt-sm, .txt-block p.btn-txt, .txt-block p.btns-group-txt,
  .txt-block p.compatibility { margin-bottom: 0; }
  .txt-block p.video-txt-lg { margin-bottom: 6px; }

  .txt-block .btn { margin-top: 18px; }
  .txt-block .stores-badge.mt-15, .ws-wrapper .stores-badge.mt-15 { margin-top: 8px; } 
  .txt-block .btns-group .btn.btn--transparent { margin-top: 0; } 
  .bc-6-link.mt-15 { margin-top: 12px; }

  /* Content Box */
  .right-column .cbox-5 { margin-bottom: 40px; }
  .cbox-5-content { padding: 25px; margin: 0; }
  .cbox-5-content .divider { margin-top: 22px; margin-bottom: 22px; }

  .txt-block .cbox-5-title h2 { font-size: 2.45rem; margin-bottom: 10px; }
  .cbox-5-title .statistic-number sup { font-size: 2.05rem; top: -5px; right: 6px; }

  .cbox-5-fea { padding: 15px 20px; margin-top: 20px; }
  .txt-block .cbox-5-fea h4 { font-size: 1.625rem; }
  .cbox-5-txt .btn { margin-top: 10px; }
  .txt-block-tra-link.mt-25 { margin-top: 16px; }

  #cb-6-1, #cb-6-2, #cb-6-3 { margin-bottom: 22px; }

  /* Content Box Icon */ 
  .cbox-1.ico-10 span { right: 6px; }
  .cbox-1.ico-15 span { top: 0.5px; right: 6px; }
  .cbox-1.ico-15 [class*="flaticon-"]:before, .cbox-1.ico-15 [class*="flaticon-"]:after { font-size: 0.7rem; }

  .cbox-2 .ico-wrap { margin-right: 1rem; }
  .cbox-2-ico { width: 2.3rem; height: 2.3rem; font-size: 1.15rem; line-height: 2.05rem; }
  .ct-03 .cbox-2-ico { width: 1.55rem; height: 1.55rem; font-size: 1rem; line-height: 1.35rem; }
  .cbox-2-line { height: calc(100% - 74px); }

  .cbox-3 { display: block; text-align: center; }
  .cbox-3 .ico-wrap { margin-right: 0; }
  .cbox-3 .cbox-3-ico { top: 0; margin-bottom: 10px; }
  .cbox-3-ico span[class*="flaticon-"]:before, .cbox-3-ico span[class*="flaticon-"]:after { font-size: 3rem; }

  .cbox-4 { text-align: center; }
  .box-title h6, .box-title h5 { display: block; line-height: 1!important; margin-bottom: 15px; }
  .img-block-txt .box-title h6, 
  .img-block-txt .box-title h5 { display: inline-block; line-height: 2.35rem!important; margin-bottom: 20px; }
  .box-title span { display: block; position: relative; top:0; margin-right: 0; margin-bottom: 10px; }
  .img-block-txt .box-title span { display: inline-block; top: 10px; margin-right: 10px; margin-bottom: 0; }
  .box-title span[class*="flaticon-"]:before, .box-title span[class*="flaticon-"]:after { font-size: 3rem; }

  .img-block-txt .box-title span[class*="flaticon-"]:before, 
  .img-block-txt .box-title span[class*="flaticon-"]:after { font-size: 2.35rem; }

  /* Content Box Text */ 
  .cbox-2-txt, .ct-03 .cbox-2-txt, .cbox-3-txt { margin-bottom: 20px; }
  .cbox-4-txt { margin-bottom: 30px; }

  /* Content Box Typography */
  .cbox-1-txt p { margin-bottom: 5px; }
  .cbox-6 h6 { margin-bottom: 12px; }

  /* Accordion */
  .txt-block .accordion-1 .accordion-item { padding: 18px 25px; }
  .txt-block .accordion-2 .accordion-item { padding: 10px 0 20px; }

  .txt-block .accordion-1 .accordion-item .accordion-thumb:before { font-size: 0.95rem; top: -1px; }
  .txt-block .accordion-2 .accordion-item .accordion-thumb:after,
  .txt-block .accordion-2 .accordion-item.is-active .accordion-thumb:after { font-size: 0.95rem; top: 8px; }

  .txt-block .accordion-1 .accordion-item.is-active { padding: 30px 25px 14px 25px; }
  .txt-block .accordion-2 .accordion-item.is-active { padding: 15px 0 20px; }

  .txt-block .accordion-1 .accordion-panel { padding: 0; }

  .txt-block .accordion-2 .accordion-thumb h5 { padding-left: 10px; padding-right: 0; }

  .accordion-2 .accordion-thumb span[class*="flaticon-"]:before,
  .accordion-2 .accordion-thumb span[class*="flaticon-"]:after { font-size: 1.75rem; top: 6px; }

  /* Tabs */
  .tabs-1 li { font-size: 1.125rem; } 

  /* Advantages */
  .advantages, .text-center .advantages { margin-top: 15px; }
  .img-block-btn .advantages li { display: block!important; margin-top: 4px; }
  .img-block-btn .advantages li p { display: block; float: none; }
  .img-block-btn li.advantages-links-divider { display: none!important; }

  /* Compatibility */
  .compatibility { margin-top: 10px; }

  /* Tools List / Payments Icons */
  .tools-list p, .payment-methods p { margin-bottom: 12px; }
  .tools-list span { padding-right: 1px; }
  .payment-icons img { max-height: 30px; }

  .tools-list.ico-35 [class*="flaticon-"]:before, .tools-list.ico-35 [class*="flaticon-"]:after { 
    font-size: 2.125rem;line-height: 2.125rem!important; margin-right: 4px;
  }

  /* Content Box Shapes */
  .cbox-5-shape-1, .cbox-5-shape-2 { display: none; }

  /* Content Images */
  .img-block.img-block-bkg img { padding: 35px 20px; }
  .ct-10 .img-block { margin-bottom: -40px; }

  .ct-01 .img-block, .ct-02 .img-block, .ct-03 .img-block.right-column, .ct-03 .img-block.left-column { margin: 0 2% 35px; }
  .ct-01 .img-block.j-img, .ct-02 .img-block.j-img { margin-left: 0%; margin-right: 0; }
  .ct-06 .img-block, .ct-07 .img-block { margin-top: -80px; margin-bottom: 40px; }

  .img-block-hidden .video-btn, .bc-5-img.bc-5-dashboard.img-block-hidden .video-btn { top: 50%; }
  .bc-5-img.bc-5-tablet.img-block-hidden .video-btn { top: calc(50% - 25px); }
  .img-block-txt, .img-block-btn { margin-top: 35px; }
  .bc-5-img.bc-5-tablet.img-block-hidden {
    .img-tablet {
      max-width: 100%;
    }
  }
  .bc-6-img { padding: 30px 15px 0; margin-bottom: 25px; }
  .ct-11 .img-block, .ct-12 .img-block { margin-bottom: 35px; padding: 40px 6%; }

  /* STATISTIC */
  .sb-rounded .statistic-block { text-align: center; padding: 25px; }
  #statistic-5 .statistic-digit { padding-bottom: 18px; margin: 0 10% 18px; }

  #sb-1-1, #sb-1-2, #sb-3-1, #sb-3-2, #sb-3-3 { margin-bottom: 35px; }
  #sb-2-1, #sb-2-2, #sb-2-3, #sb-2-4 { width: 50%; }
  #sb-2-3, #sb-2-4 { padding-right: 12px; }
  #sb-4-3 { margin-bottom: 30px; }
  #sb-5-1, #sb-5-2 { margin-bottom: 35px; }

  .statistic-1-wrapper .statistic-block-digit { margin-bottom: 12px; }

  h2.statistic-number { font-size: 3.5rem; line-height: 1; letter-spacing: -0.5px; }
  .txt-block-stat h2.statistic-number { font-size: 1.98717rem; }
  #statistic-1 h2.statistic-number { letter-spacing: -0.5px; }
  #sb-2-1 h2.statistic-number, #sb-2-2 h2.statistic-number { margin-bottom: 12px; }
  #statistic-5 .statistic-digit h2 { font-size: 2.21153rem; letter-spacing: -0.5px; }

  #sb-2-3 .statistic-block p.p-sm, #sb-2-4 .statistic-block p.p-sm { margin-top: 12px; }
  .txt-block-stat .statistic-block p { font-size: 0.95rem; }
  #statistic-5 .statistic-txt p { padding: 0 5%; }

  /* PROJECTS */
  #pt-1-1, #pt-1-2, #pt-1-3, .inner-page-hero #pt-1-3, .inner-page-hero #pt-1-4, .inner-page-hero #pt-1-5 { margin-bottom: 35px; }
  .project-details h5 { margin-bottom: 25px; }
  .more-projects { margin-top: 45px; }

  /* PROJECT DETAILS */
  .single-project .project-title { margin-bottom: 40px; }
  .project-title h2 { padding-right: 0; padding-bottom: 25px; margin-bottom: 25px; }
  .project-data p { margin-bottom: 5px; }

  .project-priview-img.mb-50 { margin-bottom: 35px; }
  .project-inner-img .top-img { margin-bottom: 30px; }
  .project-inner-img.mt-50 { margin-top: 35px; }
  .project-txt h5.mt-35.mb-35 { margin-top: 20px; margin-bottom: 20px; }
  .project-txt h5.mt-50.mb-35 { margin-top: 35px; margin-bottom: 20px; }

  .project-description .more-projects { margin-top: 40px; }
  .project-description .more-projects span { top: 1px; left: 4px; }
  .project-description .more-projects.ico-25 [class*="flaticon-"]:before, 
  .project-description .more-projects.ico-25 [class*="flaticon-"]:after { font-size: 1.1rem; } 

  /* RATING */
  #rb-2-1, #rb-2-2 { margin-bottom: 25px; }

  .rating-title h5 { font-size: 1.125rem; padding: 0 15%; }
  .rbox-1-img { margin-bottom: 15px; }
  .rbox-1-img img { max-height: 32px; }
  .rbox-2-txt img { max-height: 30px; }
  p.rbox-2-txt { line-height: 30px; margin-top: 8px; }

  .rbox-1 .star-rating { padding: 5px 16px; }
  .rbox-1 .star-rating { font-size: 0.9rem; line-height: 1; }

  .rbox-1 .star-rating span { top: 2px; }
  .rbox-1 .ico-10 [class*="flaticon-"]:before, .rbox-1 .ico-10 [class*="flaticon-"]:after { font-size: 0.9rem; } 
  .rbox-1 .ico-15 [class*="flaticon-"]:before, .rbox-1 .ico-15 [class*="flaticon-"]:after { font-size: 1rem; } 
  .rbox-2 .ico-20 [class*="flaticon-"]:before, .rbox-2 .ico-20 [class*="flaticon-"]:after { font-size: 1.3rem; } 
  .rbox-2 .ico-25 [class*="flaticon-"]:before, .rbox-2 .ico-25 [class*="flaticon-"]:after { font-size: 1.3rem; } 

  /* TESTIMONIALS */
  #reviews-4 .txt-block, #reviews-5 .txt-block { text-align: center; }
  #reviews-4 .txt-block h2, #reviews-5 .txt-block h2 { font-size: 1.826923rem; padding: 0 3%; }  /* 28.5px */

  .review-1 { padding: 35px 25px 30px; }
  .review-2 { padding: 35px 20px 24px; }
  .review-3, .review-4, .review-5 { padding: 35px 25px 24px; }

  .review-txt { margin-top: -43px; }
  .review-txt h6 { margin-bottom: 10px; }
  .review-3 .review-txt p { font-size: 1rem; }  

  .review-1 .author-data, .review-2 .author-data, 
  .review-3 .author-data, .review-4 .author-data, .review-5 .author-data { margin-top: 20px; }

  .review-avatar img, .owl-item .review-avatar img { width: 52px; height: 52px; }
  .review-2 .review-avatar img, .review-3 .review-avatar img, .review-5 .review-avatar img { width: 52px; height: 52px; }

  .review-author { padding: 0 0 0 12px; }
  .review-1 .review-author, .review-2 .review-author, .review-3 .review-author, .review-5 .review-author { margin-top: 3px; }

  .review-author h6 { margin-bottom: 8px; }
  .review-1 .review-author h6, .review-2 .review-author h6, .review-3 .review-author h6, 
  .review-4 .review-author h6, .review-5 .review-author h6 { font-size: 1.1875rem; }

  /* PRESS */
  #press-01, #press-02, #press-03, #press-04, #press-05, #press-06, #press-07 { margin-bottom: 25px; }
  .press-logo img { max-height: 72px; }
  .press-txt { padding: 0 1%; margin-top: 10px; }

  /* BRANDS */
  #brands-3:after { height: 75%; }

  .brands-title { padding: 0 4%; } 
  .brands-title.mb-35, .brands-title.mb-40 { margin-bottom: 25px; }
  .brands-title.mb-50 { margin-bottom: 30px; }

  .brands-title h4, .brands-title h3, .brands-title h2 { margin-bottom: 8px; }
  .brands-title p.p-md, .brands-title p.p-lg { font-size: 1rem; }

  .brands-3-wrapper, .brands-3-wrapper.brands-3-static { padding: 25px 15px; }

  #brand-2-1, #brand-2-2, #brand-2-3, #brand-2-4, #brand-3-1, #brand-3-2, #brand-4-1, #brand-4-2, 
  #brand-4-3, #brand-4-4, #brand-4-5, #brand-4-6, #brand-4-7, #brand-4-8, #brands-4 .brand-logo.mb-40 { margin-bottom: 25px; }
  #brand-3-5 { display: none; }

  #brands-1 .brands-carousel-6 .brand-logo, #brands-1 .brands-carousel-5 .brand-logo { padding: 0 18px; }   
  #brands-1 .justify-content-center .brand-logo, #brands-4 .brand-logo { padding: 0 18px; }   
  #brands-2 .brand-logo, #brands-3 .brand-logo, #brands-3 .brands-3-static .brand-logo { padding: 0 13px; } 

  .brands-section .more-btn { margin-top: 30px; }

  /* FOOTER */
  .footer { padding-bottom: 30px; }
  .footer hr { margin-top: 5px; margin-bottom: 30px; }
}




/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */

@media (max-width: 320.98px) {

  .logo {
    img {
      width: 116px;
    }
  }
  .blockchains-wrapper {
    flex-direction: column;
    gap: 17px;
    .blockchains {
      justify-content: center;
      gap: 10px 18px;
      max-width: 230px;
      .center-row {
        gap: 10px 18px;
      }
      .blockChainsWithFooter_blockChains_blockchains--img {
        width: 50px;
        height: 50px;
      }
    }
  }

  /*------------------------------------------*/
  /*  BASE
  /*------------------------------------------*/

  html { font-size: 87.5%; } 

  #stlChanger { display: none; }
  .ts-0 { padding-top: 80px; }
  .bg--fixed { width: auto; }
  .bg--scroll { width: auto; background-attachment: scroll!important; }

  .py-50 { padding-top: 30px; padding-bottom: 30px; }
  .py-60 { padding-top: 40px; padding-bottom: 40px; }
  .py-70 { padding-top: 40px; padding-bottom: 40px; }
  .py-80 { padding-top: 40px; padding-bottom: 40px; }
  .py-90 { padding-top: 50px; padding-bottom: 50px; }
  .py-100 { padding-top: 60px; padding-bottom: 60px; }
  .py-130 { padding-top: 90px; padding-bottom: 90px; }

  .pt-50 { padding-top: 30px; }
  .pt-60 { padding-top: 40px; }
  .pt-70 { padding-top: 40px; }
  .pt-80 { padding-top: 40px; }
  .pt-90 { padding-top: 50px; }
  .pt-100 { padding-top: 60px; }

  .pb-50 { padding-bottom: 30px; }
  .pb-60 { padding-bottom: 40px; }
  .pb-70 { padding-bottom: 40px; }
  .pb-80 { padding-bottom: 40px; }
  .pb-90 { padding-bottom: 50px; }
  .pb-100 { padding-bottom: 60px; }

  .mb-40 { margin-bottom: 20px; }
  .mb-50 { margin-bottom: 30px; }
  .mb-60 { margin-bottom: 40px; }

  /* Backgroud Shape */
  .shape--01:after { height: 90%; top: 6.5%; }
  .shape--02:after { width: 140%; height: 90%; top: 6.5%; left: -20%; }
  .shape--03:after { width: 140%; height: 129%; top: -70px; left: -20%; }
  .shape--04:after { height: 92%; top: 0; }
  .shape--05:after { height: 94%; }
  .shape--06:after { height: 92%; }
  .shape--07:after { height: 80%; top: 15%; }

  ol.digit-list p { margin-bottom: 5px; }

  .font--jakarta h1, .font--jakarta h2 { line-height: 1.35; }
  .font--jakarta h3, .font--jakarta h4, .font--jakarta h5, .font--jakarta h6 { line-height: 1.4; }

  /* Font Size */
  .s-15, .s-16 { font-size: 1rem; }                                    /* 14px */
  .s-17 { font-size: 1.0625rem; }                                      /* 14.875px */
  .s-18, .s-19, .s-20, .s-21, .s-22, .s-23 { font-size: 1.285714rem; } /* 18px */
  .s-24, .s-26,  { font-size: 1.35714rem; }                       /* 19px */
  .s-30, .s-32, .s-34 { font-size: 1.5rem; }                           /* 21px */
  .s-36, .s-38, .s-40 { font-size: 1.57142rem; }                       /* 22px */  
  .s-42, .s-43, .s-44, .s-45, .s-46, .s-47 { font-size: 1.714285rem; } /* 24px */ 
  .s-48 { font-size: 1.85714rem; }                                     /* 26px */  
  .s-50, .s-52, .s-54, .s-56, .s-58, .s-60 { font-size: 1.92857rem; }  /* 27px */ 
  .s-62 { font-size: 2rem; }                                           /* 28px */   
  .s-64, .s-66, .s-68 { font-size: 2.07142rem; }                       /* 29px */
  .s-68 { font-size: 2.14285rem; }                                     /* 30px */

  /* Paragraphs */
  p.p-md, p.p-lg { font-size: 1.0625rem; }
  p.p-xl { font-size: 1.125rem; }

  /* Transparent Link */
  a.tra-link { font-size: 1.0625rem; }
  .tra-link.ico-20 [class*="flaticon-"]:before, 
  .tra-link.ico-20 [class*="flaticon-"]:after { font-size: 0.785rem; line-height: 0.7rem!important; top: 1px; left: 2px; }

  /* Button */
  .btn { font-size: 1rem; }
  .btn.btn-sm { font-size: 1rem; padding: 0.7rem 1.5rem; }
  .btns-group .btn.ico-30.ico-left, .btns-group .btn.ico-30.ico-right { padding: 0.391175rem 0.5rem 0; }

  /* Section ID */
  .section-id.rounded-id { padding: 7px 20px; font-size: 0.8rem; margin-bottom: 25px; }

  /* Section Title */
  .section-title.mb-60 { margin-bottom: 32px; }
  .section-title.mb-70, .section-title.mb-80 { margin-bottom: 35px; }

  .section-title h2 { padding: 0 1%; }

  .section-title p.p-md, .section-title p.p-lg { margin-top: 12px; }
  .section-title p.p-xl { font-size: 1.125rem; margin-top: 12px; }
  .section-title p.s-20, .section-title p.s-21 { font-size: 1.285714rem; margin-top: 10px; }  /* 18px */
  .section-title p.p-md, .section-title p.p-lg { padding: 0; }

  .section-title .btns-group.mt-35, .section-title .stores-badge.mt-30 { margin-top: 20px; }
  .section-title .btns-group .btn { margin-left: 10px; margin-right: 10px; }  
  .section-title .btns-group .btn:first-child { margin-bottom: 12px; }

  /*------------------------------------------*/
  /*   HERO   
  /*------------------------------------------*/

  .hero-section.blur--purple:after { top: -25%; opacity: .16; }

  .hero-section h2 { margin-bottom: 13px; }
  .hero-section h2.s-48, .hero-section h2.s-50 { margin-bottom: 20px; }
  .hero-section p.p-md, .hero-section p.p-lg, .hero-section p.p-xl,
  .hero-section p.s-20, .hero-section p.s-22, .hero-section p.s-24 { margin-bottom: 23px; }
  .hero-section p.s-21 { font-size: 1.285714rem; margin-bottom: 22px; }
  .hero-section .btn.mt-15, .hero-section .btns-group.mt-15 { margin-top: 8px; }

  /* Hero Advantages */
  .hero-section .text-center .advantages { margin-top: 15px; }
  .hero-section .advantages li { display: block!important; margin-top: 3px; }
  .hero-section .advantages li p { display: block; float: none; }
  .hero-section li.advantages-links-divider { display: none!important; }

  /* HERO LOGO */
  .hero-logo { margin-bottom: 28px; }
  .hero-logo img { max-height: 28px; }

  .hero-square-logo { margin-bottom: 10px; }
  .hero-square-logo img { max-height: 58px; }


  /* hero */
  #hero { padding-top: 0px; margin-bottom: 0px; margin-top: 0;}
  .hero-txt h1 { margin-bottom: 12px; }
  .hero-txt p.p-lg { margin-bottom: 20px; }
  .hero-img { margin-top: 0px; }


  /*------------------------------------------*/
  /*  CONTENT
  /*------------------------------------------*/

  /* ABOUT */
  .about-2-title { margin-bottom: 30px; }
  .a2-txt { padding: 10px 20px 25px; }

  #a3-1 { margin-bottom: 20px; }

  .a2-txt-quote { position: relative; margin-bottom: -35px; }
  .a2-txt-quote.ico-40 [class*="flaticon-"]:before, .a2-txt-quote.ico-40 [class*="flaticon-"]:after { font-size: 4.25rem; }

  .a2-txt-author { margin-top: 10px; }
  .a2-txt-author { font-size: 1.125rem; }
  .a2-txt-author span { font-size: 0.975rem; margin-top: 1px; }

  #about-1 .txt-block h3.mb-30, .about-2-title h2.mb-30 { margin-bottom: 15px; }

  #about-4 .txt-block h2 { padding: 0 5%; }
  #about-4 .txt-block p { margin-top: 12px; }
  #a4-2 { margin: 30px 2% 0; }

  /* FEATURES */
  #features-14 .container { max-width: 100%; }
  .fbox-14-wrapper { padding: 60px 30px; margin: 0 -15px; border-radius: 0 0!important; } 

  #features-12.py-100.shape--bg:after, #features-13.py-100.shape--bg:after { height: 69%; top: 31%; }
  #features-12.pt-100.shape--bg:after, #features-13.pt-100.shape--bg:after { height: 65%; top: 32%; }

  .fb-1, .fb-2, .rows-2 .fb-1, .rows-2 .fb-2, .rows-2 .fb-3, .rows-2 .fb-4, .rows-2 .fb-5, .row-cols-md-2 .fb-3, .fbox-5.fb-3, 
  .fbox-5.fb-5, .rows-2 .fbox-7.fb-1, .rows-2 .fbox-7.fb-2, .rows-2 .fbox-7.fb-3, .rows-2 .fbox-9.fb-1, .rows-2 .fbox-9.fb-2, 
  .rows-2 .fbox-9.fb-3 { margin-bottom: 30px; }

  .rows-3 .fb-3.fbox-11-lst { margin-bottom: 30px; }

  .fbox-5.fb-2, .fbox-5.fb-4, .fbox-5.fb-6 { margin-bottom: 0; }

  .row-cols-md-3 .fbox-4, .row-cols-md-2 .fbox-4, .fbox-5, .fbox-8.bg--white-100 { padding: 35px 25px; } 
  .fbox-7, .fbox-9 { padding: 35px 25px 30px; }
  .row-cols-md-2 .fbox-7 { padding: 35px 35px 30px; }
  .fbox-8.fb-1 { padding: 0 25px 35px; }
  .fbox-8.fb-3 { padding: 40px 25px 0; }
  .fbox-12, .fbox-13 { padding: 30px 25px; } 
  #fb-12-1, #fb-12-2, #fb-12-3, #fb-13-1, #fb-13-2, #fb-13-3 { margin-bottom: 30px; }
  #fb-12-1, #fb-13-1 { margin-top: 50px; }
  #features-12 .txt-block, #features-13 .txt-block { margin-bottom: 30px; }

  .fbox-14 { padding: 30px 25px 25px; } 
  .fbox-14.fb-1, .fbox-14.fb-2 { margin-bottom: 25px; }

  .fbox-img.h-135 img, .fbox-img.h-140 img, .fbox-img.h-145 img, .fbox-img.h-150 img, .fbox-img.h-155 img, 
  .fbox-img.h-160 img, .fbox-img.h-165 img, .fbox-img.h-170 img, .fbox-img.h-175 img, .fbox-img.h-180 img { max-height: 160px; } 

  .fbox-2 .fbox-img.h-135 img, .fbox-2 .fbox-img.h-140 img, .fbox-2 .fbox-img.h-145 img, .fbox-2 .fbox-img.h-150 img, 
  .fbox-2 .fbox-img.h-155 img, .fbox-2 .fbox-img.h-160 img, .fbox-2 .fbox-img.h-165 img, .fbox-2 .fbox-img.h-170 img, 
  .fbox-2 .fbox-img.h-175 img, .fbox-2 .fbox-img.h-180 img { max-height: 160px; }

  .fbox-3 .fbox-img.h-135 img, .fbox-3 .fbox-img.h-140 img, .fbox-3 .fbox-img.h-145 img, .fbox-3 .fbox-img.h-150 img, 
  .fbox-3 .fbox-img.h-155 img, .fbox-3 .fbox-img.h-160 img, .fbox-3 .fbox-img.h-165 img, .fbox-3 .fbox-img.h-170 img, 
  .fbox-3 .fbox-img.h-175 img, .fbox-3 .fbox-img.h-180 img { max-height: 160px; }

  .fbox-4 .fbox-img.h-135 img, .fbox-4.fbox-img.h-140 img, .fbox-4 .fbox-img.h-145 img, .fbox-4 .fbox-img.h-150 img, 
  .fbox-4 .fbox-img.h-155 img, .fbox-4 .fbox-img.h-160 img, .fbox-4 .fbox-img.h-165 img, .fbox-4 .fbox-img.h-170 img, 
  .fbox-4 .fbox-img.h-175 img { max-height: 145px; }

  .fbox-1 .fbox-img, .row-cols-md-2 .fbox-1 .fbox-img, .fbox-4 .fbox-img, .row-cols-md-2 .fbox-4 .fbox-img { margin-bottom: 25px; }

  .fbox-2 .fbox-img.h-135, .fbox-2 .fbox-img.h-140, .fbox-2 .fbox-img.h-145, 
  .fbox-2 .fbox-img.h-150, .fbox-2 .fbox-img.h-155, .fbox-2 .fbox-img.h-160, 
  .fbox-2 .fbox-img.h-165, .fbox-2 .fbox-img.h-170, .fbox-2 .fbox-img.h-175, 
  .fbox-2 .fbox-img.h-180 { padding-top: 35px; margin: 0 0 45px; }

  .fbox-3 .fbox-img.h-135, .fbox-3 .fbox-img.h-140, .fbox-3 .fbox-img.h-145, 
  .fbox-3 .fbox-img.h-150, .fbox-3 .fbox-img.h-155, .fbox-3 .fbox-img.h-160, 
  .fbox-3 .fbox-img.h-165, .fbox-3 .fbox-img.h-170, .fbox-3 .fbox-img.h-175, 
  .fbox-3 .fbox-img.h-180 { padding-top: 35px; margin: 0 0 25px; }

  .fbox-5.fb-1 .fbox-5-img, .fbox-5.fb-2 .fbox-5-img, .fbox-5.fb-3 .fbox-5-img, 
  .fbox-5.fb-4 .fbox-5-img, .fbox-5.fb-5 .fbox-5-img, .fbox-5.fb-6 .fbox-5-img { margin: 0 3% 25px; }

  .fbox-ico, .fbox-ico.ico-rounded, .fbox-ico.ico-square { margin-bottom: 18px; }

  .fbox-wrapper .ico-50 [class*="flaticon-"]:before, 
  .fbox-wrapper .ico-50 [class*="flaticon-"]:after,
  .fbox-wrapper .ico-55 [class*="flaticon-"]:before, 
  .fbox-wrapper .ico-55 [class*="flaticon-"]:after,
  .fbox-wrapper .ico-60 [class*="flaticon-"]:before, 
  .fbox-wrapper .ico-60 [class*="flaticon-"]:after,
  .fbox-10 .fbox-title.ico-35 [class*="flaticon-"]:before, 
  .fbox-10 .fbox-title.ico-35 [class*="flaticon-"]:after { font-size: 3.6rem; }

  .features-section h6.s-18, .features-section h6.s-20, .features-section h6.s-22 { font-size: 1.35714rem; }  /* 19px */
  .features-section h6, .fbox-txt h5, .rows-2 .fbox-11 h6, .fbox-wrapper .row-cols-md-2 h6 { margin-bottom: 12px; }

  .fbox-10 .fbox-title { margin: 0 auto; }
  .fbox-10 .fbox-title span { float: none; line-height: 1!important; margin-right: 0; }
  .fbox-10 .fbox-title h6 { display: block; line-height: 1!important; margin-top: 10px; margin-bottom: 15px; }
  .fbox-14 h5 { padding: 0 5%; margin-bottom: 12px; }

  .row-cols-md-3 .fbox-1 p, .row-cols-md-3 .fbox-2 p, 
  .row-cols-md-3 .fbox-3 p, .row-cols-md-3 .fbox-6 p, .fbox-10 .fbox-txt p { padding: 0 3%; }

  .row-cols-md-2 .fbox-1 p, .row-cols-md-2 .fbox-2 p, .row-cols-md-2 .fbox-3 p, .row-cols-md-2 .fbox-6 p { padding: 0 8%; }
  .row-cols-md-2 .fbox-4 p, .fbox-5 .fbox-txt p, .rows-2 .fbox-11 p { padding: 0 5%; }
  .row-cols-lg-3 .fbox-10 .fbox-txt p { padding: 0; }
  .rows-3 .fbox-11 p { padding: 0 4%; }

  /* CONTENT */
  .ct-06 { padding-bottom: 60px; }
  .ct-10 .section-overlay { margin-bottom: 40px; }
  .ct-top { margin-bottom: 60px; }

  .ct-13 .txt-block, .ct-13 .img-block { margin-bottom: 30px; }
  .ct-13 .txt-block.ml-block, .ct-13 .img-block.ml-block { margin-bottom: 0; }

  /* Content Box Wrapper */
  .content-section.ws-wrapper { margin: 0 -15px; }
  .bc-1-wrapper .section-overlay, .bc-4-wrapper .section-overlay { padding: 60px 32px; }
  #ludo-rank-dao .section-overlay { padding: 15px 32px 40px; }
  #web3-curators-network .bc-1-wrapper .section-overlay { padding: 40px 32px 15px; }
  .bc-2-wrapper .section-overlay, .bc-3-wrapper .section-overlay { padding: 60px 14px; }
  .bc-5-wrapper .section-overlay { padding: 40px 14px 0; }
  .bc-6-wrapper .section-overlay { padding: 60px 14px; } 
  .bc-6 { padding: 20px 15px 25px; }
  .bc-6-top { margin-bottom: 30px; }
  .cbox-6-wrapper { padding: 28px 18px; }

  .bc-1-wrapper, .bc-2-wrapper, .bc-3-wrapper, .bc-4-wrapper, .bc-5-wrapper, .bc-6-wrapper { border-radius: 0 0!important; } 

  .bc-5-wrapper .section-title p { padding: 0 3%; }

  .bc-2-txt h2, .bc-3-txt h2, .bc-4-txt h2, .txt-block h3, .txt-block h2 { margin-bottom: 14px; }
  .txt-block h5 { margin-bottom: 16px; }
  h5.h5-title { margin-top: 16px; margin-bottom: 16px; }
  .cbox-2-txt h5, .cbox-3-txt h5, .bc-6-txt h5 { margin-bottom: 12px; }
  
  .txt-block p, .txt-block p.p-md, .txt-block p.p-lg { margin-bottom: 0.85714rem; }
  .txt-block p.s-20, .txt-block p.s-21 { font-size: 1.14285rem; }
  .cbox-5-fea p, .txt-block p.video-txt-sm, .txt-block p.btn-txt, .txt-block p.btns-group-txt,
  .txt-block p.compatibility { margin-bottom: 0; }
  .txt-block p.video-txt-lg { margin-bottom: 6px; }

  .txt-block .btn { margin-top: 16px; } 
  .txt-block .stores-badge.mt-15, .ws-wrapper .stores-badge.mt-15 { margin-top: 8px; } 
  .txt-block .btns-group .btn.btn--transparent { margin-top: 0; } 
  .bc-6-link.mt-15 { margin-top: 12px; }
  .txt-block-tra-link.mt-25 { margin-top: 16px; }

  /* Content Box */
  .txt-box { margin-bottom: 15px; } 
  .right-column .cbox-5 { margin-bottom: 40px; }
  .cbox-5-content { padding: 25px; margin: 0 1%; }
  .cbox-5-content .divider { margin-top: 22px; margin-bottom: 22px; }

  .txt-block .cbox-5-title h2 { font-size: 2.45rem; margin-bottom: 10px; }
  .cbox-5-title .statistic-number sup { font-size: 2.05rem; top: -5px; right: 6px; }

  .cbox-5-fea { padding: 15px 20px; margin-top: 20px; }
  .txt-block .cbox-5-fea h4 { font-size: 1.625rem; }
  .cbox-5-txt .btn { margin-top: 10px; }

  #cb-6-1, #cb-6-2, #cb-6-3 { margin-bottom: 20px; }

  /* Content Box Icon */
  .cbox-1.ico-10 span { right: 5px; }
  .cbox-1.ico-15 span { top: 0.5px; right: 5px; }
  .cbox-1.ico-15 [class*="flaticon-"]:before, .cbox-1.ico-15 [class*="flaticon-"]:after { font-size: 0.7rem; }

  .cbox-2 .ico-wrap { margin-right: 1rem; }
  .cbox-2-ico { width: 2.15rem; height: 2.15rem; font-size: 1.0625rem; line-height: 1.85rem; }
  .ct-03 .cbox-2-ico { width: 1.65rem; height: 1.65rem; font-size: 1rem; line-height: 1.4rem; }
  .cbox-2-line { height: calc(100% - 70px); }

  .cbox-3 { display: block; text-align: center; }
  .cbox-3 .ico-wrap { margin-right: 0; }
  .cbox-3 .cbox-3-ico { top: 0; margin-bottom: 10px; }
  .cbox-3-ico span[class*="flaticon-"]:before, .cbox-3-ico span[class*="flaticon-"]:after { font-size: 3rem; }

  .cbox-4 { text-align: center; }
  .box-title h6, .box-title h5 { display: block; line-height: 1!important; margin-bottom: 15px; }

  .img-block-txt .box-title h6, 
  .img-block-txt .box-title h5 { display: inline-block; line-height: 2.5rem!important; margin-bottom: 20px; }

  .box-title span { display: block; position: relative; top: 0; margin-right: 0; margin-bottom: 10px; }
  .img-block-txt .box-title span { display: inline-block; top: 10px; margin-right: 10px; margin-bottom: 0; }
  .box-title span[class*="flaticon-"]:before, .box-title span[class*="flaticon-"]:after { font-size: 3rem; }

  .img-block-txt .box-title span[class*="flaticon-"]:before, 
  .img-block-txt .box-title span[class*="flaticon-"]:after { font-size: 2.5rem; }

  /* Content Box Text */ 
  .cbox-2-txt, .cbox-3-txt, .cbox-4-txt { margin-bottom: 25px; }
  .ct-03 .cbox-2-txt { margin-bottom: 20px; }

  /* Content Box Typography */
  .cbox-1-txt p { margin-bottom: 5px; }
  .cbox-6 h6 { margin-bottom: 10px; }

  /* Accordion */
  .txt-block .accordion-1 .accordion-item { padding: 18px 20px; }
  .txt-block .accordion-2 .accordion-item { padding: 10px 0 20px; }

  .txt-block .accordion-1 .accordion-item .accordion-thumb:before { font-size: 0.95rem; top: -1px; }
  .txt-block .accordion-2 .accordion-item .accordion-thumb:after,
  .txt-block .accordion-2 .accordion-item.is-active .accordion-thumb:after { font-size: 0.95rem; top: 7px; }

  .txt-block .accordion-1 .accordion-item.is-active { padding: 25px 20px 9px 20px; }
  .txt-block .accordion-2 .accordion-item.is-active { padding: 15px 0 20px; }

  .txt-block .accordion-1 .accordion-panel { padding: 0; }

  .txt-block .accordion-1 .accordion-thumb h5 { padding-left: 22px; }
  .txt-block .accordion-2 .accordion-thumb h5 { padding-left: 10px; padding-right: 0; }

  .accordion-2 .accordion-thumb span[class*="flaticon-"]:before,
  .accordion-2 .accordion-thumb span[class*="flaticon-"]:after { font-size: 1.75rem; top: 6px; }

  /* Tabs */
  .tabs-1 li { font-size: 1.125rem; } 
  #tabs-1 .tab-content .img-block { margin-bottom: 30px; }

  /* Advantages */
  .advantages, .text-center .advantages { margin-top: 15px; }
  .img-block-btn .advantages li { display: block!important; margin-top: 4px; }
  .img-block-btn .advantages li p { display: block; float: none; }
  .img-block-btn li.advantages-links-divider { display: none!important; }

  /* Compatibility */
  .compatibility { margin-top: 10px; }

  /* Tools List / Payments Icons */
  .tools-list p, .payment-methods p { margin-bottom: 12px; }
  .tools-list span { padding-right: 1px; }
  .payment-icons img { max-height: 26px; }

  .tools-list.ico-35 [class*="flaticon-"]:before, .tools-list.ico-35 [class*="flaticon-"]:after { 
    font-size: 2rem;line-height: 2rem!important; margin-right: 3px; 
  }

  /* Content Box Shapes */
  .cbox-5-shape-1, .cbox-5-shape-2 { display: none; }

  /* Content Images */
  .img-block.img-block-bkg img { padding: 30px 15px; }
  .ct-10 .img-block { margin-bottom: -40px; }
  .ct-01 .img-block, .ct-02 .img-block, .ct-03 .img-block.right-column, .ct-03 .img-block.left-column { margin: 0 2% 30px; }
  .ct-01 .img-block.j-img, .ct-02 .img-block.j-img { margin-left: 0%; margin-right: 0; }
  .ct-04 .img-block, .ct-05 .img-block, .ct-06 .img-block, .ct-07 .img-block- { margin-bottom: 30px; }
  .ct-06 .img-block, .ct-07 .img-block { margin-top: -80px; margin-bottom: 40px; }

  .img-block-hidden .video-btn, .bc-5-img.bc-5-dashboard.img-block-hidden .video-btn { top: 50%; }
  .bc-5-img.bc-5-tablet.img-block-hidden .video-btn { top: calc(50% - 20px); }
  .img-block-txt, .img-block-btn { margin-top: 30px; }

  .bc-1-wrapper .img-block, .bc-2-wrapper .img-block, .bc-2-img, .bc-3-img, .bc-4-img { margin-bottom: 30px; }
  .bc-5-img.bc-5-tablet.img-block-hidden {
    .img-tablet {
      max-width: 100%;
    }
  }
  .bc-6-img { padding: 25px 15px 0; margin-bottom: 20px; }
  .ct-11 .img-block, .ct-12 .img-block { margin-bottom: 30px; padding: 40px 5%; }

  /* STATISTIC */
  .sb-rounded .statistic-block { text-align: center; padding: 25px; }
  #statistic-5 .statistic-digit { padding-bottom: 18px; margin: 0 12% 18px; }
  #sb-1-1, #sb-1-2, #sb-3-1, #sb-3-2, #sb-3-3 { margin-bottom: 30px; }
  #sb-2-1, #sb-2-2, #sb-2-3, #sb-2-4 { width: 50%; }
  #sb-2-3, #sb-2-4 { padding-right: 10px; }
  #sb-4-1, #sb-4-2, #sb-4-3 { margin-bottom: 25px; }
  #sb-5-1, #sb-5-2 { margin-bottom: 30px; }

  .statistic-1-wrapper .statistic-block-digit { margin-bottom: 12px; }

  h2.statistic-number { font-size: 3.5rem; line-height: 1; letter-spacing: -0.5px; }
  .txt-block-stat h2.statistic-number { font-size: 2rem; }
  #statistic-1 h2.statistic-number { letter-spacing: -0.5px; }
  #sb-2-1 h2.statistic-number, #sb-2-2 h2.statistic-number { margin-bottom: 12px; }
  #statistic-5 .statistic-digit h2 { font-size: 2.125rem; letter-spacing: -0.5px; }

  #sb-2-3 .statistic-block p.p-sm, #sb-2-4 .statistic-block p.p-sm { margin-top: 12px; }
  .txt-block-stat .statistic-block p { font-size: 0.95rem; }
  #statistic-5 .statistic-txt p { padding: 0 8%; }

  /* PROJECTS */
  #pt-1-1, #pt-1-2, #pt-1-3, .inner-page-hero #pt-1-3, .inner-page-hero #pt-1-4, .inner-page-hero #pt-1-5 { margin-bottom: 35px; }
  .project-details h5 { margin-bottom: 20px; }
  .more-projects { margin-top: 40px; }

  /* PROJECT DETAILS */
  .single-project .project-title { margin-bottom: 35px; }
  .project-title h2 { padding-right: 0; padding-bottom: 25px; margin-bottom: 25px; }
  .project-data p { margin-bottom: 5px; }

  .project-priview-img.mb-50 { margin-bottom: 30px; }
  .project-inner-img .top-img { margin-bottom: 30px; }
  .project-inner-img.mt-50 { margin-top: 30px; }
  .project-txt h5.mt-35.mb-35 { margin-top: 20px; margin-bottom: 20px; }
  .project-txt h5.mt-50.mb-35 { margin-top: 30px; margin-bottom: 20px; }

  .project-description .more-projects { margin-top: 40px; }
  .project-description .more-projects span { top: 1px; left: 4px; }
  .project-description .more-projects.ico-25 [class*="flaticon-"]:before, 
  .project-description .more-projects.ico-25 [class*="flaticon-"]:after { font-size: 1.125rem; } 

  /* RATING */
  #rb-2-1, #rb-2-2 { margin-bottom: 25px; }

  .rating-title h5 { font-size: 1.125rem; padding: 0 15%; }
  .rbox-1-img { margin-bottom: 15px; }
  .rbox-1-img img { max-height: 30px; }
  .rbox-2-txt img { max-height: 28px; }
  p.rbox-2-txt { line-height: 28px; margin-top: 8px; }

  .rbox-1 .star-rating { padding: 5px 16px; }
  .rbox-1 .star-rating { font-size: 0.9rem; line-height: 1; }

  .rbox-1 .star-rating span { top: 2px; }
  .rbox-1 .ico-10 [class*="flaticon-"]:before, .rbox-1 .ico-10 [class*="flaticon-"]:after { font-size: 0.9rem; } 
  .rbox-1 .ico-15 [class*="flaticon-"]:before, .rbox-1 .ico-15 [class*="flaticon-"]:after { font-size: 1rem; } 
  .rbox-2 .ico-20 [class*="flaticon-"]:before, .rbox-2 .ico-20 [class*="flaticon-"]:after { font-size: 1.225rem; } 
  .rbox-2 .ico-25 [class*="flaticon-"]:before, .rbox-2 .ico-25 [class*="flaticon-"]:after { font-size: 1.225rem; } 

  /* TESTIMONIALS */
  #reviews-4 .txt-block, #reviews-5 .txt-block { text-align: center; }
  #reviews-4 .txt-block h2, #reviews-5 .txt-block h2 { font-size: 1.82142rem; padding: 0 2%; }  /* 25.5px */

  .review-1 { padding: 30px 30px 24px; }
  .review-2 { padding: 35px 15px 24px; }
  .review-3, .review-4, .review-5 { padding: 35px 25px 24px; }

  .review-txt { margin-top: -40px; }
  .review-txt h6 { margin-bottom: 10px; }
  .review-3 .review-txt p { font-size: 1rem; }  

  .review-1 .author-data, .review-2 .author-data, 
  .review-3 .author-data, .review-4 .author-data, .review-5 .author-data { margin-top: 20px; }

  .review-avatar img, .owl-item .review-avatar img { width: 45px; height: 45px; }
  .review-2 .review-avatar img, .review-3 .review-avatar img, .review-5 .review-avatar img { width: 45px; height: 45px; }

  .review-author { padding: 0 0 0 12px; }
  .review-1 .review-author, .review-2 .review-author, .review-3 .review-author, .review-5 .review-author { margin-top: 2px; }

  .review-author h6 { margin-bottom: 8px; }
  .review-1 .review-author h6, .review-2 .review-author h6, .review-3 .review-author h6, 
  .review-4 .review-author h6, .review-5 .review-author h6 { font-size: 1.225rem; }

  /* BRANDS */
  #brands-3:after { height: 85%; }

  .brands-title { padding: 0 3%; } 
  .brands-title.mb-35, .brands-title.mb-40 { margin-bottom: 20px; }
  .brands-title.mb-50 { margin-bottom: 25px; }

  .brands-title h5.s-19 { font-size: 1.21428rem; }
  .brands-title h4, .brands-title h3, .brands-title h2 { margin-bottom: 8px; }
  .brands-title p.p-md, .brands-title p.p-lg { font-size: 1rem; }

  .brands-3-wrapper, .brands-3-wrapper.brands-3-static { padding: 25px 15px; }

  #brand-2-1, #brand-2-2, #brand-2-3, #brand-2-4, #brand-3-1, #brand-3-2, #brand-4-1, #brand-4-2, #brand-4-3, 
  #brand-4-4, #brand-4-5, #brand-4-6, #brand-4-7, #brand-4-8, #brands-4 .brand-logo.mb-40 { margin-bottom: 25px; }
  #brand-3-5 { display: none; }

  #brands-1 .brands-carousel-6 .brand-logo, #brands-1 .brands-carousel-5 .brand-logo { padding: 0 15px; }   
  #brands-1 .justify-content-center .brand-logo, #brands-4 .brand-logo { padding: 0 15px; }   
  #brands-2 .brand-logo, #brands-3 .brand-logo, #brands-3 .brands-3-static .brand-logo { padding: 0 11px; } 

  .brands-section .more-btn { margin-top: 30px; }

  /* FOOTER */
  .footer { padding-bottom: 30px; }
  .footer hr { margin-top: 5px; margin-bottom: 30px; }


}
