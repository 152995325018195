@import '@splidejs/splide/dist/css/splide.min.css';
@import 'normalize.css';
@import 'fonts';
@import 'colors';
@import 'modules';
@import 'animation';
@import 'plate';
@import 'forms';
@import 'landing';
@import "src/styles/mixin/adaptiv-value";
@import "src/styles/variables";

html {
  font-size: 14px;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
  background-color: transparent;
  @media (min-width: $fourKMinPx) {
    @include adaptiv-value(font-size, 28, 14);
  }

  * {
    box-sizing: border-box;
  }
}

#burger {
  box-sizing: border-box;
}

#burger * {
  box-sizing: border-box;
}

body {
  font-family: $mainFont;
  padding: 0;
  margin: 0;
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;
  width: 100vw;
}

.main_page {
  width: 100%;
  overflow-x: hidden;

  #accessLudoBtn {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top: 30vh;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 1000;
    cursor: pointer;
    @media (min-width: $fourKMinPx) {
      button {
        @include adaptiv-value(border-radius, 60, 30);
        @include adaptiv-value(font-size, 36, 18);
        @include adaptiv-value(padding-top, 32, 16);
        @include adaptiv-value(padding-bottom, 32, 16);
        @include adaptiv-value(padding-left, 80, 40);
        @include adaptiv-value(padding-right, 80, 40);

        &:hover {
          @include adaptiv-value-box-shadow(12, 6, 30, 15);
        }
      }
    }
  }
}

.get_early_access_page,
.join_waitlist {
  height: 100vh;
  background-image: url('/public/get_early_access/get_early_access_bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.page_404 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  background: #F3F4F9;

  .notFoundContent {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 140px;

    .notFoundText {
      display: flex;
      flex-direction: column;
      gap: 36px;
    }

    .actions {}

    .notFoundCaption {
      font-weight: 700;
      font-size: 3.8em;
      line-height: 1.37em;
      color: $grayColor2;
      margin-bottom: 0;
    }

    .notFoundIcon {
      width: 337px;
      height: 329px;
    }

    @media screen and (max-width: $tablet + px) {
      flex-direction: column-reverse;
      gap: 40px;

      .notFoundText {
        gap: 32px;
        text-align: center;
      }

      .notFoundIcon {
        width: 286px;
        height: 280px;
      }

      .notFoundCaption {
        font-size: 3em;
        line-height: 1.6em;
      }
    }

    @media screen and (max-width: $mobile + px) {
      justify-content: start;
      margin-top: 100px;
      .notFoundIcon {
        width: 218px;
        height: 212px;
      }
    }
  }
}

//section {
//  width: 100%;
//  height: 100vh;
//  min-height: 600px;
//  //scroll-snap-align: start;
//  box-sizing: border-box;
//  @media (min-width: $fourKMinPx) {
//    @include adaptiv-value(min-height, 1200, 600);
//  }
//}

h1,
h2,
h3,
p {
  margin: 0;
}

::selection {
  color: white;
  background-color: $accentColor;
}

::placeholder {
  color: $grayColor;
}

