@import 'src/styles/colors';
@import 'src/styles/fonts';
@import 'src/styles/media';
@import "src/styles/mixin/adaptiv-value";
@import "src/styles/variables";

.discover {
  background-color: white;
  padding: 140px 0 140px 90px;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  &_img{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -144px;
    z-index: 1;
  }

  &_wrapper {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    height: 100%;

    &_text {
      width: 100%;
      max-width: 516px;

      &--caption {
        @include sectionCaption;
        margin-bottom: 16px;

        strong {
          color: $accentColor;
        }
        .typed-cursor {
          color: $accentColor;
        }
      }
      &--subText {
        @include sectionSubText;
        text-align: justify;
      }
    }
  }

  @media (min-width:  $fourKMinPx) {
    @include adaptiv-value(padding-top, 280, 140);
    @include adaptiv-value(padding-bottom, 280, 140);
    @include adaptiv-value(padding-left, 180, 90);
    @include adaptiv-value(max-width, 2400, 1200);
    &_img{
      @include adaptiv-value(right, -288, -144);
      @include adaptiv-value(max-width, 1690, 845);
      & img{
        width: 100%;
      }
    }

    &_wrapper {

      &_text {
        @include adaptiv-value(max-width, 1032, 516);

        &--caption {
          @include adaptiv-value(margin-bottom, 32, 16);
          @include adaptiv-value(font-size, 48, 24);
          @include adaptiv-value(line-height, 72, 36);
        }
        &--subText {
          @include adaptiv-value(font-size, 30, 15);
          @include adaptiv-value(line-height, 44, 22);
        }
      }
    }
  }


  @media screen and (max-width: $desktop + px) {
    padding: 0 30px;
    min-height: 824px;
    &_img{
      right:unset;
      top:-164px;
      left:50%;
      transform: translateX(-50%);
    }
    &_wrapper {
      justify-content: center;
      box-sizing: border-box;
      padding-top: 384px;

      &_text {
        &--caption {
          text-align: center;
        }
      }
    }
  }

  @media screen and (max-width: $mobile + px) {
    min-height: 584px;
    &_img{
      top:-176px;
    }
    &_wrapper {
      padding-top: 238px;

      &_text {
        &--caption {
          font-size: 22px;
          line-height: 28px;
          margin-bottom: 12px;
        }
      }
    }
  }
}
