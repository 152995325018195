@import 'src/styles/colors';
@import 'src/styles/fonts';
@import 'src/styles/media';
@import "src/styles/mixin/adaptiv-value";
@import "src/styles/variables";

.extension {
  background-color: white;
  padding: 140px 0;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  &_img{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -240px;
    z-index: 1;
  }

  &_wrapper {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    box-sizing: border-box;
    padding-right: 90px;
    @media(max-width: 1200px){
      padding-right: 40px;
    }

    &_text {
      width: 100%;
      max-width: 516px;

      &--caption {
        @include sectionCaption;
        margin-bottom: 16px;
      }
      &--subText {
        @include sectionSubText;
        text-align: justify;
      }
      &--btn {
        margin-top: 40px;
      }
    }
  }

  @media (min-width:  $fourKMinPx) {
    @include adaptiv-value(padding-top, 280, 140);
    @include adaptiv-value(padding-bottom, 280, 140);
    @include adaptiv-value(max-width, 2400, 1200);

    &_img{
      @include adaptiv-value(left, -480, -240);
      @include adaptiv-value(max-width, 1998, 999);
      & img{
        width: 100%;
      }
    }

    &_wrapper {
      @include adaptiv-value(padding-right, 180, 90);


      &_text {
        @include adaptiv-value(max-width, 1032, 516);

        &--caption {
          @include adaptiv-value(margin-bottom, 32, 16);
          @include adaptiv-value(font-size, 48, 24);
          @include adaptiv-value(line-height, 72, 36);
        }
        &--subText {
          @include adaptiv-value(font-size, 30, 15);
          @include adaptiv-value(line-height, 44, 22);
        }
        &--btn {
          @include adaptiv-value(margin-top, 80, 40);
        }
      }
    }
  }


  @media screen and (max-width: $desktop + px) {
    padding: 0 30px;
    min-height: 824px;

    &_img{
      top:-90px;
      left:50%;
      transform: translateX(-50%);
    }
    &_wrapper {
      justify-content: center;
      padding-right: 0;
      padding-top: 384px;

      &_text {
        &--caption {
          text-align: center;
        }
        &--btn {
          text-align: center;
        }
      }
    }
  }
  @media screen and (max-width: $mobile + px) {
    min-height: 584px;

    &_wrapper {
      padding-top: 238px;
      &_text {
        &--caption {
          font-size: 22px;
          line-height: 28px;
          margin-bottom: 12px;
        }
      }
    }
  }
}
