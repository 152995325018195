@import 'src/styles/colors';
@import 'src/styles/fonts';
@import "src/styles/mixin/adaptiv-value";
@import "src/styles/variables";

.button {
  border: 1px solid transparent;
  border-radius: 30px;
  padding: 9px 24px;
  font-weight: 500;
  white-space: nowrap;
  text-decoration: none;
  font-size: 15px;
  line-height: 1.47;
  background: $accentColor;
  color: white;
  filter: none;
  box-shadow: none;
  transition: all 0.2s ease-in 0s;
  user-select: none;
  width: 100%;
  cursor: pointer;
  @media (min-width: $fourKMinPx) {
    @include adaptiv-value(border-radius, 60, 30);
    @include adaptiv-value(padding-top, 18, 9);
    @include adaptiv-value(padding-left, 48, 24);
    @include adaptiv-value(padding-bottom, 18, 9);
    @include adaptiv-value(padding-right, 48, 24);
    @include adaptiv-value(font-size, 30, 15);
  }

  &:hover {
    background: $accentColor;
    color: white;
    border: 1px solid transparent;
    filter: none;
    box-shadow: rgba(0, 0, 0, 0.2) 0 6px 15px 0;
    transition: all 0.2s ease-in-out 0s;
    @media (min-width: $fourKMinPx) {
      @include adaptiv-value-box-shadow(12, 6, 30, 15);
    }
  }
}

.button.big {
  font-size: 18px;
  font-weight: $fontWeightSemiBold;
  padding: 16px 40px;
  @media (min-width: $fourKMinPx) {
    @include adaptiv-value(font-size, 36, 18);
    @include adaptiv-value(padding-top, 32, 16);
    @include adaptiv-value(padding-bottom, 32, 16);
    @include adaptiv-value(padding-left, 80, 40);
    @include adaptiv-value(padding-right, 80, 40);
  }
}

.button.medium {
  font-size: 15px;
  font-weight: $fontWeightMedium;
  padding: 9px 24px;
  @media (min-width: $fourKMinPx) {
    @include adaptiv-value(padding-top, 18, 9);
    @include adaptiv-value(padding-bottom, 18, 9);
    @include adaptiv-value(padding-left, 48, 24);
    @include adaptiv-value(padding-right, 48, 24);
    @include adaptiv-value(font-size, 30, 15);
  }
}

.button.outline {
  background-color: transparent;
  color: $grayColor;

  &:hover {
    box-shadow: none;
    color: $accentColor;
  }
}

.button.link {
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: transparent;
  border: none;
  color: #6C757D;
  padding: 8px 16px;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;

  @media (min-width: $fourKMinPx) {
    @include adaptiv-value(font-size, 32, 16);
    @include adaptiv-value(line-height, 44, 22);
  }

  &:hover {
    box-shadow: none;
    color: $accentColor;
  }

  &:disabled {
    color: #CFD5EA;
  }
}
