@import "src/styles/variables";

@mixin adaptiv-value($property, $maxSize, $minSize) {
  $addSize: $maxSize - $minSize;
  #{$property}: calc(#{$minSize + px} + #{$addSize} * ((100vw - $fourKMinPx) / #{$fourKMax - $fourKMin}));
}

@mixin adaptiv-value-double($property, $maxSizeOne, $minSizeOne, $maxSizeTwo, $minSizeTwo) {
  $addSizeOne: $maxSizeOne - $minSizeOne;
  $addSizeTwo: $maxSizeTwo - $minSizeTwo;
  #{$property}: calc(#{$minSizeOne + px} + #{$addSizeOne} * ((100vw - $fourKMinPx) / #{$fourKMax - $fourKMin})) calc(#{$minSizeTwo + px} + #{$addSizeTwo} * ((100vw - $fourKMinPx) / #{$fourKMax - $fourKMin}));
}

@mixin adaptiv-value-background($url, $maxSizeOne, $minSizeOne, $maxSizeTwo, $minSizeTwo) {
  $addSizeOne: $maxSizeOne - $minSizeOne;
  $addSizeTwo: $maxSizeTwo - $minSizeTwo;
  background: $url calc(#{$minSizeOne + px} + #{$addSizeOne} * ((100vw - $fourKMinPx) / #{$fourKMax - $fourKMin})) calc(#{$minSizeTwo + px} + #{$addSizeTwo} * ((100vw - $fourKMinPx) / #{$fourKMax - $fourKMin}));
}

@mixin adaptiv-value-splide-height($maxSize, $minSize) {
  $addSize: $maxSize - $minSize;
  height: calc(100% - calc(#{$minSize + px} + #{$addSize} * ((100vw - $fourKMinPx) / #{$fourKMax - $fourKMin})));
}

@mixin adaptiv-value-transform-home-after($maxSize, $minSize) {
  $addSize: $maxSize - $minSize;
  transform: translateY(calc(100% - calc(#{$minSize + px} + #{$addSize} * ((100vw - $fourKMinPx) / #{$fourKMax - $fourKMin}))));
}

@mixin adaptiv-value-transform-animate($maxSize, $minSize) {
  $addSize: $maxSize - $minSize;
  transform: translateY(calc(#{$minSize + px} + #{$addSize} * ((100vw - $fourKMinPx) / #{$fourKMax - $fourKMin})));
}

@mixin adaptiv-value-box-shadow( $maxSizeOne, $minSizeOne, $maxSizeTwo, $minSizeTwo) {
  $addSizeOne: $maxSizeOne - $minSizeOne;
  $addSizeTwo: $maxSizeTwo - $minSizeTwo;
  box-shadow: rgba(0, 0, 0, 0.2) 0 calc(#{$minSizeOne + px} + #{$addSizeOne} * ((100vw - $fourKMinPx) / #{$fourKMax - $fourKMin})) calc(#{$minSizeTwo + px} + #{$addSizeTwo} * ((100vw - $fourKMinPx) / #{$fourKMax - $fourKMin})) 0;
}