@import 'src/styles/colors';
@import 'src/styles/fonts';
@import 'src/styles/media';
@import "src/styles/mixin/adaptiv-value";
@import "src/styles/variables";

.blockChainsWithFooter {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: unset;

  &_blockChains {
    background-color: #F7F7FC;
    padding: 60px 8px 100px;

    &--caption {
      text-align: center;
      @include sectionCaption;
      padding-bottom: 53px;
    }

    &_blockchains {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      column-gap: 32px;
      row-gap: 30px;
      max-width: 976px;
      margin: 0 auto;

      &--img {
        width: 80px;
        height: 80px;
      }
    }
  }

  @media (min-width: $fourKMinPx) {

     &_blockChains {
      @include adaptiv-value(padding-top, 120, 120);
      @include adaptiv-value(padding-bottom, 200, 100);

      &--caption {
        @include adaptiv-value(line-height, 72, 36);
        @include adaptiv-value(font-size, 48, 24);
        @include adaptiv-value(padding-bottom, 106, 53);
      }

      &_blockchains {
        @include adaptiv-value(column-gap, 64, 32);
        @include adaptiv-value(row-gap, 60, 30);
        @include adaptiv-value(max-width, 1952, 976);

        &--img {
          @include adaptiv-value(width, 160, 80);
          @include adaptiv-value(height, 160, 80);
        }
      }
    }
  }

  @media screen and (max-width: $desktop + px) {
    &_blockChains {
      &--caption {
        font-size: 22px;
      }

      &_blockchains {
        box-sizing: border-box;
        padding: 0 64px;
        @media screen and (max-width: 767px) {
          padding: 0;
          &--img {
            width: 48px;
            height: 48px;
          }
        }

      }
    }
  }
  @media screen and (max-width: $mobile + px) {
    min-height: 1000px;
  }
}
