@import '/src/styles/colors';
@import '/src/styles/fonts';
@import 'src/styles/media';
@import "src/styles/mixin/adaptiv-value";
@import "src/styles/variables";

.footer {
  padding:  120px 20px 40px;

  &_wrapper {
    margin: 0 auto;
    text-align: center;

    ul {
      list-style: none;
      margin: 0;
      padding-left: 0;
      min-width: 200px;
    }

    &_links {
      display: flex;
      justify-content: space-between;
      gap: 50px;

      &--menu-title {
        margin-bottom: 32px;
        font-size: 20px;
        text-decoration: none;
        color: $blackColor;
        line-height: 110%;
        font-weight: 500;
      }
      &--menu-item {
        margin-bottom: 8px;
      }

      li>a {
        line-height: 150%;
        font-size: 18px;
        font-weight: 500;
        transition: color 0.1s ease-in-out 0s;
        color: rgb(137, 141, 154) !important;
        text-decoration: none;
        &:hover{
          color: $blackColor !important;
        }

      }
    }
    &_social {
      margin-left: auto;
      display: flex;
      gap: 32px;
      align-items: center;
      justify-content: flex-end;
      &--link {
        width: 24px;
        height: 24px;
        text-decoration: none;
        opacity: 0.8;
      }
    }
  }

  &-copyright {
    display: flex;
    gap: 38px;
    &>*:not(:last-child){
      position: relative;
      &:before{
        content: '';
        position: absolute;
        top: 50%;
        right: -18px;
        height: 16px;
        width: 2px;
        background: #6C757D;
        transform: translateY(-50%);
      }
    }
  }
  .bottom-footer-wrapper{
    display: flex;
    align-items: center;
  }

  @media (min-width:  $fourKMinPx) {
    @include adaptiv-value(padding-top, 80, 40);
    @include adaptiv-value(padding-bottom, 80, 40);
    @include adaptiv-value(padding-right, 40, 20);
    @include adaptiv-value(padding-left, 40, 20);

    &_wrapper {

      &_links {
        @include adaptiv-value(gap, 200, 100);

        &--menu-title {
          @include adaptiv-value(font-size, 32, 20);
        }

        li>a {
          @include adaptiv-value(font-size, 28, 18);
        }
      }
      &_social {
        @include adaptiv-value(gap, 64, 32);

        &--link {
          @include adaptiv-value(width, 48, 24);
          @include adaptiv-value(height, 48, 24);

          & img {
            @include adaptiv-value(width, 48, 24);
            @include adaptiv-value(height, 48, 24);
          }
        }
      }
    }
  }

  @media screen and (max-width: $desktop + px) {
    &_wrapper {
      &_links {
        flex-wrap: wrap;
        gap: 24px;
      }
      &_social {
        margin-left: unset;
      }
    }
    .footer_logo_wrapper {
      width: 100%;
      .header_logoM {
        display: none;
      }
      .header_logoD {
        display: block;
        margin-bottom: 30px;
      }
    }
    .bottom-footer-wrapper{
     flex-direction: column;
      gap: 16px;
    }
  }

  @media screen and (max-width: $tablet + px) {
    &_wrapper {
      &_links {
        flex-wrap: wrap;
        gap: 24px;
      }
    }
    .footer_logo_wrapper {
      width: 100%;
      .header_logoM {
        display: none;
      }
      .header_logoD {
        display: block;
        margin-bottom: 30px;
      }
    }
    &-copyright {
      flex-direction: column;
      align-items: center;
      gap: 8px;
      &>*:not(:last-child){
        &:before{
        display: none;
        }
      }
    }
  }

  @media screen and (max-width: $mobile + px) {
    height: auto;
    .bottom-footer {
      margin: 0 !important;
    }
    &-copyright {
      text-align: center;
      margin-bottom: 20px;
    }

    &_wrapper {
      &_links {
        flex-direction: column;
        gap: 14px;

        &--link {
          font-size: 14px;
          display: block;
          font-weight: $fontWeightMedium;
          margin-right: 0;
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      &_social {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        margin: 0 auto 32px auto;
        max-width: 260px;
        justify-items: center;
        row-gap: 16px;
        column-gap: 20px;

        &--link {
          margin-right: 0;
        }
      }
    }
  }
}
