@import '/src/styles/colors';
@import '/src/styles/fonts';

.plate {
  background-color: white;
  border-radius: 8px;
  width: 400px;
  margin: 0 20px;
  padding: 32px 40px;
  box-shadow: 0 16px 32px -16px rgba(33, 21, 95, 0.12);

  &__header {
    text-align: center;
    user-select: none;

    &--caption {
      font-family: $mainFont;
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      font-size: 20px;
      font-weight: $fontWeightSemiBold;
      line-height: 28px;
      color: $blackColor;
      text-decoration: none;

      img {
        margin-top: 8px;
      }
    }
    &--text {
      font-family: $secondaryFont;
      color: $grayColor;
      margin-top: 16px;
      text-align: left;
      font-size: 13px;
      line-height: 18px;
    }
  }
  &__content {
    .form {
      margin-top: 24px;

      &__inviteInput {
        &--label {
          display: none;
        }
        &--error {
          display: block;
          font-family: $secondaryFont;
          margin-top: 8px;
          font-size: 12px;
          color: $redColor;
          line-height: 16px;
          user-select: none;
        }
      }
      &__submit {
        margin-top: 24px;

        &--back_button {
          margin-top: 24px;
        }
      }
    }
  }
  &__footer {
    &--text {
      margin-top: 24px;
      color: $grayColor;
      font-family: $secondaryFont;
      font-weight: $fontWeightRegular;
      font-size: 13px;
      text-align: center;
      line-height: 18px;
      user-select: none;

      a {
        color: $accentColor;
        text-decoration: none;
      }
    }
    &--btn {
      text-align: center;
      margin-top: 17px;

      a {
        font-family: $mainFont;
        color: $grayColor;
        font-weight: $fontWeightMedium;
        font-size: 15px;
        padding: 9px 24px;
        user-select: none;
        text-decoration: none;
      }
    }
  }
}
