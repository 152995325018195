@import 'src/styles/colors';
@import "src/styles/variables";
@import "src/styles/mixin/adaptiv-value";

#hero {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

#hero-card-img {
  width: 650px;
  position: relative;
}

.color-grey2 {
  color: $grayColor2;
}

.txt-block {
  max-width: 651px;
}

.list-group {
  text-align: left;
}

.section-discovery {
  padding: 0 70px;
  &.pl-0 {
    padding-left: 0;
  }
  &.pr-0 {
    padding-right: 0;
  }
  .txt-block {
    max-width: 500px;
  }
}

.brand-card {
  display: inline-flex;
  width: 265px;
  height: 110px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: var(--Surface-Light-Surface-light-primary, #FFF);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.06);
}

.blockchains-wrapper {
  display: flex;
  justify-content: space-between;

  .blockchains {
    justify-content: center;
    column-gap: 24px;
    display: flex;
    flex-wrap: wrap;
    max-width: 476px;
    row-gap: 10px;
    .center-row {
      column-gap: 24px;
      display: flex;
    }
  }

  .end {
    justify-content: center;
  }
}

#web3-curators-network img.mh-240 {
  max-height: 240px;
}

.partners {
  padding-left: 32px;
  padding-right: 32px;
}

.background {
  position: absolute;
  width: 100%;
  height: 100%;

  &.ludo-app-bg {
    border-radius: 621px;
    background: rgba(95, 72, 255, 0.25);
    filter: blur(82px);
  }

  &.ludo-extension-bg {
    border-radius: 594px;
    background: rgba(194, 228, 231, 0.50);
    filter: blur(82px);
  }

  &.ludo-telegram-app-bg {
    border-radius: 621px;
    background: #D7EEE6;
    filter: blur(82px);
  }
}

[class*="ludo-animation"]{
  opacity: 0;
}

.accent-color {
  color: $accentColor
}

.bg--gradient {
  background: linear-gradient(36deg, #4F44E9 25.54%, #28C9F5 84.93%);
}

.bg--gradient-light {
  background: linear-gradient(180deg, rgba(245, 244, 251, 0.10) 0%, #F5F4FB 100%);
}
.bg--gradient-light2 {
  background: linear-gradient(180deg, #F5F4FB 0%, rgba(245, 244, 251, 0.10) 100%);
}

.text-align-justify {
  text-align: justify
}

.text-gradient {
  background: linear-gradient(0deg, #4F44E9 40.6%, #28C9F5 86.33%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.btn-gradient button,
.loginBtn button{
  background: linear-gradient(36deg, #4F44E9 25.54%, #28C9F5 84.93%) !important;
  border: none !important;
  font-weight: 700;
}

.large button{
  //padding: 20px 39px;
  //font-size: 20px;
  //font-style: normal;
  //font-weight: 700;
  //line-height: 24px;
}

.btn-gradient-outline {
  padding: 16px 40px;
  border: none;
  outline: none;
  position: relative;
  z-index: 1;
  border-radius: 50px;
  background: linear-gradient(33deg, #4F44E9 28.43%, #28C9F5 86.96%);
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.33;

  &::before {
    content: "";
    position: absolute;
    left: 2px;
    right: 2px;
    top: 2px;
    bottom: 2px;
    border-radius: 48px;
    background-color: white;
    z-index: -1;
    transition: 0.2s all ease-in-out;
  }

  &::after {
    content: attr(data);
    background: linear-gradient(33deg, #4F44E9 28.43%, #28C9F5 86.96%);
    -webkit-background-clip: text;
    color: transparent;
    transition: 0.2s all ease-in-out;
  }

  &:hover {
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
    transition: 0.2s all ease-in-out;
  }

  @media screen and (max-width: 767px) {
    padding: 14px 32px;
    font-size: 16px;
    line-height: 1.375;
  }
}

.ludo-card {
  background: #f6f4fc;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.06);
}

.btn-code-wrapper{
  display: flex;
  flex-wrap: wrap;
  gap: 10px 28px;
  justify-content: center;
}

// Slider

$slider-width: 536px;
$slider-height: 468px;
$slider-input-width: 6px;

@mixin center() {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-container {
  position: relative;
  //aspect-ratio:  $slider-width / $slider-height;
  //width: 100%;
  width: $slider-width;
  height: $slider-height;
  border-radius: 16px;
  overflow: hidden;

  .img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: $slider-width 100%;
  }

  .background-img {
    background-image: url('/public/images/landing/slider/background.webp');
  }

  .foreground-img {
    background-image: url('/public/images/landing/slider/foreground.webp');
    width: 50%;
  }

  .slider {
    position: absolute;
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 100%;
    background: rgba(#ffffff, 0);
    outline: none;
    margin: 0;
    padding: 0;
    transition: all .2s;
    border: none;
    border-radius: 0;
    @include center;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: $slider-input-width;
      //height: 100%;
      height: $slider-height;
      background: white;
      opacity: 0.2;
      cursor: pointer;
    }

    &::-moz-range-thumb {
      width: $slider-input-width;
      height: $slider-height;
      background: white;
      opacity: 0.2;
      cursor: pointer;
    }
  }

  .slider-button {
    $size: 44px;
    pointer-events: none;
    position: absolute;
    width: $size;
    height: $size;
    border-radius: 50%;
    background-color: #6048ff;
    left: calc(50% - $size / 2 - $slider-input-width / 2);
    top: calc(50% - $size / 2 - $slider-input-width / 2);
    @include center;

    @mixin arrow-helper() {
      content: '';
      padding: 3px;
      display: inline-block;
      border: solid #f2f2f2;
      border-width: 0 2px 2px 0;
    }

    &:after {
      @include arrow-helper();
      transform: rotate(-45deg);
    }

    &:before {
      @include arrow-helper();
      transform: rotate(135deg);
    }
  }

  @media (max-width: 1250px) {
    width: 100%;
  }
}

/*------------------------------------------*/
/*  BACKGROUND IMAGE
/*------------------------------------------*/

.bg--02, .bg--04 {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}


.bg--02 {
  background-image: url(/public/images/landing/bg-02.jpg);
}

.bg--04 {
  background-image: url(/public/images/landing/bg-04.jpg);
}

.w-90{
  width: 90% !important;
}


/* ==========================================================================
  01. GENERAL & BASIC STYLES
  =========================================================================== */

html {
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
}

body {
  font-family: 'Rubik', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #6c757d;
  line-height: 1.6666;
  font-weight: 400;
}

/*------------------------------------------*/
/*  PAGE CONTENT
/*------------------------------------------*/

#page,
.hidd {
  overflow: hidden;
}

.rel,
section,
.container {
  position: relative !important;
  z-index: 3;
}

.section-overlay {
  width: 100%;
  height: 100%;
}

/*------------------------------------------*/
/*  BLOCK SHADOW
/*------------------------------------------*/

.block-shadow {
  -webkit-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08);
  -moz-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08);
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08);
}

/*------------------------------------------*/
/*  BORDER SETTINGS
/*------------------------------------------*/

.block-border {
  border: 1px solid #e7e7e7;
}

.block-border.block-shadow {
  border: 1px solid #f5f6f6;
}

.x-border {
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
}

.top-border {
  border-top: 1px solid #e7e7e7;
}

.bottom-border {
  border-bottom: 1px solid #e7e7e7;
}

/*------------------------------------------*/
/*  BORDER RADIUS SETTING
/*------------------------------------------*/

.r-0 {
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -o-border-radius: 0px;
  border-radius: 0px;
}

.r-02 {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
}

.r-04 {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

.r-06 {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -o-border-radius: 6px;
  border-radius: 6px;
}

.r-08 {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -o-border-radius: 8px;
  border-radius: 8px;
}

.r-10 {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
}

.r-12 {
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -o-border-radius: 12px;
  border-radius: 12px;
}

.r-14 {
  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
  -o-border-radius: 14px;
  border-radius: 14px;
}

.r-16 {
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -o-border-radius: 16px;
  border-radius: 16px;
}

.r-18 {
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  -o-border-radius: 18px;
  border-radius: 18px;
}

.r-20 {
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -o-border-radius: 20px;
  border-radius: 20px;
}

.r-22 {
  -webkit-border-radius: 22px;
  -moz-border-radius: 22px;
  -o-border-radius: 22px;
  border-radius: 22px;
}

.r-24 {
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  -o-border-radius: 24px;
  border-radius: 24px;
}

.r-26 {
  -webkit-border-radius: 26px;
  -moz-border-radius: 26px;
  -o-border-radius: 26px;
  border-radius: 26px;
}

.r-28 {
  -webkit-border-radius: 28px;
  -moz-border-radius: 28px;
  -o-border-radius: 28px;
  border-radius: 28px;
}

.r-30 {
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -o-border-radius: 30px;
  border-radius: 30px;
}

.r-32 {
  -webkit-border-radius: 32px;
  -moz-border-radius: 32px;
  -o-border-radius: 32px;
  border-radius: 32px;
}

.r-34 {
  -webkit-border-radius: 34px;
  -moz-border-radius: 34px;
  -o-border-radius: 34px;
  border-radius: 34px;
}

.r-36 {
  -webkit-border-radius: 36px;
  -moz-border-radius: 36px;
  -o-border-radius: 36px;
  border-radius: 36px;
}

.r-100 {
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -o-border-radius: 100px;
  border-radius: 100px;
}

/*------------------------------------------*/
/*  OPACITY SETTINGS
/*------------------------------------------*/

.o-0 {
  opacity: 0 !important;
}

.o-10 {
  opacity: .1 !important;
}

.o-15 {
  opacity: .15 !important;
}

.o-20 {
  opacity: .2 !important;
}

.o-25 {
  opacity: .25 !important;
}

.o-30 {
  opacity: .3 !important;
}

.o-35 {
  opacity: .35 !important;
}

.o-40 {
  opacity: .4 !important;
}

.o-45 {
  opacity: .45 !important;
}

.o-50 {
  opacity: .5 !important;
}

.o-55 {
  opacity: .55 !important;
}

.o-60 {
  opacity: .60 !important;
}

.o-65 {
  opacity: .65 !important;
}

.o-70 {
  opacity: .7 !important;
}

.o-75 {
  opacity: .75 !important;
}

.o-80 {
  opacity: .8 !important;
}

.o-85 {
  opacity: .85 !important;
}

.o-90 {
  opacity: .9 !important;
}

.o-95 {
  opacity: .95 !important;
}

.o-100 {
  opacity: 1 !important;
}

/*------------------------------------------*/
/*  SPACING & INDENTS
/*------------------------------------------*/

.py-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.py-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.py-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.py-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.py-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.py-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.py-60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.py-70 {
  padding-top: 70px;
  padding-bottom: 70px;
}

.py-80 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.py-90 {
  padding-top: 90px;
  padding-bottom: 90px;
}

.py-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.py-130 {
  padding-top: 130px;
  padding-bottom: 130px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-90 {
  padding-top: 90px;
}

.pt-100 {
  padding-top: 100px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-100 {
  padding-bottom: 100px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-65 {
  margin-top: 65px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-75 {
  margin-top: 75px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-85 {
  margin-top: 85px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-95 {
  margin-top: 95px;
}

.mt-100 {
  margin-top: 100px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-65 {
  margin-bottom: 65px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-85 {
  margin-bottom: 85px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-95 {
  margin-bottom: 95px;
}

.mb-100 {
  margin-bottom: 100px;
}

.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px;
}

/*------------------------------------------*/
/*  BACKGROUND SETTINGS
/*------------------------------------------*/

.bg--fixed,
.bg--scroll {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.bg--fixed {
  background-attachment: scroll !important;
}

.bg--scroll {
  background-attachment: fixed !important;
}

/*------------------------------------------*/
/*  BACKGROUND COLORS
/*------------------------------------------*/

.bg--white-500 {
  background-color: #f2f4f8;
}

.bg--white-400 {
  background-color: #f4f4f9;
}

.bg--white-300 {
  background-color: #f7f8f9;
}

.bg--white-200 {
  background-color: #fbfbfd;
}

.bg--white-100 {
  background-color: #fff;
}

.bg--black-500 {
  background-color: #22223b;
}

.bg--black-400 {
  background-color: #2e3648;
}

.bg--black-300 {
  background-color: #394455;
}

.bg--black-200 {
  background-color: #e1e3e5;
}

.bg--black-100 {
  background-color: #ecf1f4;
}

.bg--blue-500 {
  background-color: #185abd;
}

.bg--blue-400 {
  background-color: #1680fb;
}

.bg--blue-300 {
  background-color: #3eb1ff;
}

.bg--blue-200 {
  background-color: #ddedff;
}

.bg--blue-100 {
  background-color: #f4f9fc;
}

.bg--green-500 {
  background-color: #00a14e;
}

.bg--green-400 {
  background-color: #0fbc49;
}

.bg--green-300 {
  background-color: #6fd792;
}

.bg--green-200 {
  background-color: #cff2db;
}

.bg--green-100 {
  background-color: #e9f8f2;
}

.bg--pink-500 {
  background-color: #ff149c;
}

.bg--pink-400 {
  background-color: #f74780;
}

.bg--pink-300 {
  background-color: #fcb5cc;
}

.bg--pink-200 {
  background-color: #fee4ec;
}

.bg--pink-100 {
  background-color: #feecf2;
}

.bg--purple-5000 {
  background-color: #663ae9;
}

.bg--purple-500 {
  background-color: $accentColor;
}

.bg--purple-400 {
  background-color: #7b5dd6;
}

.bg--purple-300 {
  background-color: #bdaeea;
}

.bg--purple-200 {
  background-color: #e5deff;
}

.bg--purple-100 {
  background-color: #f1edff;
}

.bg--red-500 {
  background-color: #da0f29;
}

.bg--red-400 {
  background-color: #ff1e1a;
}

.bg--red-300 {
  background-color: #ffa5a3;
}

.bg--red-200 {
  background-color: #ffd2d1;
}

.bg--red-100 {
  background-color: #fce7e4;
}

.bg--violet-500 {
  background-color: #c73e9b;
}

.bg--violet-400 {
  background-color: #be6cbe;
}

.bg--violet-300 {
  background-color: #e2bde2;
}

.bg--violet-200 {
  background-color: #f1cde5;
}

.bg--violet-100 {
  background-color: #f7e4f1;
}

.bg--yellow-500 {
  background-color: #ffba01;
}

.bg--yellow-400 {
  background-color: #ffbd45;
}

.bg--yellow-300 {
  background-color: #ffdf35;
}

.bg--yellow-200 {
  background-color: #fef5cd;
}

.bg--yellow-100 {
  background-color: #fef9e5;
}

.bg--tra-black {
  background-color: rgba(0, 0, 0, .04);
}

.bg--tra-white {
  background-color: rgba(255, 255, 255, .15);
}

.bg--primary-blue {
  background-color: #2160fd;
}

.bg--light-grey {
  background-color: #f0f3ff;
}

.bg--snow {
  background-color: #fdfdfd;
}

/*------------------------------------------*/
/*  GRADIENT COLOR BACKGROUND
/*------------------------------------------*/

.gr--ghost {
  background-image: linear-gradient(180deg, rgba(247, 248, 249, .8) 50%, rgba(247, 248, 249, .05) 100%);
}

.gr--snow {
  background-image: linear-gradient(to left, #f3edf7, #f9f6fa);
}

.gr--cotton {
  background-image: linear-gradient(180deg, rgba(223, 225, 250, .8) 50%, rgba(223, 225, 250, .05) 100%);
}

.gr--ghostwhite {
  background-image: linear-gradient(to left, #e9e9f5, #f4f4f9);
}

.gr--smoke {
  background: linear-gradient(180deg, #f8f9fe 0%, #f4f4f9 100%);
}

.gr--whitesmoke {
  background-image: linear-gradient(180deg, rgba(244, 244, 249, .8) 50%, rgba(244, 244, 249, .05) 100%);
}

.gr--perl {
  background-image: linear-gradient(180deg, rgba(236, 234, 253, .8) 50%, rgba(236, 234, 253, .05) 100%);
}


/*------------------------------------------*/
/*  SECTION DIVIDER
/*------------------------------------------*/

hr.divider {
  width: 100%;
  height: 1px;
  background-color: transparent;
  background-image: linear-gradient(90deg, rgba(206, 211, 246, 0) 0, #bbb 38%, #bbb 64%, rgba(206, 211, 246, 0) 99%);
  opacity: .4;
  border: none;
  margin: 0;
}

/*------------------------------------------*/
/*  WAVE SHAPE BOTTOM
/*------------------------------------------*/

.wave-shape-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}


/* ==========================================================================
  02. TYPOGRAPHY
  =========================================================================== */

/*------------------------------------------*/
/*  HEADERS
/*------------------------------------------*/

h1, h2, h3, h4, h5, h6 {
  color: $blackColor;
  font-weight: 600;
  letter-spacing: 0;
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  PARAGRAPHS
/*------------------------------------------*/

p.p-sm {
  font-size: 0.9375rem;
}

/* 15px */
p {
  font-size: 1rem;
}

/* 16px */
p.p-md {
  font-size: 1.0625rem;
}

/* 17px */
p.p-lg {
  //font-size: 1.125rem;
}

/* 18px */
p.p-xl {
  font-size: 1.2rem;
}

/* 19.2px */

/*------------------------------------------*/
/*  FONT SIZE
/*------------------------------------------*/

.s-15 {
  font-size: 0.9375rem;
}

/* 15px */
.s-16 {
  font-size: 1rem;
}

/* 16px */
.s-17 {
  font-size: 1.0625rem;
}

/* 17px */
.s-18 {
  font-size: 1.125rem;
}

/* 18px */
.s-19 {
  font-size: 1.1875rem;
}

/* 19px */
.s-20 {
  font-size: 1.25rem;
}

/* 20px */
.s-21 {
  font-size: 1.3125rem;
}

/* 21px */
.s-22 {
  font-size: 1.375rem;
}

/* 22px */
.s-23 {
  font-size: 1.4375rem;
}

/* 23px */
.s-24 {
  font-size: 1.5rem;
}

/* 24px */
.s-26 {
  font-size: 1.625rem;
}

/* 26px */
.s-28 {
  font-size: 1.75rem;
}

/* 28px */
.s-30 {
  font-size: 1.875rem;
}

/* 30px */
.s-32 {
  font-size: 2rem;
}

/* 32px */
.s-34 {
  font-size: 2.125rem;
}

/* 34px */
.s-36 {
  font-size: 2.25rem;
}

/* 36px */
.s-38 {
  font-size: 2.375rem;
}

/* 38px */
.s-40 {
  font-size: 2.5rem;
}

/* 40px */
.s-42 {
  font-size: 2.625rem;
}

/* 42px */
.s-43 {
  font-size: 2.6875rem;
}

/* 43px */
.s-44 {
  font-size: 2.75rem;
}

/* 44px */
.s-45 {
  font-size: 2.8125rem;
}

/* 45px */
.s-46 {
  font-size: 2.875rem;
}

/* 46px */
.s-47 {
  font-size: 2.9375rem;
}

/* 47px */
.s-48 {
  font-size: 3rem;
}

/* 48px */
.s-50 {
  font-size: 3.125rem;
}

/* 50px */
.s-52 {
  font-size: 3.25rem;
}

/* 52px */
.s-54 {
  font-size: 3.375rem;
}

/* 54px */
.s-56 {
  font-size: 3.5rem;
}

/* 56px */
.s-58 {
  font-size: 3.625rem;
}

/* 58px */
.s-60 {
  font-size: 3.75rem;
}

/* 60px */
.s-62 {
  font-size: 3.875rem;
}

/* 62px */
.s-64 {
  font-size: 4rem;
}

/* 64px */
.s-66 {
  font-size: 4.125rem;
}

/* 66px */
.s-68 {
  font-size: 4.25rem;
}

/* 68px */
.s-70 {
  font-size: 4.375rem;
}

/* 70px */
.s-72 {
  font-size: 4.5rem;
}

/* 72px */
.s-74 {
  font-size: 4.625rem;
}

/* 74px */
.s-76 {
  font-size: 4.75rem;
}

/* 76px */
.s-78 {
  font-size: 4.875rem;
}

/* 78px */
.s-80 {
  font-size: 5rem;
}

/* 80px */

/*------------------------------------------*/
/*  FONT WEIGHT
/*------------------------------------------*/

.txt-upcase {
  text-transform: uppercase
}

.w-200 {
  font-weight: 200;
}

.w-300 {
  font-weight: 300;
}

.w-400 {
  font-weight: 400;
}

.w-500 {
  font-weight: 500;
}

.w-600 {
  font-weight: 600;
}

.w-700 {
  font-weight: 700;
}

.w-800 {
  font-weight: 800;
}

.w-900 {
  font-weight: 900;
}

/*------------------------------------------*/
/*  LINK SETTINGS
/*------------------------------------------*/

a {
  color: #6c757d;
  text-decoration: none;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

a:hover {
  color: #353f4f !important;
  text-decoration: none;
}

a:focus {
  outline: none;
  text-decoration: none;
}


/*------------------------------------------*/
/*   SECTION ID
/*------------------------------------------*/

.section-id {
  display: block;
  font-size: 0.85rem;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin-bottom: 35px;
}

.section-id.rounded-id {
  display: inline-block;
  padding: 10px 24px;
  font-size: 0.85rem;
  text-transform: uppercase;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  margin-bottom: 40px;
}

.section-id.border-id {
  border-width: 2px;
  border-style: solid;
}

.section-id.border-id.border--grey {
  border-color: #757575;
}

.section-id.border-id.border--blue {
  border-color: #1680fb;
}


/*------------------------------------------*/
/*  SECTION TITLE
/*------------------------------------------*/

.section-title {
  text-align: center;
}

.section-title h2 {
  margin-bottom: 16px;
  line-height: 120%; /* 67.2px */
  letter-spacing: -1.12px;
}

.section-title h2.w-700 {
  letter-spacing: -0.5px;
}

.section-title p.p-md,
.section-title p.p-lg,
.section-title p.p-xl,
.section-title p.s-20,
.section-title p.s-21 {
  margin-top: 18px;
  margin-bottom: 0;
}

.section-title p.p-md,
.section-title p.p-lg {
  padding: 0 3%;
}

.section-title a.contact-link {
  font-weight: 500;
  text-decoration: underline;
}

.section-title .btns-group .btn {
  margin-top: 0;
}

.section-title .advantages,
.section-title .btns-txt,
.section-title .btns-group-txt {
  margin-top: 20px;
}

/*------------------------------------------*/
/*  TEXT COLORS
/*------------------------------------------*/

.color--white,
.color--white h1,
.color--white h2,
.color--white h3,
.color--white h4,
.color--white h5,
.color--white h6,
.color--white p,
.color--white a,
.color--white li,
.color--white i,
.color--white span {
  color: #fff;
}

.color--black,
.color--black h1,
.color--black h2,
.color--black h3,
.color--black h4,
.color--black h5,
.color--black h6,
.color--black p,
.color--black a,
.color--black li,
.color--black i,
.color--black span {
  color: #353f4f;
}

.color--grey,
.color--grey h1,
.color--grey h2,
.color--grey h3,
.color--grey h4,
.color--grey h5,
.color--grey h6,
.color--grey p,
.color--grey a,
.color--grey li,
.color--grey i,
.color--grey span,
.color--white .color--grey {
  color: #757575;
}


/*------------------------------------------*/
/*  LOGO IMAGE
/*------------------------------------------*/

.logo-white,
.logo-black {
  display: block;
}

.navbar-light .logo-black,
.navbar-dark .logo-white,
.tra-menu.navbar-light .scroll .logo-white,
.tra-menu.navbar-dark.black-scroll .scroll .logo-black {
  display: none;
}

.tra-menu.navbar-light .scroll .logo-black,
.tra-menu.navbar-dark.black-scroll .scroll .logo-white {
  display: block;
}


/* ==========================================================================
  05. HERO
  ========================================================================== */

.hero-section,
.hero-section .container {
  position: relative;
  z-index: 3;
}

.hero-section.bg--img {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}


/*------------------------------------------*/
/*  HERO LOGO
/*------------------------------------------*/

.hero-logo {
  margin-bottom: 40px;
}

.hero-square-logo {
  margin-bottom: 20px;
}

.hero-logo img {
  width: auto;
  max-width: inherit;
  max-height: 36px;
}

.hero-square-logo img {
  width: auto;
  max-width: inherit;
  max-height: 90px;
}

/*------------------------------------------*/
/*  HERO APP LOGO
/*------------------------------------------*/

.hero-app-logo {
  margin-bottom: 35px;
}

.hero-app-logo img {
  width: auto;
  max-width: inherit;
  max-height: 100px;
}

/*------------------------------------------*/
/*  HERO DIGITS
/*------------------------------------------*/

#hd-1-1, #hd-1-2 {
  display: inline-block;
  float: left;
  padding-right: 20px;
}

#hd-1-1 {
  width: 45%;
}

#hd-1-2 {
  width: 55%;
}

.hero-digits-block {
  display: flex;
  position: relative;
  flex-flow: row wrap;
  align-items: stretch !important;
  justify-content: flex-start;
}

.hero-digits .block-txt {
  overflow: hidden;
  flex: 1;
  max-width: 100%;
  padding-left: 12px;
}

.hero-digits h2.statistic-number {
  line-height: 1;
  margin-bottom: 0;
}

.hero-digits h2.statistic-number span {
  font-size: 1.85rem;
}

.hero-digits .block-txt p.p-sm {
  line-height: 1.35;
  margin-top: 5px;
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  HERO TYPOGRAPHY
/*------------------------------------------*/

.hero-section h2 {
  margin-bottom: 28px;
}

.hero-section h2.s-48,
.hero-section h2.s-50 {
  margin-bottom: 40px;
}

.hero-section h2.s-62 {
  margin-bottom: 24px;
}

.hero-section p.p-sm {
  padding: 0 !important;
}

.hero-section p.p-md {
  margin-bottom: 30px;
}

.hero-section p.p-lg,
.hero-section p.p-xl {
  margin-bottom: 32px;
}

.hero-section p.s-20,
.hero-section p.s-22,
.hero-section p.s-24 {
  margin-bottom: 36px;
}

.hero-section p.p-lg span,
.hero-section p.p-xl span {
  font-weight: 600;
  letter-spacing: -0.35px;
}


/*------------------------------------------*/
/*  hero
/*------------------------------------------*/

#hero .wave-shape-bottom path {
  fill: #fff;
}

#hero {
  background-image: url('/public/home/homeBg.jpg');
  padding-bottom: 50px;

  .container {
    //padding-top: 40px;
  }

  .hero_social {
    margin-top: 122px;
    display: flex;
    gap: 32px;

    a {
      transition: all .4s ease-in-out;
    }

    img {
      height: 24px;
      width: 24px;

      &:hover {
        filter: invert(21%) sepia(40%) saturate(357%) hue-rotate(178deg) brightness(67%) contrast(88%)
      }
    }
  }
}

.hero-txt {
  max-width: 600px;
}

.hero-txt h1 {
  color: var(--Text-color, #12183A);
  //font-size: 60px;
  font-style: normal;
  font-weight: 500;
  line-height: 110%; /* 66px */
  letter-spacing: -1.2px;
  margin-bottom: 32px;
}

.hero-txt p.p-lg {
  margin-bottom: 32px;

  color: #6C757D;
  //font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  .bold {
    font-weight: 600;
  }
}

.hero-img {
  margin: 0 0 -50px;
}


/* ==========================================================================
  07. FEATURES
  ========================================================================== */

#features-12.py-100.shape--bg:after,
#features-12.pt-100.shape--bg:after,
#features-13.py-100.shape--bg:after,
#features-13.pt-100.shape--bg:after {
  position: absolute;
  content: '';
  z-index: -1;
}

#features-12.py-100.shape--bg:after {
  width: 48%;
  height: 64%;
  top: 18%;
  left: 52%;
}

#features-12.pt-100.shape--bg:after {
  width: 48%;
  height: 72%;
  top: 21%;
  left: 52%;
}

#features-13.py-100.shape--bg:after {
  width: 48%;
  height: 64%;
  top: 18%;
}

#features-13.pt-100.shape--bg:after {
  width: 48%;
  height: 72%;
  top: 21%;
}

.rows-2 .fb-1,
.rows-2 .fb-2,
.rows-2 .fb-3 {
  margin-bottom: 50px;
}

.rows-2 .fbox-7.fb-1,
.rows-2 .fbox-7.fb-2,
.rows-2 .fbox-7.fb-3,
.rows-2 .fbox-9.fb-1,
.rows-2 .fbox-9.fb-2,
.rows-2 .fbox-9.fb-3,
.rows-3 .fb-1, .rows-3 .fb-2,
.rows-3 .fb-3, .rows-3 .fb-4 {
  margin-bottom: 40px;
}

.rows-3 .fb-3.fbox-11-lst,
.rows-3 .fb-4.fbox-11-lst {
  margin-bottom: 0;
}

.fbox-14-wrapper {
  padding: 80px 70px;
}

.fbox-14-wrapper .col {
  padding: 0 10px;
}

/*------------------------------------------*/
/*  FEATURE BOX
/*------------------------------------------*/

.row-cols-md-3 .fbox-4 {
  padding: 50px 35px;
}

.row-cols-md-2 .fbox-4 {
  padding: 35px 20px;
}

.fbox-5 {
  padding: 60px;
}

.fbox-7 {
  border: 2px solid #eee;
  padding: 55px 40px 50px;
}

.row-cols-md-2 .fbox-7 {
  padding: 35px 20px 30px;
}

.fbox-8 {
  padding: 55px 40px;
}

.fbox-8.bg--white-100 {
  padding: 60px 40px;
}

.fbox-9 {
  border: 2px solid #eee;
  padding: 40px 40px 35px;
}

.fbox-14 {
  border: 2px solid #eee;
  padding: 40px 30px 35px;
}

/*------------------------------------------*/
/*  FEATURE BOX IMAGE
/*------------------------------------------*/

.fbox-img img {
  width: auto;
  //max-width: inherit;
}

.fbox-img.h-135 img {
  max-height: 135px;
}

.fbox-img.h-140 img {
  max-height: 140px;
}

.fbox-img.h-145 img {
  max-height: 145px;
}

.fbox-img.h-150 img {
  max-height: 150px;
}

.fbox-img.h-155 img {
  max-height: 155px;
}

.fbox-img.h-160 img {
  max-height: 160px;
}

.fbox-img.h-165 img {
  max-height: 165px;
}

.fbox-img.h-170 img {
  max-height: 170px;
}

.fbox-img.h-175 img {
  max-height: 150px !important;
}

.fbox-img.h-180 img {
  max-height: 180px;
}

/*------------------------------------------*/
/*  FBOX-1 IMAGE
/*------------------------------------------*/

.fbox-1 .fbox-img {
  margin-bottom: 35px;
}

.row-cols-md-2 .fbox-1 .fbox-img {
  margin-bottom: 28px;
}

/*------------------------------------------*/
/*  FBOX-2 IMAGE
/*------------------------------------------*/

.fbox-2 .fbox-img {
  -webkit-border-radius: 12px 12px 0 0;
  -moz-border-radius: 12px 12px 0 0;
  -o-border-radius: 12px 12px 0 0;
  border-radius: 12px 12px 0 0;
}

.fbox-2 .fbox-img.h-135, .fbox-2 .fbox-img.h-140, .fbox-2 .fbox-img.h-145,
.fbox-2 .fbox-img.h-150, .fbox-2 .fbox-img.h-155 {
  padding-top: 35px;
  margin-bottom: 60px;
}

.fbox-2 .fbox-img.h-160 {
  padding-top: 35px;
  margin: 0 20px 60px;
}

.fbox-2 .fbox-img.h-165 {
  padding-top: 38px;
  margin: 0 15px 60px;
}

.fbox-2 .fbox-img.h-170 {
  padding-top: 40px;
  margin: 0 10px 60px;
}

.fbox-2 .fbox-img.h-175 {
  align-content: center;
  height: 175px;
  padding-top: 42px;
  margin: 0 8px 60px;
}

.fbox-2 .fbox-img.h-180 {
  padding-top: 44px;
  margin: 0 5px 60px;
}

.fbox-2 .fbox-img img {
  margin-bottom: -25px;
}

/*------------------------------------------*/
/*  FBOX-3 IMAGE
/*------------------------------------------*/

.fbox-3 .fbox-img {
  overflow: hidden;
}

.fbox-3 .fbox-img.h-135 {
  padding-top: 30px;
  margin: 0 0 35px;
}

.fbox-3 .fbox-img.h-160 {
  padding-top: 32px;
  margin: 0 18px 35px;
}

.fbox-3 .fbox-img.h-170 {
  padding-top: 40px;
  margin: 0 10px 35px;
}

.fbox-3 .fbox-img.h-175, .fbox-3 .fbox-img.h-180 {
  padding-top: 40px;
  margin: 0 5px 35px;
}

.row-cols-md-2 .fbox-3 .fbox-img {
  margin-bottom: 28px;
}

/*------------------------------------------*/
/*  FBOX-4 IMAGE
/*------------------------------------------*/

.fbox-4 .fbox-img {
  margin-bottom: 35px;
}

.row-cols-md-2 .fbox-4 .fbox-img {
  margin-bottom: 28px;
}

/*------------------------------------------*/
/*  FBOX-5 IMAGE
/*------------------------------------------*/

.fbox-5.fb-1 .fbox-5-img,
.fbox-5.fb-5 .fbox-5-img,
.fbox-5.fb-6 .fbox-5-img {
  margin: 40px 5% 0;
}

.fbox-5.fb-2 .fbox-5-img,
.fbox-5.fb-3 .fbox-5-img,
.fbox-5.fb-4 .fbox-5-img {
  margin: 0 5% 40px;
}

/*------------------------------------------*/
/*  FEATURE BOX VECTOR ICON
/*------------------------------------------*/

.fbox-ico,
.fbox-ico.ico-rounded {
  margin-bottom: 20px;
}

.fbox-ico.ico-rounded-md,
.fbox-ico.ico-rounded-lg {
  margin-bottom: 25px;
}

.fbox-11 {
  display: flex;
  position: relative;
  flex-flow: row wrap;
  align-items: stretch !important;
  justify-content: flex-start;
}

.fbox-ico-wrap {
  position: relative;
  margin-right: 25px;
}

.rows-2 .fbox-ico-wrap {
  margin-right: 20px;
}

.fbox-11 .fbox-ico {
  margin-top: 4px;
}

/*------------------------------------------*/
/*  FEATURE BOX
/*------------------------------------------*/

.fbox-11 .fbox-txt {
  overflow: hidden;
  flex: 1;
  max-width: 100%;
}

/*------------------------------------------*/
/*  FEATURE BOX TITLE
/*------------------------------------------*/

.fbox-wrapper .row-cols-lg-3 .fbox-title {
  margin-bottom: 10px;
}

.fbox-title span {
  float: left;
  line-height: 1 !important;
  margin-right: 15px;
}

.fbox-title h6 {
  line-height: 2.1875rem !important;
  margin-bottom: 0;
}

.fbox-title.ico-30 h6 {
  line-height: 1.875rem !important;
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  FEATURE BOX TYPOGRAPHY
/*------------------------------------------*/

.fbox-wrapper .row-cols-md-3 h6 {
  margin-bottom: 15px;
}

.rows-2 .fbox-11 h6,
.fbox-wrapper .row-cols-md-2 h6 {
  margin-bottom: 10px;
}

.fbox-wrapper p {
  margin-bottom: 0;
}

.fbox-5 h5 {
  margin-bottom: 15px;
}

.fbox-14 h5 {
  margin-bottom: 15px;
}

/*------------------------------------------*/
/*  FEATURE-12
/*------------------------------------------*/

.fbox-12-wrapper {
  padding-left: 45px;
}

.fbox-13-wrapper {
  padding-right: 45px;
}

.fbox-12,
.fbox-13 {
  padding: 32px 35px;
}

.fbox-12 h5,
.fbox-13 h5 {
  margin-top: 20px;
  margin-bottom: 10px;
}

.fbox-12 p,
.fbox-13 p,
.fbox-14 p {
  margin-bottom: 0;
}


/* ==========================================================================
  08. CONTENT
  ========================================================================== */

.ct-06 {
  margin-bottom: 80px;
}

.ct-07 {
  margin-top: 80px;
}

.ct-10 .section-overlay {
  margin-bottom: 100px;
}

.ct-11,
.ct-12 {
  padding: 60px 0;
}

.ct-11:after {
  position: absolute;
  content: '';
  z-index: -1;
  width: 50%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
}

.ct-12:after {
  position: absolute;
  content: '';
  z-index: -1;
  width: 50%;
  height: 100%;
  top: 0;
  left: 50%;
  background-color: #fff;
}

/*------------------------------------------*/
/*  CONTENT BOX WRAPPER
/*------------------------------------------*/

.bc-5-wrapper .section-overlay {
  padding: 66px 50px 0;
}

.bc-5-wrapper .section-overlay-1 {
  padding: 66px 50px 10px;
}

.bc-5-wrapper .section-overlay-2 {
  padding: 10px 50px 32px;
}

.cbox-6-wrapper {
  padding: 60px 40px;
}

.content-section .cbox-6-wrapper {
  padding: 80px 70px;
}

.bc-5-wrapper .section-title p {
  padding: 0;
}

/*------------------------------------------*/
/*  TEXT BLOCK
/*------------------------------------------*/

.txt-box {
  margin-bottom: 20px;
}

.txt-box:last-child {
  margin-bottom: 0;
}

.bc-3-txt,
.ct-06 .txt-block,
.bc-2-wrapper .txt-block {
  margin-top: -40px;
}

.bc-2-txt,
.ct-07 .txt-block {
  margin-top: 40px;
}

.bc-1-wrapper .txt-block.pr-25.left-column,
.bc-2-wrapper .txt-block.pr-25.left-column {
  padding-right: 25px;
}

.bc-1-wrapper .txt-block.pl-25.right-column,
.bc-2-wrapper .txt-block.pl-25.right-column {
  padding-left: 25px;
}

.ct-11 .txt-block {
  padding-right: 50px;
}

.ct-12 .txt-block {
  padding-left: 70px;
}

.ct-13 .txt-block h5 {
  padding-right: 6%;
}

/*------------------------------------------*/
/*  TEXT BLOCK TYPOGRAPHY
/*------------------------------------------*/

.bc-2-txt h2,
.bc-3-txt h2,
.bc-4-txt h2,
.txt-block h3,
.txt-block h2 {
  margin-bottom: 26px;
}

.txt-block h5 {
  margin-bottom: 20px;
}

h5.h5-title {
  margin-top: 20px;
  margin-bottom: 20px;
}

.txt-block .btn,
.txt-block .btns-group .btn.btn--transparent {
  margin-top: 25px;
}

.txt-block .btn.btn--transparent {
  margin-top: 0;
}

/*------------------------------------------*/
/*  CONTENT BOX
/*------------------------------------------*/

.cbox-2 {
  display: flex;
  position: relative;
  flex-flow: row wrap;
  align-items: stretch !important;
  justify-content: flex-start;
}


/*------------------------------------------*/
/*  CONTENT BOX TITLE
/*------------------------------------------*/

.box-title h6,
.box-title h5 {
  display: inline-block;
  line-height: 2.35rem !important;
  margin-bottom: 15px;
}

.box-title span {
  position: relative;
  top: 12px;
  margin-right: 10px;
}

.box-title span[class*="flaticon-"]:before,
.box-title span[class*="flaticon-"]:after {
  font-size: 2.35rem;
}


/*------------------------------------------*/
/*  CBOX #2
/*------------------------------------------*/

.cbox-2 .ico-wrap {
  position: relative;
  margin-right: 1.625rem;
}

.cbox-2-ico {
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  width: 48px;
  height: 48px;
  text-align: center;
  align-content: center;
  border-radius: 100%;
  font-size: 22px;
  font-weight: 500;
  line-height: 2.375rem;
  //border: 2px solid transparent;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
}

.ct-03 .cbox-2-ico {
  position: relative;
  width: 2.35rem;
  height: 2.35rem;
  font-size: 1.15rem;
  line-height: 2.15rem;
}

.cbox-2-line {
  position: absolute;
  bottom: 20px;
  left: 50%;
  width: 2px;
  background-color: #e4e4e4;
  height: calc(100% - 90px);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.cbox-2:hover .cbox-2-ico {
  background-color: transparent;
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}


/*------------------------------------------*/
/*  CONTENT BOX TEXT
/*------------------------------------------*/

.cbox-2-txt {
  overflow: hidden;
  flex: 1;
  max-width: 100%;
}

.cbox-2-txt {
  margin-bottom: 35px;
}

.ct-03 .cbox-2-txt {
  margin-bottom: 30px;
}

.cbox-2:last-child .cbox-2-txt {
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  CONTENT BOX TYPOGRAPHY
/*------------------------------------------*/

.cbox-2-txt h5 {
  margin-bottom: 15px;
  //padding-top: 10px;
  letter-spacing: -0.52px
}

.ct-03 .cbox-2-txt h5 {
  margin-bottom: 10px;
}


.cbox-2-txt p {
  margin-bottom: 0;
}


/*------------------------------------------*/
/*  IMG BLOCK IMAGE
/*------------------------------------------*/

.grey-img {
  filter: grayscale(100%);
}

.img-block {
  text-align: center;
}

.img-block.img-block-bkg {
  overflow: hidden;
}

.img-block.img-block-bkg img {
  padding: 60px 45px;
}

.ct-10 .img-block,
.bc-5-img.bc-5-dashboard.img-block-hidden {
  margin-bottom: -100px;
}


.bc-5-img.bc-5-tablet.img-block-hidden {
  text-align: center;

  .img-tablet {
    //max-width: 1100px;
  }
}

.img-block-hidden .video-btn,
.bc-5-img.bc-5-dashboard.img-block-hidden .video-btn {
  top: calc(50% - 30px);
}

.bc-5-img.bc-5-tablet.img-block-hidden .video-btn {
  top: calc(50% - 70px);
}

.ct-01 .img-block,
.ct-04 .img-block {
  margin-left: 30px;
}

.ct-02 .img-block {
  margin-right: 30px;
}

.ct-03 .img-block.right-column {
  margin-left: 20px;
}

.ct-03 .img-block.left-column {
  margin-right: 20px;
}

.ct-04 .img-block img {
  max-width: none;
  display: inline-block;
}

.ct-06 .img-block {
  margin-bottom: -80px;
}

.ct-07 .img-block {
  margin-top: -80px;
}

.img-block-txt,
.img-block-btn {
  margin-top: 60px;
}

.bc-1-wrapper .img-block.left-column,
.bc-2-wrapper .img-block.left-column {
  padding-right: 10px;
}

.bc-1-wrapper .img-block.right-column,
.bc-2-wrapper .img-block.right-column {
  padding-left: 10px;
}

.bc-2-img {
  margin-left: 15px;
}

.bc-3-img {
  margin: 0 -65% -25px 15px;
}

.bc-4-img {
  margin-left: 15px;
  margin-right: -65%;
}

.bc-6-img {
  padding: 50px 30px 0;
  margin-bottom: 35px;
}

.ct-11 .img-block {
  position: relative;
  text-align: center;
  padding-left: 70px;
  z-index: 2;
}

.ct-12 .img-block {
  position: relative;
  text-align: center;
  padding-right: 70px;
  z-index: 2;
}

.ct-11 .img-block .tra-bkg,
.ct-12 .img-block .tra-bkg {
  opacity: 0;
  -moz-opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}


/* ==========================================================================
  09. STATISTIC
  =========================================================================== */

.sb-rounded .statistic-block {
  padding: 35px 25px;
}

/*------------------------------------------*/
/*  STATISTIC BLOCK
/*------------------------------------------*/

#sb-2-1,
#sb-2-2 {
  width: 48%;
  display: inline-block;
  float: left;
}

#sb-2-3, #sb-2-4 {
  display: inline-block;
  float: left;
  padding-right: 15px;
}

#sb-2-3 {
  width: 45%;
}

#sb-2-4 {
  width: 55%;
}

#sb-2-3 .statistic-block,
#sb-2-4 .statistic-block {
  display: flex;
  position: relative;
  flex-flow: row wrap;
  align-items: stretch !important;
  justify-content: flex-start;
}

#sb-2-3 .statistic-block-txt,
#sb-2-4 .statistic-block-txt {
  overflow: hidden;
  flex: 1;
  max-width: 100%;
  padding-left: 15px;
}

/*------------------------------------------*/
/*  STATISTIC BLOCK TYPOGRAPHY
/*------------------------------------------*/
.statistic-block {
  display: flex;
  gap: 10px;
  align-items: center;
}

.statistic-1-wrapper .statistic-block-digit {
  display: inline-block !important;
  float: left;
}

.statistic-1-wrapper .statistic-block-txt {
  width: 190px;
  display: inline-block !important;
}

#statistic-5 .statistic-digit {
  padding-bottom: 20px;
  margin: 0 100px 20px 0;
  border-bottom: 1px solid #999;
}

#statistic-5 .color--white .statistic-digit {
  border-bottom: 1px solid rgba(255, 255, 255, .8);
}

/*------------------------------------------*/
/*  STATISTIC BLOCK TYPOGRAPHY
/*------------------------------------------*/

.statistic-ico {
  margin-bottom: 10px;
}

h2.statistic-number {
  font-size: 60px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 72px */
  letter-spacing: -1.2px;
  color: $grayColor;
}

#sb-2-1 h2.statistic-number,
#sb-2-2 h2.statistic-number {
  margin-bottom: 15px;
}

#sb-2-3 h2.statistic-number span,
#sb-2-4 h2.statistic-number span {
  font-size: 2rem;
}

#statistic-5 .statistic-digit h2 {
  letter-spacing: -1.5px;
}

#statistic-5 .statistic-txt h5 {
  line-height: 1;
  margin-bottom: 12px;
}

.statistic-block-txt p.p-md {
  line-height: 1.35;
  margin-bottom: 0;
}

#sb-2-1 .statistic-block p.p-sm,
#sb-2-2 .statistic-block p.p-sm {
  line-height: 1.4;
  margin-bottom: 0;
}

#sb-2-3 .statistic-block p.p-sm,
#sb-2-4 .statistic-block p.p-sm {
  margin-top: 3px;
  line-height: 1.5;
  margin-bottom: 0;
}

.statistic-3-wrapper p {
  line-height: 1;
  margin-top: 15px;
  margin-bottom: 0;
}

.statistic-3-wrapper p.p-md {
  line-height: 1;
  margin-top: 22px;
  margin-bottom: 0;
}

.statistic-4-wrapper p {
  line-height: 1;
  margin-bottom: 14px;
}

#statistic-5 .statistic-txt p {
  padding-right: 3%;
  margin-bottom: 0;
}

.txt-block-stat .statistic-block .txt-block-rating {
  margin-top: -4px;
  margin-bottom: -3px;
}

.txt-block-stat .ico-15 [class*="flaticon-"]:before,
.txt-block-stat .ico-15 [class*="flaticon-"]:after {
  font-size: 0.8125rem;
}


/* ==========================================================================
  15. BRANDS
  =========================================================================== */

#brands-3 {
  position: relative;
}

#brands-3:after {
  position: absolute;
  content: '';
  width: 100%;
  height: 80%;
  top: 0;
  left: 0;
  z-index: -1;
}

/*------------------------------------------*/
/*  BRANDS SECTION TITLE
/*------------------------------------------*/

.brands-title {
  text-align: center;
  padding: 0 5%;
}

.brands-title h4,
.brands-title h3,
.brands-title h2 {
  margin-bottom: 8px;
}

.brands-title p {
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  BRANDS LOGO WRAPPER
/*------------------------------------------*/

.brands-3-wrapper {
  padding: 50px 30px;
  background-color: #fff;
  border: 1px solid #e9ecef;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

/*------------------------------------------*/
/*  BRANDS LOGO IMAGE
/*------------------------------------------*/

.brand-logo {
  overflow: hidden;
  position: relative;
  top: 0;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

#brands-1 .brands-carousel-5 .brand-logo {
  padding: 0 30px;
}

#brands-1 .brands-carousel-6 .brand-logo {
  padding: 0 20px;
}

#brands-1 .justify-content-center .brand-logo {
  padding: 0 15px;
}

#brands-2 .brand-logo {
  padding: 0;
}

#brands-3 .brand-logo {
  padding: 0 25px;
}

#brands-3 .brands-3-static .brand-logo {
  padding: 0 15px;
}

#brands-4 .brand-logo {
  padding: 0 20px;
}

.brand-logo:hover {
  top: -6px;
}

.brands-section .more-btn {
  text-align: center;
  margin-top: 40px;
}

.hero-section .owl-dots,
.brands-section .owl-dots {
  display: none;
}


/* ==========================================================================
  29. FOOTER
  =========================================================================== */

.footer {
  padding-bottom: 50px;
}

.footer-info,
.footer-links {
  margin-bottom: 40px;
}

#footer-10 .footer-info {
  margin-bottom: 40px;
  padding-bottom: 55px;
  border-bottom: 1px solid #dbdddf;
}

#footer-10 .footer-links {
  margin-bottom: 30px;
}

/*------------------------------------------*/
/*  FOOTER LOGO
/*------------------------------------------*/

img.footer-logo,
img.footer-logo-dark {
  width: auto;
  max-width: inherit;
  max-height: 40px;
}

#footer-10 img.footer-logo {
  max-height: 46px;
}

#footer-1 .footer-logo,
#footer-5 .footer-logo,
#footer-6 .footer-logo,
#footer-7 .footer-logo {
  margin-bottom: 25px;
}

/*------------------------------------------*/
/*  FOOTER TYPOGRAPHY
/*------------------------------------------*/

.footer h6 {
  line-height: 1;
  margin-bottom: 25px;
}

#footer-9.footer h6 {
  line-height: 1.5;
}

.bg--black.footer h6 {
  color: #fff;
}


/*------------------------------------------*/
/*  BOTTOM FOOTER
/*------------------------------------------*/

.footer hr {
  margin-top: 30px;
  margin-bottom: 50px;
}

#footer-8 .bottom-footer,
#footer-9 .bottom-footer {
  margin-top: 20px;
}

/*------------------------------------------*/
/*  BOTTOM FOOTER COPYRIGHT
/*------------------------------------------*/

.footer-copyright p {
  margin-bottom: 0;
}

.bg--black.footer .footer-copyright p {
  color: #a0a7b1;
}

/*------------------------------------------*/
/*  BOTTOM FOOTER SECONDARY LINK
/*------------------------------------------*/

.bottom-secondary-link p {
  margin-bottom: 0;
}

.bottom-secondary-link p span {
  position: relative;
  top: 1px;
  padding: 0 2px;
}

.bottom-secondary-link.ico-15 [class*="flaticon-"]:before,
.bottom-secondary-link.ico-15 [class*="flaticon-"]:after {
  font-size: 0.75rem;
}

/*------------------------------------------*/
/*  BOTTOM FOOTER LINKS
/*------------------------------------------*/

.bottom-footer-list li,
.bottom-footer-socials li,
.bottom-footer-payment li {
  width: auto !important;
  display: inline-block !important;
  vertical-align: top;
  clear: none !important;
}

.bottom-footer-socials li {
  padding-left: 14px;
}

#footer-4 .bottom-footer-list li,
#footer-9 .bottom-footer-list li {
  padding-left: 15px;
}

#footer-4 .bottom-footer-list li.first-li,
#footer-9 .bottom-footer-list li.first-li {
  padding-left: 0;
}

#footer-10 .bottom-footer-socials li {
  padding: 0 15px;
}

.footer-list-divider {
  position: relative;
  top: -0.5px;
  transform: rotate(-90deg);
  margin-left: 4px;
}

.bottom-footer-list.ico-15 [class*="flaticon-"]:before,
.bottom-footer-list.ico-15 [class*="flaticon-"]:after {
  font-size: 0.8rem;
}

.bottom-footer-list li p {
  display: inline-block;
  float: left;
  margin-bottom: 0;
}

.bottom-footer-socials li span {
  position: relative;
  top: 4px;
  opacity: .8;
}

.bottom-footer-payment li span {
  line-height: 1 !important;
  position: relative;
  top: 4px;
  opacity: .8;
}

#footer-4 .bottom-footer-list li span,
#footer-9 .bottom-footer-list li span {
  margin-top: 1px;
  margin-right: 2px;
}

.bottom-footer-socials.ico-20 [class*="flaticon-"]:before,
.bottom-footer-socials.ico-20 [class*="flaticon-"]:after {
  font-size: 1.115rem;
}

.bottom-footer-socials.ico-25 [class*="flaticon-"]:before,
.bottom-footer-socials.ico-25 [class*="flaticon-"]:after {
  font-size: 1.25rem;
}

.bg--black.footer .bottom-footer-list li p a {
  color: #a0a7b1;
}

.bg--black.footer .bottom-footer-list li p a:hover {
  color: #fff !important;
}


/* ==========================================================================
  34. COLOR SCHEME
  ========================================================================== */

.bg--theme,
.shape--blue-400:after {
  background-color: $accentColor;
}

.btn--theme {
  color: #fff !important;
  border-color: $accentColor !important;
  background-color: $accentColor !important;
}

.hover--theme:hover,
.white-scroll .scroll .hover--theme:hover,
.black-scroll .scroll .hover--theme:hover {
  color: #fff !important;
  border-color: $accentColor !important;
  background-color: $accentColor !important;
}

.theme--dark .white-scroll .scroll .hover--theme:hover {
  color: #fff !important;
  border-color: $accentColor !important;
  background-color: $accentColor !important;
}

.theme--dark .white-scroll .hover--theme:hover {
  color: #fff !important;
  border-color: $accentColor !important;
  background-color: $accentColor !important;
}

.color--theme,
.color--theme h1,
.color--theme h2,
.color--theme h3,
.color--theme h4,
.color--theme h5,
.color--theme h6,
.color--theme p,
.color--theme a,
.color--theme li,
.color--theme i,
.color--theme span,
.color--white .color--theme {
  color: $accentColor !important;
}

.loading--theme .loader {
  border-color: transparent $accentColor;
}

.loading--theme .loader::after {
  border-color: transparent rgba(123, 93, 214, .15);
}

.nav-theme .sm-info {
  background-color: $accentColor;
}

.wsmenu > .wsmenu-list.nav-theme > li > ul.sub-menu > li > a:hover,
.wsmenu > .wsmenu-list.nav-theme > li > .wsmegamenu.w-75 ul.link-list > li > a:hover,
.wsmenu > .wsmenu-list.nav-theme > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover {
  color: $accentColor !important;
}

.hero-section .quick-form .form-control:focus {
  border-color: $accentColor;
}

.cbox-2:hover .cbox-2-ico.bg--theme {
  color: $accentColor;
  border-color: $accentColor;
}

.tabs-1.tabs--theme,
.tabs-1.tabs--theme li.current:hover {
  color: $accentColor;
  border-color: $accentColor;
}

.faqs-section .tabs--theme .tabs-1 li.current,
.faqs-section .tabs--theme .tabs-1 li.current:hover {
  background-color: $accentColor;
  border-color: $accentColor !important;
}

.switch-wrap input:checked + .switcher.switcher--theme {
  background-color: $accentColor;
  border-color: $accentColor;
}

.shape-ico.color--theme path,
.shape-ico.color--theme path,
.shape-ico.color--theme path {
  fill: #f1edff;
}

.newsletter-section .form-control:focus {
  background-color: #fff;
  border-color: #353f4f;
}

.theme-pagination .page-item.active .page-link {
  color: #fff !important;
  background-color: $accentColor;
  border: 2px solid $accentColor;
}
