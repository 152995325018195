@import '/src/styles/colors';
@import '/src/styles/media';
@import '/src/styles/fonts';

@import "src/styles/mixin/adaptiv-value";
@import "src/styles/variables";

$offsetsBorderD: 0;
$offsetsBorderT: 0;
$offsetsBorderM: 0;
$offsetsBetweenImagesD: 2.5%;
$offsetFromBottomD: 4;
$imageWidthD: 192;
$imageWidthT: 208;
$imageWidthM: 100;

@mixin slideBgs($slidePosition) {
  #splideMainSlider-slide0#{$slidePosition} {

    .bg-1 {
      background: url('/public/home/slide#{$slidePosition}/desktop.webp') -2px -458px;
      background-size: 196px 1240px;
    }

    .bg-2 {
      background: url('/public/home/slide#{$slidePosition}/desktop.webp') -2px -146px;
      background-size: 196px 1240px;
    }

    .bg-3 {
      background: url('/public/home/slide#{$slidePosition}/desktop.webp') -2px -654px;
      background-size: 196px 1240px;
    }

    .bg-4 {
      background: url('/public/home/slide#{$slidePosition}/desktop.webp') -2px -2px;
      background-size: 196px 1240px;
    }

    .bg-5 {
      background: url('/public/home/slide#{$slidePosition}/desktop.webp') -2px -850px;
      background-size: 196px 1240px;
    }

    .bg-6 {
      background: url('/public/home/slide#{$slidePosition}/desktop.webp') -2px -1046px;
      background-size: 196px 1240px;
    }

    .bg-7 {
      background: url('/public/home/slide#{$slidePosition}/desktop.webp') -2px -302px;
      background-size: 196px 1240px;
    }

    @media (min-width: $fourKMinPx) {
      .bg-1 {
        @include adaptiv-value-background(url('/public/home/slide#{$slidePosition}/four_k.webp'), -4, -2, -916, -458);
        @include adaptiv-value-double('background-size', 392, 196, 2480, 1240)
      }

      .bg-2 {
        @include adaptiv-value-background(url('/public/home/slide#{$slidePosition}/four_k.webp'), -4, -2, -292, -146);
        @include adaptiv-value-double('background-size', 392, 196, 2480, 1240)
      }

      .bg-3 {
        @include adaptiv-value-background(url('/public/home/slide#{$slidePosition}/four_k.webp'), -4, -2, -1308, -654);
        @include adaptiv-value-double('background-size', 392, 196, 2480, 1240)
      }

      .bg-4 {
        @include adaptiv-value-background(url('/public/home/slide#{$slidePosition}/four_k.webp'), -4, -2, -4, -2);
        @include adaptiv-value-double('background-size', 392, 196, 2480, 1240)
      }

      .bg-5 {
        @include adaptiv-value-background(url('/public/home/slide#{$slidePosition}/four_k.webp'), -4, -2, -1700, -850);
        @include adaptiv-value-double('background-size', 392, 196, 2480, 1240)
      }

      .bg-6 {
        @include adaptiv-value-background(url('/public/home/slide#{$slidePosition}/four_k.webp'), -4, -2, -2092, -1046);
        @include adaptiv-value-double('background-size', 392, 196, 2480, 1240)
      }

      .bg-7 {
        @include adaptiv-value-background(url('/public/home/slide#{$slidePosition}/four_k.webp'), -4, -2, -604, -302);
        @include adaptiv-value-double('background-size', 392, 196, 2480, 1240)
      }
    }


    @media screen and (max-width: $desktop + px) {
      .bg-1 {
        background: url('/public/home/slide#{$slidePosition}/tablet.webp') -2px -422px;
        background-size: 212px 1056px;
      }
      .bg-2 {
        background: url('/public/home/slide#{$slidePosition}/tablet.webp') -2px -102px;
        background-size: 212px 1056px;
      }
      .bg-3 {
        background: url('/public/home/slide#{$slidePosition}/tablet.webp') -2px -634px;
        background-size: 212px 1056px;
      }
      .bg-4 {
        background: url('/public/home/slide#{$slidePosition}/tablet.webp') -2px -2px;
        background-size: 212px 1056px;
      }
      .bg-5 {
        background: url('/public/home/slide#{$slidePosition}/tablet.webp') -2px -846px;
        background-size: 212px 1056px;
      }
      .bg-6 {
        background: url('/public/home/slide#{$slidePosition}/tablet.webp') -2px -262px;
        background-size: 212px 1056px;
      }
    }

    @media screen and (max-width: ($tablet + 1) + px) {
      .bg-1 {
        background: url('/public/home/slide#{$slidePosition}/mobile.webp') -2px -260px;
        background-size: 104px 570px;
      }
      .bg-2 {
        background: url('/public/home/slide#{$slidePosition}/mobile.webp') -2px -72px;
        background-size: 104px 570px;
      }
      .bg-3 {
        background: url('/public/home/slide#{$slidePosition}/mobile.webp') -2px -364px;
        background-size: 104px 570px;
      }
      .bg-4 {
        background: url('/public/home/slide#{$slidePosition}/mobile.webp') -2px -2px;
        background-size: 104px 570px;
      }
      .bg-5 {
        background: url('/public/home/slide#{$slidePosition}/mobile.webp') -2px -468px;
        background-size: 104px 570px;
      }
      .bg-6 {
        background: url('/public/home/slide#{$slidePosition}/mobile.webp') -2px -166px;
        background-size: 104px 570px;
      }
    }
  }
}

.home {
  background-image: url('/public/home/homeBg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  user-select: none;

  @media screen and (max-width: $desktop + px) {
    min-height: 760px;
  }
  @media screen and (max-width: ($tablet + 1) + px) {
    height: 90vh;
    min-height: 650px;
  }
  @media screen and (max-width: ($mobile + 1) + px) {
    min-height: 570px;
  }
  @media (min-width: $fourKMinPx) {
    @include adaptiv-value(min-height, 1520, 760);
  }

  .splide {
    //height: calc(100% - 62px - 60px);
    height: calc(100% - 122px);
    margin-top: 62px;

    .splide__track {
      height: 100%;
    }

    .splide__pagination {
      top: 170px;
      bottom: 0;
      height: 12px;

      .presentation {
        margin-right: 12px;

        &:last-child {
          margin-right: 0;
        }
      }

      .splide__pagination__page {
        width: 12px;
        height: 12px;
        background-color: $accentColor;
        opacity: 0.2;
        outline: none;
      }

      .splide__pagination__page.is-active {
        background-color: $accentColor;
        opacity: 1;
        transform: none;
      }

      @media screen and (max-width: $mobile + px) {
        top: 110px + 24px + 12px;
        //top: 164px;
      }
      @media(max-width: 472px) {
        top: 110px + 24px + 12px + 24px;
      }
    }

    @media (min-width: $fourKMinPx) {
      @include adaptiv-value-splide-height(244, 122);
      @include adaptiv-value(margin-top, 124, 62);

      .splide__pagination {
        @include adaptiv-value(top, 340, 170);
        @include adaptiv-value(height, 24, 12);

        .presentation {
          @include adaptiv-value(margin-right, 24, 12);
        }

        .splide__pagination__page {
          @include adaptiv-value(width, 24, 12);
          @include adaptiv-value(height, 24, 12);
        }
      }
    }

  }

  &_common {
    text-align: center;
    position: relative;
    height: 100%;
    margin: 0 auto 0 auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    &_text {
      position: relative;

      .leftImage,
      .rightImage {
        position: absolute;
        display: none;
        top: 100px;
        @media(max-width: 472px) {
          top: 124px;
        }
        @media(max-width: $mobile + px) {
          width: 66px;
          height: 66px;
        }
      }

      .leftImage {
        left: 50px;
        @media(max-width: $mobile + px) {
          left: 24px;
        }
      }

      .rightImage {
        right: 50px;
        @media(max-width: $mobile + px) {
          right: 24px;
        }
      }

      .btn {
        margin-top: 80px;
      }
    }

    &::after {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      transform: translateY(calc(100% - 146px));
      transition: transform 2s ease-in;
      will-change: transform;
    }

    @media (min-width: $fourKMinPx) {
      &_text {
        .leftImage,
        .rightImage {
          @include adaptiv-value(top, 200, 100);
        }

        .leftImage {
          @include adaptiv-value(left, 100, 50);
          @include adaptiv-value(width, 240, 120);
          @include adaptiv-value(height, 240, 120);
        }

        .rightImage {
          @include adaptiv-value(right, 100, 50);
          @include adaptiv-value(width, 240, 120);
          @include adaptiv-value(height, 240, 120);
        }

        .btn {
          @include adaptiv-value(margin-top, 160, 80);
        }
      }

      &::after {
        @include adaptiv-value-transform-home-after(292, 146);
      }
    }

  }

  //.collectibles {
  //  &::after {
  //    content: url('/public/home/slide1/cards.png');
  //  }
  //}
  //.sports {
  //  &::after {
  //    content: url('/public/home/slide2/cards.png');
  //  }
  //}
  .events {
    //&::after {
    //  content: url('/public/home/slide3/cards.png');
    //}
  }

  .bg {
    position: absolute;
    transform: translateY(1000px);
    @media (min-width: $fourKMinPx) {
      @include adaptiv-value-transform-animate(2000, 1000);
    }
  }

  @include slideBgs(1);
  @include slideBgs(2);
  @include slideBgs(3);
  @include slideBgs(4);
  @include slideBgs(5);
  @include slideBgs(6);
  @include slideBgs(7);


  .grid {
    width: 1080px;
    position: absolute;
    height: 374px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    bottom: 0;
    right: 0;

    .bg-1 {
      width: #{$imageWidthD}px;
      height: 192px;
      left: $offsetsBorderD;
      animation-delay: 0.1s;
    }

    .bg-2 {
      bottom: 0;
      height: 152px;
      left: $offsetsBorderD;
      animation-delay: 0.4s;
      width: #{$imageWidthD}px;
    }

    .bg-3 {
      width: #{$imageWidthD}px;
      height: 192px;
      left: 20.56%;
      bottom: calc(24px + #{$offsetFromBottomD}px);
      animation-delay: 0.6s;
    }

    .bg-4 {
      width: #{$imageWidthD}px;
      height: 140px;
      bottom: 0;
      margin: 0 auto;
      left: 41.11%;
      right: 41.11%;
      animation-delay: 0.8s;
    }

    .bg-5 {
      width: #{$imageWidthD}px;
      height: 192px;
      bottom: 28px;
      right: 20.56%;
      animation-delay: 0.6s;
    }

    .bg-6 {
      width: #{$imageWidthD}px;
      height: 192px;
      right: $offsetsBorderD;
      animation-delay: 0.4s;
    }

    .bg-7 {
      height: 152px;
      bottom: 0;
      width: #{$imageWidthD}px;
      right: $offsetsBorderD;
      animation-delay: 0.1s;
    }

    @media (min-width: $fourKMinPx) {
      @include adaptiv-value(width, 2160, 1080);
      @include adaptiv-value(height, 748, 374);

      .bg-1 {
        @include adaptiv-value(width, 384, 192);
        @include adaptiv-value(height, 384, 192);
      }

      .bg-2 {
        @include adaptiv-value(height, 304, 152);
        @include adaptiv-value(width, 384, 192);
      }

      .bg-3 {
        @include adaptiv-value(width, 384, 192);
        @include adaptiv-value(height, 384, 192);
        @include adaptiv-value(bottom, 56, 28);
      }

      .bg-4 {
        @include adaptiv-value(width, 384, 192);
        @include adaptiv-value(height, 280, 140);
      }

      .bg-5 {
        @include adaptiv-value(width, 384, 192);
        @include adaptiv-value(height, 384, 192);
        @include adaptiv-value(bottom, 56, 28);
      }

      .bg-6 {
        @include adaptiv-value(width, 384, 192);
        @include adaptiv-value(height, 384, 192);
      }

      .bg-7 {
        @include adaptiv-value(width, 384, 192);
        @include adaptiv-value(height, 304, 152);
      }
    }

    @media screen and (max-width: $desktop + px) {
      width: 704px;
      height: 404px;

      .bg-1 {
        left: $offsetsBorderT;
        width: #{$imageWidthT}px;
        height: 208px;
      }
      .bg-2 {
        bottom: 15px;
        height: 152px;
        left: $offsetsBorderD;
        width: #{$imageWidthT}px;
      }
      .bg-3 {
        width: #{$imageWidthT}px;
        height: 208px;
        left: 35.23%;
        right: 35.23%;
        margin-right: auto;
        margin-left: auto;
        top: 60px;
      }
      .bg-4 {
        width: #{$imageWidthT}px;
        height: 96px;
        bottom: 15px;
        margin-right: auto;
        margin-left: auto;
        left: 35.23%;
        right: 35.23%;
      }
      .bg-5 {
        width: #{$imageWidthT}px;
        height: 208px;
        right: 0;
        top: 0;
      }
      .bg-6 {
        width: #{$imageWidthT}px;
        height: 156px;
        bottom: 15px;
        right: $offsetsBorderT;
      }
      .bg-7 {
        display: none;
      }
    }

    @media screen and (max-width: ($tablet + 1) + px) {
      width: 500px;
      height: 200px;
      @media screen and (max-width: ($mobile + 1) + px) {
        width: unset;
        margin: 0 20px;
        box-sizing: border-box;
      }

      .bg-1 {
        left: $offsetsBorderM;
        animation-delay: 0.1s;
        width: #{$imageWidthM}px;
        height: 100px;
      }
      .bg-2 {
        bottom: 0;
        height: 90px;
        left: $offsetsBorderM;
        width: #{$imageWidthM}px;
      }
      .bg-3 {
        width: #{$imageWidthM}px;
        height: 100px;
        left: 34.38%;
        right: 34.38%;
        margin-left: auto;
        margin-right: auto;
        top: 24px;
      }
      .bg-4 {
        width: #{$imageWidthM}px;
        height: 66px;
        bottom: 0;
        margin-left: auto;
        margin-right: auto;
        left: 34.38%;
        right: 34.38%;
      }
      .bg-5 {
        width: #{$imageWidthM}px;
        height: 100px;
        right: 0;
        top: 0;
      }
      .bg-6 {
        width: #{$imageWidthM}px;
        height: 90px;
        bottom: 0;
        right: $offsetsBorderM;
      }
    }
  }

  .collectibles.active,
  .sports.active,
  .events.active {
    .leftImage,
    .rightImage {
      display: block;
      animation: fadeInFromNone 2s ease-out;
    }

    //&::after {
    //  transform: translateY(-146px);
    //  transition: transform 2s ease-in-out;
    //  will-change: transform;
    //}
  }

  .secondaryCaption {
    font-size: 24px;
    text-transform: uppercase;
    font-weight: $fontWeightSemiBold;
    color: $grayColor;
    line-height: 36px;
    @media (min-width: $fourKMinPx) {
      @include adaptiv-value(font-size, 48, 24);
      @include adaptiv-value(line-height, 72, 36);
    }
  }

  .mainCaption {
    font-size: 40px;
    font-weight: $fontWeightBold;
    color: $blackColor;
    line-height: 52px;
    @media (min-width: $fourKMinPx) {
      @include adaptiv-value(font-size, 80, 40);
      @include adaptiv-value(line-height, 104, 52);
    }
  }

  .thirdCaption {
    font-size: 40px;
    font-weight: $fontWeightBold;
    color: $accentColor;
    line-height: 52px;
    @media (min-width: $fourKMinPx) {
      @include adaptiv-value(font-size, 80, 40);
      @include adaptiv-value(line-height, 104, 52);
    }
  }

  @media screen and (max-width: $tablet + px) {
    .secondaryCaption {
      font-size: 18px;
      line-height: 24px;
    }
    .mainCaption {
      font-size: 28px;
      line-height: 36px;
    }
    .thirdCaption {
      font-size: 28px;
      line-height: 36px;
    }
    .btn {
      margin-top: 150px;
    }
  }
  @media screen and (max-width: $mobile + px) {
    .btn {
      margin-top: 64px;
    }
  }
}

@keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

.animate {
  animation: slide-in 0.7s forwards;
  @media (min-width: $fourKMinPx) {
    animation: slide-in-four-k 0.7s forwards;
  }
}

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateY(1000px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-in-four-k {
  0% {
    @include adaptiv-value-transform-animate(2000, 1000);
  }
  100% {
    transform: translateY(0);
  }
}
